import React from "react";
import { useTranslation } from "react-i18next";

import { ColumnHeader } from "./ColumnHeader";

/**
 * A component to display the header of the checked-in clients list.

* @returns {JSX.Element}
 */
const CheckedInClientHeader = () => {
  const { t } = useTranslation();
  return (
    <ColumnHeader>
      <span style={{ flex: "0 0 60%" }}>{t("reception.client_name")}</span>
      <span
        style={{
          display: "flex",
          flex: "0 0 40%",
          justifyContent: "space-between",
        }}
      >
        <span style={{ flex: "0 0 30%" }}>{t("reception.start")}</span>
        <span
          style={{ flex: "0 0 70%", whiteSpace: "nowrap", textAlign: "right" }}
        >
          {t("reception.wait_time")}
        </span>
      </span>
    </ColumnHeader>
  );
};

export { CheckedInClientHeader };
