import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays, faXmark } from "@fortawesome/pro-solid-svg-icons";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
} from "@mui/material";

import * as d from "shared/src/date.mjs";
import { status } from "shared/src/appointment.mjs";

import * as api from "../../services/api";
import ButtonLoader from "../ButtonLoader";
import { FormErrorList, useFormAction } from "../../form";
import { useCurrentLocation } from "../../components/LocationSelector";
import AutocompleteSelect from "../AutocompleteSelect";
import { ClientAutocomplete } from "./ClientAutocomplete";
import { useServiceOptions } from "./hooks/useServiceOption";
import { useClientOptions } from "./hooks/useClientOptions";

export const adhocTypesEnum = {
  STREET: "STREET",
  INITIALEXAMINATION: "INITIAL_EXAMINATION",
  NOSHOW: "NOSHOW",
  CHAIR: "CHAIR",
};

export const adhocTypes = {
  STREET: {
    name: "Z ulice",
    value: adhocTypesEnum.STREET,
    id: adhocTypesEnum.STREET,
  },
  INITIAL_EXAMINATION: {
    name: "Vstupní vyšetření",
    value: adhocTypesEnum.INITIALEXAMINATION,
    id: adhocTypesEnum.INITIALEXAMINATION,
  },
  NOSHOW: {
    name: "No Show",
    value: adhocTypesEnum.NOSHOW,
    id: adhocTypesEnum.NOSHOW,
  },
  CHAIR: {
    name: "Z křesla",
    value: adhocTypesEnum.CHAIR,
    id: adhocTypesEnum.CHAIR,
  },
};

/**
 * @typedef NewAdhocDialogDefaults
 *
 * @typedef NewAdhocDialogState
 * @property {object | null} option
 * @property {object | null} serviceOption
 * @property {string} selectedType
 * @property {string[]} types

 *
 * @returns {NewAdhocDialogState}
 */
const initialNewAdhocDialogState = (defaults) => {
  return {
    appointment: defaults.appointment ?? null,
    option: defaults.option ?? null,
    serviceOption: defaults.serviceOption ?? null,
    types: defaults.types ?? Object.keys(adhocTypes),
    selectedType: defaults.selectedType ?? null,
  };
};

/**
 * @param {object} props
 * @param {() => void} props.close
 * @param {NewAdhocDialogDefaults} [props.defaults]
 */
const NewAdhocDialog = ({ close, defaults = {} }) => {
  const { t } = useTranslation();
  const [state, setState] = useState(() =>
    initialNewAdhocDialogState(defaults),
  );

  const [serviceOptions, setServiceOptions] = useState([]);

  const { appointment, option, serviceOption, types, selectedType } = state;

  const [options, clientSearch, clientOptionsLoading] = useClientOptions(
    null,
    option,
  );
  const locationId = useCurrentLocation();

  const serviceFilter = React.useMemo(() => {
    return {
      location_id: { eq: locationId },
    };
  }, [locationId]);
  const so = useServiceOptions(serviceFilter);

  useEffect(() => {
    if (so.loading) {
      return;
    }

    setServiceOptions(so.options);
  }, [so.loading]);

  useEffect(() => {
    if (types.includes(adhocTypesEnum.NOSHOW)) {
      setState((state) => ({
        ...state,
        selectedType: adhocTypes[adhocTypesEnum.NOSHOW],
      }));
    }

    if (selectedType?.value === adhocTypesEnum.INITIALEXAMINATION) {
      setServiceOptions(
        so.options.filter(
          (option) => option.name === "Vstupní vyšetření (10m)",
        ),
      );
      setState((state) => ({
        ...state,
        serviceOption: so.options.find(
          (options) => options.name === "Vstupní vyšetření (10m)",
        ),
      }));
    } else {
      setServiceOptions(so.options);
    }
  }, [selectedType]);

  const { errors, loading, onSubmit } = useFormAction(
    () => {
      const start = new Date();
      const end = new Date(start.getTime());
      end.setMinutes(end.getMinutes() + serviceOption.duration);
      if (selectedType.value === adhocTypesEnum.NOSHOW) {
        api.patchAppointment({
          id: appointment.id,
          status_id: status.NOSHOW,
        });
      }
      return api.createAppointment({
        client_id: option.id,
        service_id: serviceOption.id,
        start: d.toString(start),
        end: d.toString(end),
        status_id: status.CONFIRMED,
        location_id: locationId,
        adhoc_type: selectedType.value,
      });
    },
    () => close(),
  );

  const submitDisabled = loading;

  return (
    <Dialog open={true} onClose={() => close()} maxWidth="lg">
      <DialogTitle>
        {t("reception.newAdhoc")}
        <IconButton aria-label="close" onClick={() => close()}>
          <FontAwesomeIcon icon={faXmark} fixedWidth />
        </IconButton>
      </DialogTitle>
      <form onSubmit={onSubmit}>
        <DialogContent>
          <Box display="flex" flexDirection="column" gap="1rem">
            <ClientAutocomplete
              search={clientSearch}
              loading={clientOptionsLoading}
              options={options}
              option={option}
              disabled={selectedType?.value === adhocTypesEnum.NOSHOW}
              onChange={(option) =>
                setState((state) => ({
                  ...state,
                  option: option,
                }))
              }
            />
            <AutocompleteSelect
              required
              name="type"
              options={types.map((type) => {
                return adhocTypes[type];
              })}
              label={t("appointment.adhoc_type")}
              value={selectedType}
              readOnly={selectedType?.value === adhocTypesEnum.NOSHOW}
              onChange={(value) => {
                setState((state) => ({
                  ...state,
                  selectedType: value == null ? null : value,
                }));
              }}
            />
            <AutocompleteSelect
              required
              name="service_id"
              loading={so.loading}
              options={serviceOptions}
              label={t("appointment.service")}
              value={serviceOption}
              readOnly={
                selectedType?.value === adhocTypesEnum.NOSHOW ||
                selectedType?.value === adhocTypesEnum.INITIALEXAMINATION
              }
              onChange={(value) => {
                setState((state) => ({
                  ...state,
                  serviceOption: value == null ? null : value,
                }));
              }}
            />
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={state.fmAgreement} required />}
                label={t("reception.floorManagerApproval")}
              />
            </FormGroup>

            <FormErrorList formErrors={errors} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => close()} variant="outlined">
            {t("form.cancel")}
          </Button>
          <Button
            type="submit"
            disabled={submitDisabled}
            sx={{ padding: "0.7rem 2.4rem", gap: "0.6rem" }}
            variant="contained"
          >
            {loading && <ButtonLoader />}
            <FontAwesomeIcon icon={faCalendarDays} />
            {t("form.adHocReserve")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export { NewAdhocDialog };
