import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Typography,
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import AppointmentServices from "./AppointmentServices";
import {
  createFilledServiceTemplate,
  loadServicesWithTemplates,
} from "../../services/api";
import { useWizardContext } from "./AppointmentWizardContext";

const AppointmentNoteFields = ({
  coordinatorOptions = [],
  doctorOptions = [],
}) => {
  const { t } = useTranslation();
  const [formSaved, setFormSaved] = useState(false);
  const [services, setServices] = useState([]);
  const [page] = useState({ limit: 60, offset: 0 });

  const {
    coordinatorId,
    setCoordinatorId,
    doctorId,
    setDoctorId,
    floorManagerNotes,
    setFloorManagerNotes,
    clientData,
    selectedServiceId,
    setSelectedServiceId,
    filledFormsData,
    setFilledFormsData,
  } = useWizardContext();

  const filledFormDataRef = useRef({});

  const servicesLoaded = services && services.length > 0;

  const fetchServices = async () => {
    try {
      const result = await loadServicesWithTemplates({
        page,
        filter: { visible: { eq: true } },
      });
      setServices(result.data.services.data);
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const handleFormChange = (templateId, formData) => {
    setFilledFormsData((prev) => ({
      ...prev,
      [templateId]: formData,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!clientData?.nextAppointment?.id) {
      console.error("No appointment ID found. Cannot submit filled data.");
      return;
    }

    try {
      const filledDataEntries = Object.entries(filledFormDataRef.current);

      for (const [serviceId, filledData] of filledDataEntries) {
        const payload = {
          appointment_id: clientData.nextAppointment.id,
          template_id: serviceId,
          filled_data: filledData,
        };

        await createFilledServiceTemplate(payload);
      }

      setFormSaved(true);
    } catch (error) {
      console.error("Error submitting filled data:", error);
    }
  };

  useEffect(() => {
    fetchServices();
    setSelectedServiceId(clientData.nextAppointment.service.id);
  }, [page]);

  return (
    <Box sx={{ mt: 4, mb: 12 }}>
      <form onSubmit={handleSubmit} id="appointmentNotesForm">
        {selectedServiceId && servicesLoaded && (
          <AppointmentServices
            services={services}
            formSubmitted={formSaved}
            onFormChange={handleFormChange}
            initialFormData={filledFormsData}
          />
        )}
      </form>

      <Typography variant="h6" sx={{ mb: 2 }} fontWeight="bold">
        {t("appointmentNotes.assignedStaff")}
      </Typography>

      <Grid container spacing={2}>
        <Grid item size={{ xs: 12, sm: 6 }}>
          <FormControl fullWidth sx={{ minWidth: 250 }}>
            <InputLabel id="coordinator-label">
              {t("appointmentNotes.coordinator")}
            </InputLabel>
            <Select
              labelId="coordinator-label"
              value={coordinatorId}
              onChange={(e) => setCoordinatorId(e.target.value)}
              label={t("appointmentNotes.coordinator")}
            >
              <MenuItem value="">
                <em>{t("appointmentNotes.selectOption")}</em>
              </MenuItem>
              {coordinatorOptions.map((emp) => (
                <MenuItem key={emp.id} value={emp.id}>
                  {emp.first_name} {emp.last_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item size={{ xs: 12, sm: 6 }}>
          <FormControl fullWidth sx={{ minWidth: 250 }}>
            <InputLabel id="doctor-label">
              {t("appointmentNotes.doctor")}
            </InputLabel>
            <Select
              labelId="doctor-label"
              value={doctorId}
              onChange={(e) => setDoctorId(e.target.value)}
              label={t("appointmentNotes.doctor")}
            >
              <MenuItem value="">
                <em>{t("appointmentNotes.selectOption")}</em>
              </MenuItem>
              {doctorOptions.map((emp) => (
                <MenuItem key={emp.id} value={emp.id}>
                  {emp.first_name} {emp.last_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Typography variant="h6" sx={{ mt: 4, mb: 1 }} fontWeight="bold">
        {t("appointmentNotes.noteForFloorManagerTitle")}
      </Typography>

      <TextField
        label={t("appointmentNotes.noteForFloorManagerLabel")}
        placeholder={t("appointmentNotes.notePlaceholder")}
        variant="outlined"
        multiline
        rows={3}
        fullWidth
        sx={{ mt: 1 }}
        value={floorManagerNotes}
        onChange={(e) => setFloorManagerNotes(e.target.value)}
      />
    </Box>
  );
};

export default AppointmentNoteFields;
