import React, { useState } from "react";
import * as api from "../services/api";
import { useLoaderData, useRevalidator } from "react-router";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import * as f from "../utils/formatter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsRotate,
  faChartSimple,
} from "@fortawesome/free-solid-svg-icons";
import { currentLocationFromRequest } from "../components/LocationSelector";
import * as calUtil from "shared/src/calendar.mjs";
import { endOfDay, startOfDay } from "shared/src/date.mjs";
import * as d from "shared/src/date.mjs";
import { status } from "shared/src/appointment.mjs";
import { groupBy } from "shared/src/util.mjs";
import ButtonLoader from "../components/ButtonLoader";
import { MoreVert } from "@mui/icons-material";

/**
 * @typedef TimeSpent
 * @property {string} employee_id
 * @property {Date} from
 * @property {Date} to
 *
 * @typedef Audit
 * @property {string} created_at
 * @property {string} action
 * @property {Record<string, string>} [changed_fields]
 * @property {Record<string, string>} [row_data]
 *
 * @typedef Employee
 * @property {string} id
 * @property {string} first_name
 * @property {string} last_name
 *
 * @typedef {Pick<import('shared/src/gql-types.mjs').Location, 'id' | 'working_hours'>} Location
 */

export async function loader({ request }) {
  const now = new Date();
  const location_id = currentLocationFromRequest(request);

  if (location_id == null) {
    return {
      now,
      data: {
        location: null,
        employees: { data: [] },
        appointments: { data: [] },
      },
    };
  }

  const res = await api.loadFlooreManagerEmployees({
    location_id: location_id,
    employeeFilter: {
      location_id: { eq: location_id },
    },
    appointmentFilter: {
      start: {
        gte: d.toString(startOfDay(now)),
        lte: d.toString(endOfDay(now)),
      },
      location_id: { eq: location_id },
    },
  });

  return { ...res, now };
}

/**
 * @param {string} [timeStr]
 *
 * @returns {number[]?}
 */
function parseTime(timeStr) {
  const parts = timeStr?.split(":").map((v) => parseInt(v, 10));

  if (
    parts == null ||
    parts.length !== 2 ||
    parts.some((p) => Number.isNaN(p))
  ) {
    return null;
  }

  return parts;
}

/**
 * @param {string} [timeStr]
 *
 * @returns {number?}
 */
function createFromHour(timeStr) {
  const time = parseTime(timeStr);

  return time?.[0];
}

/**
 * @param {string} [timeStr]
 *
 * @returns {number?}
 */
function createToHour(timeStr) {
  const time = parseTime(timeStr);
  if (time == null) {
    return;
  }

  const [h, m] = time;

  return m > 0 ? h + 1 : h;
}

/**
 * @param {object} wh
 * @param {string} wh.from
 * @param {string} wh.to
 */
function* hours(wh) {
  const from = createFromHour(wh?.from);
  const to = createToHour(wh?.to);
  if (from == null || to == null) {
    return;
  }

  for (let i = from; i <= to; i++) {
    yield i;
  }
}

/**
 * @param {Audit} audit
 *
 * @returns {Record<string, string>?}
 */
function auditData(audit) {
  switch (audit.action) {
    case "I":
      return audit.row_data;
    case "U":
      return audit.changed_fields;
  }
}

/**
 * @param {Appointment} appointment
 * @param {Date} now
 *
 * @returns {TimeSpent[]}
 */
function appointmentTimeSpent(appointment, now) {
  let current = {};

  const res = appointment.audit.data.reduce((acc, next) => {
    const newData = auditData(next);
    if (newData == null) {
      return acc;
    }

    if (newData.employee_id != null) {
      current.employee_id = newData.employee_id;
    }

    switch (newData.status_id) {
      case String(status.TREATING):
        current.from = d.parseUtcDateTime(next.created_at);
        break;
      case String(status.FINISHED):
        current.to = d.parseUtcDateTime(next.created_at);
        acc.push(current);
        current = {};
        break;
    }

    return acc;
  }, []);

  // show time spent for unfinished appointment
  if (current.from != null) {
    current.to = now;
    res.push(current);
    current = {};
  }

  return res;
}

/**
 * @param {Appointment[]} [appointments]
 * @param {Date} now
 *
 * @returns {Record<string, TimeSpent>}
 */
function useAppointmentTimeSpent(appointments, now) {
  const [timeSpentByEmployeeId, setTimeSpentByEmployeeId] = React.useState({});
  React.useEffect(() => {
    if (appointments == null) {
      return;
    }

    setTimeSpentByEmployeeId(
      groupBy(
        appointments.flatMap((a) => appointmentTimeSpent(a, now)),
        (ts) => ts.employee_id,
      ),
    );
  }, [appointments]);

  return timeSpentByEmployeeId;
}

/**
 * @param {Date} date
 */
function dateToHours(date) {
  return date.getHours() + date.getMinutes() / 60 + date.getSeconds() / 3600;
}

/**
 * @param {number} ms
 */
function msToHours(ms) {
  return ms / 3600000;
}

/**
 * @param {Location} [location]
 * @param {Date} now
 */
function hoursFromLocation(location, now) {
  const day = calUtil.dateToDay(now);
  const wh = calUtil.workingHoursInDay(now, location?.working_hours?.[day]);
  const hs = Array.from(hours(wh));

  return hs;
}

/**
 * @param {number} h
 */
function formatHour(h) {
  return String(h).padStart(2, "0") + ":00";
}

/**
 * @param {object} props
 * @param {() => void} props.close
 * @param {Employee[]} props.employees
 * @param {TimeSpent} props.timeSpent
 * @param {Date} props.now
 */
function EmployeeChartDialog({ close, employees, location, timeSpent, now }) {
  const hs = hoursFromLocation(location, now);

  if (hs.length === 0) {
    return null;
  }

  const firstHour = hs[0];
  const hourSize = 8;

  return (
    <Dialog open={true} onClose={() => close()} fullScreen>
      <DialogContent>
        <table className="femployees-workload">
          <thead>
            <tr>
              <td></td>
              {employees.map((e) => (
                <th key={e.id}>{f.fullName(e)}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ padding: 0 }}></td>
              {employees.map((e) => (
                <td key={e.id} style={{ position: "relative", padding: 0 }}>
                  {timeSpent[e.id]?.map((ts) => {
                    return (
                      <div
                        key={ts.from}
                        className="time-slot"
                        style={{
                          top: `${(dateToHours(ts.from) - firstHour) * hourSize}rem`,
                          height: `${msToHours(ts.to - ts.from) * hourSize}rem`,
                          backgroundColor: "lightblue",
                        }}
                      >
                        Na křesle
                      </div>
                    );
                  })}
                </td>
              ))}
            </tr>
            {hs.map((h) => (
              <tr
                key={h}
                className="hour-row"
                style={{ height: `${hourSize}rem` }}
              >
                <td>{formatHour(h)}</td>
                <td colSpan={employees.length} />
              </tr>
            ))}
          </tbody>
        </table>
      </DialogContent>
    </Dialog>
  );
}

/**
 * @param {object} props
 * @param {Location} props.location
 * @param {Employee[]} props.employees
 * @param {Date} props.now
 * @param {TimeSpent} props.timeSpent
 */
function EmployeeChart({ location, employees, now, timeSpent }) {
  const [showDialog, setShowDialog] = React.useState(false);

  return (
    <>
      <IconButton
        onClick={() => setShowDialog(true)}
        sx={{ aspectRatio: "1 / 1" }}
        size="small"
      >
        <FontAwesomeIcon icon={faChartSimple} fixedWidth />
      </IconButton>
      {showDialog && (
        <EmployeeChartDialog
          close={() => setShowDialog(false)}
          employees={employees}
          location={location}
          now={now}
          timeSpent={timeSpent}
        />
      )}
    </>
  );
}

/**
 * @param {object} props
 * @param {Employee} props.employee
 * @param {TimeSpent[]} [props.timesSpent]
 * @param {Date} now
 * @param {Location?} props.location
 */
function EmployeeRow({ employee, timesSpent, now, location }) {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [pinDialogOpen, setPinDialogOpen] = useState(false);

  const [newPin, setNewPin] = useState("");
  const [pinError, setPinError] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  const { t } = useTranslation();

  const hs = hoursFromLocation(location, now);
  const firstHour = hs[0];
  const hourSize = 100 / hs.length;

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const handleOpenPinDialog = () => {
    handleMenuClose();
    setPinDialogOpen(true);
  };

  const handleClosePinDialog = () => setPinDialogOpen(false);

  const handleChangePin = async () => {
    if (!/^\d{6}$/.test(newPin)) {
      setPinError(t("employees.pinValidationError"));
      return;
    }

    setPinError("");

    try {
      setIsSaving(true);

      const response = await api.updateEmployeePin(employee.id, newPin);

      if (response.data?.changeEmployeePin) {
        handleClosePinDialog();
        setNewPin("");
      } else {
        setPinError(t("employees.pinUpdateFailed"));
      }
    } catch (err) {
      console.error("PIN update failed:", err);
      setPinError(t("employees.networkError"));
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <>
      <TableRow>
        <TableCell>{f.fullName(employee)}</TableCell>
        <TableCell>{employee.available ? "Volná" : "Nepřítomná"}</TableCell>
        <TableCell>
          <IconButton
            onClick={() => setOpen((open) => !open)}
            size="small"
            sx={{ aspectRatio: "1 / 1" }}
          >
            <FontAwesomeIcon icon={faChartSimple} fixedWidth />
          </IconButton>
        </TableCell>
        <TableCell>
          <IconButton onClick={handleMenuOpen} size="small">
            <MoreVert />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleOpenPinDialog}>
              {t("employees.changePIN")}
            </MenuItem>
          </Menu>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={3} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={open}>
            <Box className="femployees-single-workload">
              <Box position="relative" height="2rem">
                {timesSpent?.map((ts) => {
                  return (
                    <div
                      key={ts.from}
                      className="time-slot"
                      style={{
                        left: `${(dateToHours(ts.from) - firstHour) * hourSize}%`,
                        width: `${msToHours(ts.to - ts.from) * hourSize}%`,
                        backgroundColor: "lightblue",
                      }}
                    >
                      Na křesle
                    </div>
                  );
                })}
              </Box>
              <Box display="flex">
                {hs.map((h) => (
                  <Box key={h} className="hour-cell">
                    {formatHour(h)}
                  </Box>
                ))}
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Dialog open={pinDialogOpen} onClose={handleClosePinDialog}>
        <DialogTitle>
          {t("employees.changePINFor", {
            name: `${employee.first_name} ${employee.last_name}`,
          })}
        </DialogTitle>

        <DialogContent>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleChangePin();
            }}
          >
            <TextField
              label={t("employees.newPIN")}
              type="password"
              variant="outlined"
              value={newPin}
              onChange={(e) => {
                const digitsOnly = e.target.value.replace(/\D/g, "");
                setNewPin(digitsOnly);
                if (pinError) setPinError(""); // clear error on input
              }}
              inputMode="numeric"
              fullWidth
              autoFocus
              margin="normal"
              error={!!pinError}
              helperText={pinError}
            />
            <DialogActions sx={{ padding: 0, marginTop: 1 }}>
              <Button onClick={handleClosePinDialog}>
                {t("employees.buttons.cancel")}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSaving}
              >
                {isSaving
                  ? t("employees.buttons.saving")
                  : t("employees.buttons.save")}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}

function ActionButton({ children, action }) {
  const [loading, setLoading] = React.useState(false);
  const revalidator = useRevalidator();

  return (
    <Button
      onClick={async () => {
        try {
          setLoading(true);
          await action();
          revalidator.revalidate();
        } finally {
          setLoading(false);
        }
      }}
      disabled={loading}
    >
      {loading && <ButtonLoader />}
      {children}
    </Button>
  );
}

function RefreshShiftsButton() {
  return (
    <ActionButton action={() => api.syncEmployeeShifts()}>
      <FontAwesomeIcon icon={faArrowsRotate} />
      &nbsp; Směny
    </ActionButton>
  );
}

function RefreshAvailabilityButton() {
  return (
    <ActionButton action={() => api.syncAvailableEmployees()}>
      <FontAwesomeIcon icon={faArrowsRotate} />
      &nbsp; Stav
    </ActionButton>
  );
}

export default function FEmployees() {
  const { t } = useTranslation();
  const loaderData = useLoaderData();
  const employeesConnection = loaderData.data.employees;
  const location = loaderData.data.location;
  const now = loaderData.now;
  const appointments = loaderData.data.appointments.data;
  const timeSpent = useAppointmentTimeSpent(appointments, now);

  return (
    <>
      <EmployeeChart
        location={location}
        employees={employeesConnection.data}
        timeSpent={timeSpent}
        now={now}
      />
      <RefreshShiftsButton />
      <RefreshAvailabilityButton />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("employees.fullName")}</TableCell>
            <TableCell>Stav</TableCell>
            <TableCell>Graf</TableCell>
            <TableCell>{t("employees.actions")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {employeesConnection.data.map((row) => (
            <EmployeeRow
              key={row.id}
              employee={row}
              timesSpent={timeSpent[row.id]}
              now={now}
              location={location}
            />
          ))}
        </TableBody>
      </Table>
    </>
  );
}
