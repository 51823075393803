import "./receptionTypes.js";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  useTheme,
  TextareaAutosize,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faGlobe,
  faAngry,
  faCommentDots,
  faCheck,
  faCalendarDays,
} from "@fortawesome/free-solid-svg-icons";
import {
  faAlarmClock,
  faChevronDoubleLeft,
  faHourglassClock,
  faSeatAirline,
  faTrashCheck,
} from "@fortawesome/pro-solid-svg-icons";
import { faXmark } from "@fortawesome/pro-light-svg-icons";
import { status } from "shared/src/appointment.mjs";
import * as api from "../../services/api";
import * as f from "../../utils/formatter";
import ServiceTag from "../ServiceTag";

/**
 * An appointment detail modal.
 *
 * @typedef {object} AppointmentDetailModalProps
 * @property {Appointment} appointment
 * @property {function} handleOpenConfirmationModal Function to open confirmation modal
 * @property {function} handleCloseAppointmentDetailModal Function to close appointment detail modal
 * @property {boolean} openAppointmentDetailModal Flag to open appointment detail modal
 * @property {number} checkedInMinutes Number of minutes checked in
 * @property {number} waitingMinutes Number of minutes waiting
 *
 * @param {AppointmentDetailModalProps} props
 * @returns {JSX.Element}
 */

const AppointmentDetailModal = ({
  appointment,
  handleOpenConfirmationModal,
  handleCloseAppointmentDetailModal,
  openAppointmentDetailModal,
  checkedInMinutes,
  waitingMinutes,
}) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const handleMoveAppointment = (status) => {
    handleCloseAppointmentDetailModal();
    api.patchAppointment({
      id: appointment.id,
      status_id: status,
    });
  };

  return (
    <Dialog
      open={openAppointmentDetailModal}
      onClose={handleCloseAppointmentDetailModal}
    >
      <DialogTitle
        sx={{
          padding: "2.4rem 2rem",
          margin: 0,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {f.fullName(appointment.client)}
        <IconButton
          aria-label="close"
          onClick={handleCloseAppointmentDetailModal}
        >
          <FontAwesomeIcon icon={faXmark} fixedWidth />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem",
          padding: "0 3.8rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "10px",
          }}
        >
          <FontAwesomeIcon icon={faCommentDots} color="purple" />

          {appointment.status_id === status.CONFIRMED && (
            <>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                color={theme.palette._components.alert.error.color}
              />
            </>
          )}

          <FontAwesomeIcon icon={faGlobe} color="#6B98AD" />

          <FontAwesomeIcon icon={faAngry} color="#6B98AD" />
        </Box>
        <ServiceTag service={appointment.service} />
        <Box>
          <strong style={{ fontSize: "1.3rem" }}>
            {t("reception.appointment_start")}:
          </strong>{" "}
          {f.time24(appointment.start)}
        </Box>
        <Box
          sx={{ color: "primary.main", fontWeight: "bold", fontSize: "1.3rem" }}
        >
          <FontAwesomeIcon icon={faSeatAirline} />{" "}
          {t("reception.seat_preparing")}
        </Box>
        <Box style={{ display: "flex", gap: "2rem" }}>
          <Box>
            <FontAwesomeIcon
              icon={faAlarmClock}
              style={{ marginRight: "5px" }}
            />
            <strong style={{ fontSize: "1.3rem" }}>
              {t("reception.waiting")}
            </strong>
            :{" "}
            <span
              style={{
                color:
                  waitingMinutes > 0
                    ? "var(--mui-palette-_components-alert-error-color)"
                    : "var(--mui-palette-_components-alert-info-color)",
              }}
            >
              {f.minutesToDuration(waitingMinutes)}
            </span>
          </Box>
          <Box>
            <FontAwesomeIcon
              icon={faHourglassClock}
              style={{ marginRight: "5px" }}
            />
            <strong style={{ fontSize: "1.3rem" }}>
              {t("reception.arrival")}
            </strong>
            :{" "}
            <span
              style={{
                color:
                  checkedInMinutes > 0
                    ? "var(--mui-palette-_components-alert-error-color)"
                    : "var(--mui-palette-_components-alert-info-color)",
              }}
            >
              {f.minutesToDuration(checkedInMinutes)}
            </span>
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <Button variant="outlined" style={{ fontSize: "1.4rem" }}>
            <FontAwesomeIcon
              icon={faCalendarDays}
              style={{ marginRight: "8px" }}
            />
            Přeobjednat
          </Button>
          <Button variant="outlined">
            <FontAwesomeIcon
              icon={faTrashCheck}
              style={{ marginRight: "8px" }}
            />
            Zrušit termín
          </Button>
        </Box>
        <span>Poznámka pro příště:</span>
        <TextareaAutosize
          minRows={3}
          style={{ width: 200 }}
          placeholder="Zde můžete napsat poznámku"
        />
      </DialogContent>

      <DialogActions sx={{ padding: "3.5rem 2rem 3rem 2rem" }}>
        {appointment.status_id === status.CONFIRMED && (
          <Button
            variant="contained"
            onClick={() => handleMoveAppointment(status.WAITING)}
            style={{ fontSize: "1.4rem" }}
          >
            <FontAwesomeIcon icon={faCheck} style={{ marginRight: "8px" }} />
            {t("reception.resolved")}
          </Button>
        )}
        <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            handleCloseAppointmentDetailModal();
            handleOpenConfirmationModal();
          }}
          style={{ fontSize: "1.4rem" }}
        >
          <FontAwesomeIcon
            icon={faChevronDoubleLeft}
            style={{ marginRight: "8px" }}
          />
          {t("reception.return_to_booked")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { AppointmentDetailModal };
