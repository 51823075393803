import { config } from "./config/config";

const AUTH_TOKEN_KEY = "labs1503.auth.auth_token";

/**
 * Sets the authentication token in the appropriate storage.
 * @param {string} token - The authentication token.
 * @param {boolean} stayLoggedIn - Whether the token should be stored in localStorage (persistent) or sessionStorage (session-based).
 */
export function setAuthenticationToken(token, stayLoggedIn) {
  if (stayLoggedIn) {
    localStorage.setItem(AUTH_TOKEN_KEY, token);
  } else {
    sessionStorage.setItem(AUTH_TOKEN_KEY, token);
  }
}

/**
 * Retrieves the authentication token from storage.
 * @returns {string|null} - The authentication token or null if not found.
 */
export function getAuthenticationToken() {
  return (
    localStorage.getItem(AUTH_TOKEN_KEY) ||
    sessionStorage.getItem(AUTH_TOKEN_KEY)
  );
}

export function tokenPayload(token) {
  const payload = token?.split(".")?.[1];
  if (payload == null) {
    return null;
  }

  return JSON.parse(atob(payload));
}

/**
 * Retrieves the payload from the JWT token.
 * @returns {object|null} - The decoded payload or null if the token is not valid.
 */
export function getAuthenticationTokenPayload() {
  const payload = getAuthenticationToken()?.split(".")?.[1];
  if (payload == null) {
    return null;
  }

  return JSON.parse(atob(payload));
}

/**
 * Removes the authentication token from all storages.
 */
function removeAuthenticationToken() {
  localStorage.removeItem(AUTH_TOKEN_KEY);
  sessionStorage.removeItem(AUTH_TOKEN_KEY);
}

export function authenticationTokenExpiresIn(token) {
  const payload = tokenPayload(token);
  const exp = payload?.exp;
  if (exp == null) {
    return 0;
  }

  const now = new Date().getTime();

  return exp * 1000 - now;
}

/**
 * Checks if the user is authenticated.
 * @returns {boolean} - True if authenticated, false otherwise.
 */
function isAuthenticated() {
  return authenticationTokenExpiresIn(getAuthenticationToken()) > 0;
}

/**
 * Re-authenticates the user by removing the token and redirecting to login.
 */
export function reAuthenticate(newWindow = false) {
  removeAuthenticationToken();
  ensureAuthenticated(newWindow);
}

/**
 * Logs the user out and redirects to the login page.
 */
export function logout(redirectPath = "/login") {
  removeAuthenticationToken();
  const msalCache = Object.keys(localStorage)
    .filter((key) => key.startsWith("msal."))
    .concat(
      Object.keys(sessionStorage).filter((key) => key.startsWith("msal.")),
    );

  msalCache.forEach((key) => {
    localStorage.removeItem(key);
    sessionStorage.removeItem(key);
  });

  window.location.href = redirectPath;
}

/**
 * Ensures the user is authenticated, if not redirects to login.
 * @returns {boolean} - True if authenticated, false otherwise.
 */
export function ensureAuthenticated() {
  if (!isAuthenticated()) {
    window.location.href = "/login";
    return false;
  }
  return true;
}

/**
 * Handles login with Azure, exchanging the Azure token for a backend token.
 * @param {Object} tokenResponse - The full Azure token response, including access_token, id_token, etc.
 * @param {boolean} stayLoggedIn - Whether the user wants to stay logged in.
 */
export async function handleLoginWithAzure(tokenResponse, stayLoggedIn) {
  const { accessToken, idToken } = tokenResponse;

  try {
    const response = await fetch(`${config.apiUrl}/authenticate`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        accessToken,
        idToken,
      }),
    });

    if (response.ok) {
      const { token } = await response.json();
      setAuthenticationToken(token, stayLoggedIn);
      return token;
    } else {
      console.error("Failed to authenticate with backend.");
      return null;
    }
  } catch (error) {
    console.error("Error in handleLoginWithAzure:", error);
    return null;
  }
}
