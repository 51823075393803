import React from "react";
import { Box, Button, Typography } from "@mui/material";

const czechMonths = [
  "led",
  "úno",
  "bře",
  "dub",
  "kvě",
  "čvn",
  "čvc",
  "srp",
  "zář",
  "říj",
  "lis",
  "pro",
];

const MonthSelector = ({ range, setRange }) => {
  const selectMonth = (monthIndex) => {
    setRange((prev) => {
      if (prev[0] === null || (prev[0] !== null && prev[1] !== null)) {
        return [monthIndex, null]; // Start a new range
      } else {
        return [prev[0], monthIndex].sort((a, b) => a - b); // Complete the range
      }
    });
  };

  const isSelected = (index) => {
    const [start, end] = range;
    return (
      (start !== null && index === start) ||
      (end !== null && index >= start && index <= end)
    );
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ backgroundColor: "primary.dark" }}
    >
      {czechMonths.map((month, index) => (
        <Button
          key={index}
          variant={isSelected(index) ? "contained" : "text"}
          onClick={() => selectMonth(index)}
          sx={{
            minWidth: "2rem",
            padding: "0.4rem 1.5rem",
            color: "white",
            borderRadius: 0,
            backgroundColor: isSelected(index) ? "primary.main" : "transparent",
          }}
        >
          <Typography variant="body2">{month}</Typography>
        </Button>
      ))}
    </Box>
  );
};

export default MonthSelector;
