import { createClientFilter } from "../../client";
import * as api from "../../services/api";
import * as f from "../../utils/formatter";

const doClientSearch = async (signal, term, location_id) => {
  let filter = createClientFilter(term);
  if (filter == null) {
    return [];
  }

  if (location_id != null) {
    filter = { and: [filter, { location_id: { eq: location_id } }] };
  }

  const res = await api.clientSearch(
    {
      filter: filter,
      page: { limit: 10, offset: 0 },
    },
    { signal },
  );

  return res.data.clients.data;
};

const fetchSuggestions = async (signal, filter) => {
  const suggestions = await api.appointmentSuggestions(
    {
      filter: filter,
    },
    { signal: signal },
  );
  return suggestions.data.appointmentSuggestions.data.map((r) => {
    return {
      id: `${r.start}.${r.end}.${r.calendar_id}`,
      ...r,
    };
  });
};

/**
 * @param {AbortSignal} signal
 */
const fetchProductOptions = async (signal, filter) => {
  if (filter == null) {
    return [];
  }

  const res = await api.productOptions(
    {
      filter: filter,
    },
    { signal },
  );

  return res.data.products.data;
};

/**
 * @param {AbortSignal} signal
 */
const fetchServiceOptions = async (signal, filter) => {
  if (filter == null) {
    return [];
  }

  const res = await api.locationServiceOptions(
    {
      filter: filter,
    },
    { signal },
  );

  return res.data.locationServices.data.map((ls) => ({
    id: ls.service.id,
    duration: ls.duration,
    name: `${ls.service.name} (${f.minutesToDuration(ls.duration)})`,
  }));
};

/**
 * @param {AbortSignal} signal
 */
const fetchLocationOptions = async (signal) => {
  const res = await api.locationOptions({ signal });

  return res.data.locations.data;
};

const loadAppointments = async (client_id) => {
  const res = await api.loadAppointments({
    filter: {
      start: { gte: "2015-01-01" },
      client_id: { eq: client_id },
    },
    order: {
      desc: "start",
    },
  });
  return res.data.appointments.data;
};

const generateAppointmentDates = (count, type) => {
  const result = [];
  const today = new Date();
  if (count && type === "days") {
    today.setDate(today.getDate() + count);
  } else if (count && type === "weeks") {
    today.setDate(today.getDate() + count * 7);
  } else if (count && type === "months") {
    today.setDate(today.getDate() + count * 28);
  }

  for (let i = -1; i < 7; i++) {
    const currentDate = new Date(today);
    currentDate.setDate(today.getDate() + i);

    // Set time to start of day (00:00:00.000)
    const fromDate = new Date(currentDate);
    fromDate.setHours(0, 0, 0, 0);

    // Set time to end of day (23:59:00.000)
    const toDate = new Date(currentDate);
    toDate.setHours(23, 59, 0, 0);

    result.push({
      from: f.dateTimeInput(fromDate),
      to: f.dateTimeInput(toDate),
    });
  }

  return result;
};

const getRandomElements = (array, numElements) => {
  if (numElements <= 0) return [];
  if (numElements >= array.length) return [...array];

  return [...array]
    .sort(() => Math.random() - 0.5)
    .slice(0, numElements)
    .sort((a, b) => new Date(a.start) - new Date(b.start));
};

export {
  loadAppointments,
  doClientSearch,
  fetchSuggestions,
  fetchProductOptions,
  fetchServiceOptions,
  fetchLocationOptions,
  generateAppointmentDates,
  getRandomElements,
};
