import isEmpty from "lodash/isEmpty";
import React from "react";
import { JsonFormsDispatch } from "@jsonforms/react";
import { Grid } from "@mui/material";

export const renderLayoutElements = (
  elements,
  schema,
  path,
  enabled,
  renderers,
  cells,
) => {
  return elements.map((child, index) => {
    return (
      <Grid key={`${path}-${index}`} size={child.size ? child.size : 12}>
        <JsonFormsDispatch
          uischema={child}
          schema={schema}
          path={path}
          enabled={enabled}
          renderers={renderers}
          cells={cells}
        />
      </Grid>
    );
  });
};

const MaterialLayoutRendererComponent = ({
  visible,
  elements,
  schema,
  path,
  enabled,
  renderers,
  cells,
}) => {
  if (isEmpty(elements) || !visible) {
    return null;
  } else {
    return (
      <Grid container key={`${path}-${visible}`} spacing={1}>
        {renderLayoutElements(
          elements,
          schema,
          path,
          enabled,
          renderers,
          cells,
        )}
      </Grid>
    );
  }
};
export const CustomMaterialLayoutRenderer = React.memo(
  MaterialLayoutRendererComponent,
);
