import "./receptionTypes.js";
import React, { memo } from "react";
import { Box, useTheme } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngry,
  faCake,
  faCommentDots,
  faExclamationTriangle,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";
import { status } from "shared/src/appointment.mjs";

import * as f from "../../utils/formatter";
import Link from "../Link";
import ServiceTag from "../ServiceTag";

/**
 * A component to display client name and appointment details
 *
 * @typedef {object} ClientNameProps
 * @property {Appointment} appointment
 *
 * @param {ClientNameProps} props
 * @returns {JSX.Element}
 */

const ClientName = memo(({ appointment }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "0.5em",
        width: "100%",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <Link
          to={"/clients/" + appointment.client.id}
          style={{
            color: "inherit",
            textDecoration: "none",
            fontWeight: "bold",
            fontSize: "14px",
            display: "flex",
            gap: "0.5rem",
            alignItems: "center",
            minWidth: "0",
          }}
        >
          <div
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {f.fullName(appointment.client)}
          </div>
        </Link>
        {(appointment.status_id === status.CONFIRMED ||
          appointment.status_id === status.BOOKED) && (
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            color={theme.palette._components.alert.error.color}
          />
        )}
        <FontAwesomeIcon icon={faCake} color={theme.palette.secondary.main} />
        <FontAwesomeIcon icon={faGlobe} color="#6B98AD" />
        <FontAwesomeIcon icon={faAngry} color="#6B98AD" />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          maxWidth: "100%",
        }}
      >
        <ServiceTag service={appointment.service} />
        <FontAwesomeIcon icon={faCommentDots} color="purple" />
      </div>
    </Box>
  );
});

ClientName.displayName = "ClientName";
export { ClientName };
