import React from "react";
import { useTranslation } from "react-i18next";
import { ColumnHeader } from "./ColumnHeader";

/**
 * A component to display the header of the booked client list
 
 * @returns {JSX.Element}
 */

const BookedClientHeader = () => {
  const { t } = useTranslation();

  return (
    <ColumnHeader>
      <span style={{ flex: "0 0 70%" }}>{t("reception.client_name")}</span>
      <span style={{ flex: "0 0 20%" }}>{t("reception.start")}</span>
      <span style={{ flex: "0 0 10%" }}></span>
    </ColumnHeader>
  );
};

export { BookedClientHeader };
