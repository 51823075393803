import { useState } from "react";
import { useLatest } from "../../../hooks";
import { doClientSearch } from "../utils";

const useClients = (term, location_id) => {
  const [state, setState] = useState({ result: [], loading: false });
  useLatest(
    async (signal) => {
      setState((state) => ({ ...state, loading: true }));
      try {
        const result = await doClientSearch(signal, term, location_id);
        setState((state) => ({ ...state, result }));
      } finally {
        if (!signal.aborted) {
          setState((state) => ({ ...state, loading: false }));
        }
      }
    },
    [term, location_id],
  );
  return state;
};

export { useClients };
