import React, { useMemo, useState } from "react";
import { TablePagination } from "../components/table";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useLoaderData, useSearchParams } from "react-router";
import { useTranslation } from "react-i18next";
import * as qs from "../qs";
import * as p from "../parser";
import { debounce } from "shared/src/util.mjs";
import { DEBOUNCE_INPUT } from "../constants";
import { loadServicesWithTemplates } from "../services/api";
import { ServiceTemplateDialog } from "../components/services/ServiceTemplateDialog.jsx";
import { CreateServiceDialog } from "../components/services/CreateServiceDialog";
import { DeleteServiceDialog } from "../components/services/DeleteServiceDIalog";
import {
  faTrash,
  faFileCode,
  faAdd,
  faSearch,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { JsonForms } from "@jsonforms/react";
import { materialRenderers } from "../components/jsonForms/materialRenderers.jsx";
import { materialCells } from "@jsonforms/material-renderers";

const defaults = [
  [["page", "limit"], 10],
  [["page", "offset"], 0],
];

const parsers = [
  [["page", "limit"], (val) => p.parseInt(val, 10)],
  [["page", "offset"], (val) => p.parseInt(val, 0)],
];

const filters = [[["filter", "name", "contains"], (v) => v.length === 0]];

function graphqlArgs(request) {
  const args = qs.parse(new URL(request.url).searchParams.toString(), {
    defaults: defaults,
    parsers: parsers,
    filters: filters,
  });

  args.filter = {
    ...args.filter,
    visible: { eq: true },
  };

  return args;
}

export async function loader({ request }) {
  return { services: await loadServicesWithTemplates(graphqlArgs(request)) };
}

function PreviewServiceTemplateDialog({
  setPreviewServiceTemplate,
  previewServiceTemplate,
}) {
  const { t, i18n } = useTranslation();

  const template = previewServiceTemplate.templates[0].template;
  const translation = useMemo(
    () => (key, defaultMessage) => {
      return i18n.exists(`serviceTemplates:${key}`)
        ? t(`serviceTemplates:${key}`)
        : defaultMessage;
    },
    [i18n.language],
  );
  const [formState, setFormState] = useState({});
  const handleFormChange = (data) => {
    setFormState(data);
  };
  const [formSubmitted, setFormSubmitted] = useState(false);

  return (
    <Dialog
      open={true}
      onClose={() => setPreviewServiceTemplate(null)}
      slotProps={{ paper: { sx: { minWidth: 1200 } } }}
    >
      <DialogTitle>{t("service.editTitle")}</DialogTitle>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          console.log(formState);
        }}
      >
        <DialogContent>
          {template && template.schema ? (
            <JsonForms
              schema={JSON.parse(template.schema)}
              uischema={JSON.parse(template.uischema)}
              data={formState}
              renderers={materialRenderers}
              cells={materialCells}
              onChange={({ data }) => handleFormChange(data)}
              config={{ forceShow: formSubmitted }}
              i18n={{ translate: translation }}
            />
          ) : (
            <Typography variant="body1" sx={{ color: "text.secondary" }}>
              {t("client.noTemplateAvailable")}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPreviewServiceTemplate(null)}>
            {t("form.cancel")}
          </Button>
          <Button onClick={() => setFormSubmitted(true)} type="submit">
            {t("form.validate")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

function useChangeQuery() {
  const [_, setSearchParams] = useSearchParams();
  return React.useCallback(
    (newValues) => {
      setSearchParams((prev) => ({
        ...Object.fromEntries(prev),
        ...newValues,
      }));
    },
    [setSearchParams],
  );
}

function useSearch() {
  const changeQuery = useChangeQuery();

  return React.useCallback(
    debounce(
      (term) =>
        changeQuery({
          "page[offset]": 0,
          "filter[name][contains]": term,
        }),
      DEBOUNCE_INPUT,
    ),
    [changeQuery],
  );
}

export default function Services() {
  const { t } = useTranslation();
  const loaderData = useLoaderData();

  const servicesConnection = loaderData?.services?.data?.services;

  const [editServiceTemplate, setEditServiceTemplate] = React.useState(null);
  const [previewServiceTemplate, setPreviewServiceTemplate] =
    React.useState(null);
  const [createDialogOpen, setCreateDialogOpen] = React.useState(false);
  const [deleteService, setDeleteService] = React.useState(null);
  const search = useSearch();
  const [searchParams] = useSearchParams();

  return (
    <>
      <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
        <Typography variant="h1">Deprecated page (will be replaced)</Typography>
        <TextField
          type="search"
          defaultValue={searchParams.get("filter[name][contains]") ?? ""}
          onInput={(e) => search(e.target.value)}
          slotProps={{
            input: {
              endAdornment: <FontAwesomeIcon icon={faSearch} size="sm" />,
            },
          }}
        />
        <Button
          variant="contained"
          startIcon={<FontAwesomeIcon icon={faAdd} />}
          onClick={() => setCreateDialogOpen(true)}
        >
          {t("service.create")}
        </Button>
      </Box>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("services.color")}</TableCell>
            <TableCell>{t("services.name")}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {servicesConnection.data.map((row) => (
            <TableRow key={row.id}>
              <TableCell>
                <Box
                  sx={{
                    backgroundColor: row.color,
                    width: "1rem",
                    height: "1rem",
                  }}
                ></Box>
              </TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell sx={{ display: "flex", direction: "row" }}>
                <IconButton
                  onClick={() => setEditServiceTemplate(row)}
                  sx={{ display: "flex", aspectRatio: "1 / 1" }}
                  size="small"
                >
                  <FontAwesomeIcon icon={faFileCode} fixedWidth />
                </IconButton>
                <IconButton
                  onClick={() => setPreviewServiceTemplate(row)}
                  sx={{ display: "flex", aspectRatio: "1 / 1" }}
                  size="small"
                >
                  <FontAwesomeIcon icon={faEye} fixedWidth />
                </IconButton>
                <IconButton
                  onClick={() => setDeleteService(row)}
                  sx={{ display: "flex", aspectRatio: "1 / 1" }}
                  size="small"
                >
                  <FontAwesomeIcon icon={faTrash} fixedWidth />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination connection={servicesConnection} />
          </TableRow>
        </TableFooter>
      </Table>
      {editServiceTemplate && (
        <ServiceTemplateDialog
          editServiceTemplate={editServiceTemplate}
          setEditServiceTemplate={setEditServiceTemplate}
        />
      )}
      {previewServiceTemplate && (
        <PreviewServiceTemplateDialog
          previewServiceTemplate={previewServiceTemplate}
          setPreviewServiceTemplate={setPreviewServiceTemplate}
        />
      )}
      {createDialogOpen && (
        <CreateServiceDialog onClose={() => setCreateDialogOpen(false)} />
      )}
      {deleteService && (
        <DeleteServiceDialog
          service={deleteService}
          onClose={() => setDeleteService(null)}
        />
      )}
    </>
  );
}
