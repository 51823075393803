import { useState } from "react";
import { useLatest } from "../../../hooks";
import { fetchProductOptions } from "../utils";

const useProductOptions = (filter) => {
  const [options, setOptions] = useState();
  useLatest(
    async (signal) => {
      setOptions(null);
      setOptions(await fetchProductOptions(signal, filter));
    },
    [filter],
  );

  return { options: options ?? [], loading: options == null };
};

export { useProductOptions };
