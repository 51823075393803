import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import * as api from "../services/api";
import { currentLocationFromRequest } from "../components/LocationSelector";
import { useLoaderData } from "react-router";
import { useNavigate } from "../url";

export async function loader({ request }) {
  const res = await api.loadCompany({
    location_id: currentLocationFromRequest(request),
  });

  if (res.data.company == null) {
    throw new Response("Not Found", { status: 404, statusText: "Not Found" });
  }

  return res;
}

export default function Company() {
  const navigate = useNavigate();
  const loaderData = useLoaderData();
  const company = loaderData.data.company;

  return (
    <>
      <Typography variant="h1" sx={{ mb: 2 }}>
        Produkty
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Název</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {company?.products.map((row) => (
            <TableRow
              key={row.id}
              hover
              sx={{ cursor: "pointer" }}
              onClick={() => navigate(`/products/${row.id}`)}
            >
              <TableCell>{row.name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
