import { useState } from "react";
import { useLatest } from "../../../hooks";
import { fetchServiceOptions } from "../utils";

const useServiceOptions = (filter) => {
  const [options, setOptions] = useState();
  useLatest(
    async (signal) => {
      setOptions(null);
      setOptions(await fetchServiceOptions(signal, filter));
    },
    [filter],
  );

  return { options: options ?? [], loading: options == null };
};

export { useServiceOptions };
