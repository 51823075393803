import { useTranslation } from "react-i18next";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

export const ServicesSelectInput = ({
  onChange,
  value,
  services,
  labelKey,
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <FormControl sx={{ my: 1, width: "100%" }} disabled={disabled}>
      <InputLabel htmlFor="service">{t(labelKey)}</InputLabel>
      <Select
        name="service"
        value={value}
        id="select"
        label={t(labelKey)}
        onChange={disabled ? undefined : onChange}
        disabled={disabled}
      >
        {services.map((service) => (
          <MenuItem key={service.id} value={service.id} disabled={disabled}>
            {service.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ServicesSelectInput;
