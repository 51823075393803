import { useLatest } from "../../../hooks";
import { fetchSuggestions } from "../utils";

/**
 * @param {NewReservationDialogState} state
 */
const useSuggestions = (setState, state) => {
  const { option, serviceOption, locationOption, days } = state;

  useLatest(
    async (signal) => {
      if (option == null || serviceOption == null || locationOption == null) {
        setState((state) => ({
          ...state,
          suggestions: [],
          suggestion: null,
          suggestionsLoading: false,
        }));
        return;
      }

      setState((state) => ({
        ...state,
        suggestion: null,
        suggestionsLoading: true,
      }));

      const filter = {
        location_id: locationOption.id,
        service_id: serviceOption.id,
        duration: serviceOption.duration,
        days,
      };

      const suggestions = await fetchSuggestions(signal, filter);
      setState((state) => ({
        ...state,
        suggestion: null,
        suggestionsLoading: false,
        suggestions: suggestions,
      }));
    },
    [option, serviceOption, locationOption, days],
  );
};

export { useSuggestions };
