import "./receptionTypes.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

import { status } from "shared/src/appointment.mjs";

import { BookedClientHeader } from "./BookedClientHeader";
import { BookedClient } from "./BookedClient";
import { appointmentDelayedBy, searchAppointments } from "./utils";

/**
 * A component to display a booked client on the reception dashboard
 *
 * @typedef {object} BookedClientListProps
 * @property {Array<Appointment>} appointments
 * @property {function} setSnackbarMessage Setter function for the snackbar message
 * @property {function} setSnackbarTitle Setter function for the snackbar title
 * @property {function} setSnackbarOpen Setter function for the snackbar open state
 * @property {function} setIsHovered Setter function for the isHovered state
 * @property {function} setNewAdhocDialogOptions Setter function for the Adhoc dialog options
 *
 * @param {BookedClientListProps} props
 * @returns {JSX.Element}
 */

const BookedClientList = ({
  appointments,
  setSnackbarMessage,
  setSnackbarTitle,
  setSnackbarOpen,
  setIsHovered,
  setNewAdhocDialogOptions,
}) => {
  const [bookedClients, setBookedClients] = useState(appointments);
  const [searchTerm, setSearchTerm] = useState("");
  const { t } = useTranslation();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    if (searchTerm) {
      setBookedClients(
        searchAppointments(appointments, searchTerm, [status.BOOKED]),
      );
    } else {
      setBookedClients(
        appointments.filter(
          (appointment) =>
            appointment.status_id === status.BOOKED &&
            appointmentDelayedBy(appointment.start) < 20,
        ),
      );
    }
  }, [appointments, searchTerm]);

  return (
    <Card onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography variant="h2">{t("reception.booked_clients")}</Typography>
          <TextField
            id="outlined-basic"
            variant="outlined"
            size="small"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            placeholder={t("reception.search")}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "divider",
                },
                "& input::placeholder": {
                  opacity: 0.9,
                  color: "var(--mui-palette-_components-heading-color)",
                },
              },
            }}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </InputAdornment>
                ),
              },
            }}
          />
        </Box>

        <Box
          sx={{
            display: "grid",
            gap: "0 1rem",
            gridAutoFlow: "row",
            gridTemplateColumns: "repeat(3, 1fr)",
          }}
        >
          <BookedClientHeader />
          <BookedClientHeader />
          <BookedClientHeader />
          {bookedClients.slice(0, 15).map((appointment) => (
            <BookedClient
              appointment={appointment}
              key={appointment.id}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarTitle={setSnackbarTitle}
              setSnackbarOpen={setSnackbarOpen}
              setIsHovered={setIsHovered}
              setNewAdhocDialogOptions={setNewAdhocDialogOptions}
            />
          ))}
        </Box>
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            marginTop: "2rem",
            alignItems: "center",
            gap: "1rem",
          }}
        ></div>
      </CardContent>
    </Card>
  );
};

BookedClientList.displayName = "BookedClientList";
export { BookedClientList };
