import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { debounce } from "shared/src/util.mjs";
import * as api from "../../services/api";
import { WIZARD_STEPS } from "./hooks/useWizardSteps";

const WizardContext = createContext();

export const useWizardContext = () => {
  const context = useContext(WizardContext);
  if (!context) {
    throw new Error("useWizardContext must be used within a WizardProvider");
  }
  return context;
};

export const WizardProvider = ({ children }) => {
  const [activeStep, setActiveStep] = useState(WIZARD_STEPS.PREPARATION);
  const [floorManagerNotes, setFloorManagerNotes] = useState("");
  const [receptionistNotes, setReceptionistNotes] = useState({
    service: "",
    number: 0,
    type: "",
    quickInfo: "",
    doctorRequired: false,
    unit: "earliest",
  });
  const [clientData, setClientData] = useState(null);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [filledFormsData, setFilledFormsData] = useState({});
  const [appointmentStatus, setAppointmentStatus] = useState(null);
  const [coordinatorId, setCoordinatorId] = useState("");
  const [doctorId, setDoctorId] = useState("");
  const [elapsedTime, setElapsedTime] = useState(0);

  const elapsedTimeRef = useRef(0);
  const currentAppointmentId = clientData?.nextAppointment?.id || null;

  const saveFloorManagerNote = useCallback(
    debounce(async (appointmentId, noteContent) => {
      if (!appointmentId || !noteContent.trim()) return;

      try {
        await api.updateAppointmentNotes({
          appointment_id: appointmentId,
          note_type: "floor_manager",
          note_content: noteContent,
        });
      } catch (error) {
        console.error("Error updating floor manager note:", error);
      }
    }, 600),
    [],
  );

  useEffect(() => {
    if (currentAppointmentId && floorManagerNotes.trim()) {
      saveFloorManagerNote(currentAppointmentId, floorManagerNotes);
    }
  }, [floorManagerNotes, currentAppointmentId, saveFloorManagerNote]);

  useEffect(() => {
    const initializeWizardState = async () => {
      if (!currentAppointmentId) return;

      try {
        const today = new Date();
        const startOfDay = new Date(today.setHours(0, 0, 0, 0));

        const appointmentResponse = await api.loadAppointments({
          filter: {
            id: { eq: currentAppointmentId },
            start: {
              gte: startOfDay.toISOString(),
            },
          },
        });

        if (appointmentResponse?.data.appointments?.data?.length) {
          setAppointmentStatus(
            appointmentResponse.data.appointments.data[0].status_id,
          );
        }

        const response = await api.fetchAppointmentState(currentAppointmentId);
        if (response?.data?.appointmentState) {
          setActiveStep(
            response.data.appointmentState.activeStep ||
              WIZARD_STEPS.PREPARATION,
          );
          setElapsedTime(response.data.appointmentState.elapsedSeconds || 0);
          elapsedTimeRef.current =
            response.data.appointmentState.elapsedSeconds || 0;
        }
      } catch (error) {
        console.error("Error initializing wizard state:", error);
        setActiveStep(WIZARD_STEPS.PREPARATION);
        setElapsedTime(0);
        elapsedTimeRef.current = 0;
      }
    };

    initializeWizardState();
  }, [currentAppointmentId]);

  const getFormPrefillData = useCallback((template) => {
    if (!template) return {};

    try {
      const parsedSchema = JSON.parse(template.schema);
      const defaultData = {};

      Object.keys(parsedSchema.properties || {}).forEach((key) => {
        defaultData[key] = "";
      });

      return defaultData;
    } catch (error) {
      console.error("Error parsing template schema:", error);
      return {};
    }
  }, []);

  const handleFormSubmit = async () => {
    if (!currentAppointmentId) {
      console.error("No appointment ID found");
      return false;
    }

    try {
      const filledDataEntries = Object.entries(filledFormsData);
      for (const [templateId, filledData] of filledDataEntries) {
        await api.createFilledServiceTemplate({
          appointment_id: currentAppointmentId,
          template_id: templateId,
          filled_data: filledData,
        });
      }
      return true;
    } catch (error) {
      console.error("Error submitting forms:", error);
      return false;
    }
  };

  return (
    <WizardContext.Provider
      value={{
        activeStep,
        setActiveStep,
        receptionistNotes,
        setReceptionistNotes,
        floorManagerNotes,
        setFloorManagerNotes,
        coordinatorId,
        setCoordinatorId,
        doctorId,
        setDoctorId,
        clientData,
        setClientData,
        getFormPrefillData,
        currentAppointmentId,
        setFilledFormsData,
        filledFormsData,
        handleFormSubmit,
        selectedServiceId,
        setSelectedServiceId,
        elapsedTime,
        setElapsedTime,
        appointmentStatus,
      }}
    >
      {children}
    </WizardContext.Provider>
  );
};
