import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Stack,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { config } from "../config/config";
import TranslationSwitcher from "../components/TranslationSwitcher";

const AUTH_TOKEN_KEY = "labs1503.auth.auth_token";

export default function RFIDAuth() {
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [pin, setPin] = useState("");
  const [isTypingPin, setIsTypingPin] = useState(false);
  const rfidInputRef = useRef(null);
  const pinInputRef = useRef(null);
  const expectedRFIDLength = 10;
  const expectedPINLength = 6;

  useEffect(() => {
    const handleRFIDInput = (event) => {
      if (isTypingPin) return;

      const input = event.target.value.trim();
      if (!input) return;

      if (input.length === expectedRFIDLength) {
        sendToBackend(input, "");
        event.target.value = "";
      }
    };

    const focusRFIDInput = () => {
      if (
        rfidInputRef.current &&
        document.activeElement !== pinInputRef.current &&
        !isTypingPin
      ) {
        rfidInputRef.current.focus();
      }
    };

    const rfidInput = rfidInputRef.current;
    if (rfidInput) {
      rfidInput.addEventListener("input", handleRFIDInput);
      focusRFIDInput();
    }

    const interval = setInterval(focusRFIDInput, 500);

    return () => {
      if (rfidInput) {
        rfidInput.removeEventListener("input", handleRFIDInput);
      }
      clearInterval(interval);
    };
  }, [isTypingPin]);

  const handlePinChange = (event) => {
    const input = event.target.value
      .replace(/\D/g, "")
      .slice(0, expectedPINLength);
    setPin(input);
    setIsTypingPin(true);

    if (input.length === 0) {
      setIsTypingPin(false);
    }
  };

  const handleSubmit = () => {
    if (pin.length === expectedPINLength) {
      sendToBackend("", pin);
    }
  };

  const sendToBackend = async (rfid, pin) => {
    if (!rfid && pin.length !== expectedPINLength) return;

    setLoading(true);
    setError("");

    try {
      const response = await fetch(`${config.apiUrl}/authenticate-rfid`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ rfid, pin }),
      });

      const data = await response.json();

      if (response.ok) {
        localStorage.setItem(AUTH_TOKEN_KEY, data.token);

        if (data.redirectUrl) {
          window.location.href = data.redirectUrl;
        } else if (data.appointment?.client_id) {
          window.location.href = `/clients/${data.appointment.client_id}`;
        } else {
          setError(
            "You are not assigned to an active appointment. Please contact the Floor Manager.",
          );
        }
      } else {
        setError(data.error || "Authentication failed.");
      }
    } catch (err) {
      console.error("Error sending credentials:", err);
      setError("Network error. Please try again.");
    } finally {
      setLoading(false);
      setPin("");
    }
  };

  return (
    <Box sx={{ display: "flex", height: "100vh", position: "relative" }}>
      <input
        ref={rfidInputRef}
        id="rfidHiddenInput"
        type="text"
        style={{ position: "absolute", opacity: 0, pointerEvents: "none" }}
      />

      <Box
        flex={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        px={2}
        bgcolor="#ffffff"
        position="relative"
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            padding: 4,
            borderRadius: 2,
            border: "1px solid #C3E4E2",
            boxShadow: "0 0 12px #C3E4E2",
            width: 420,
            textAlign: "center",
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold", mb: 3, color: "#12215a" }}
          >
            {t("auth.title", "Přihlášení do systému")}
          </Typography>

          <Stack
            direction="row"
            spacing={3}
            alignItems="center"
            justifyContent="center"
            sx={{ mb: 3 }}
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <img
                src="/img/rfid_login.svg"
                alt="RFID login"
                style={{ width: 48, height: 48 }}
              />
              <Typography variant="caption" sx={{ mt: 1 }}>
                {t("auth.cardInstruction", "Načtěte kartu")}
              </Typography>
            </Box>

            <Typography variant="body2" sx={{ color: "#999" }}>
              {t("auth.or", "nebo")}
            </Typography>

            <Box display="flex" flexDirection="column" alignItems="center">
              <TextField
                inputRef={pinInputRef}
                value={pin}
                onChange={handlePinChange}
                variant="outlined"
                label={t("auth.pinLabel", "Napište 6 místný kód")}
                type="password"
                slotProps={{ maxLength: expectedPINLength }}
                sx={{
                  width: "160px",
                  "& input": {
                    textAlign: "center",
                    fontSize: "1.2rem",
                    letterSpacing: "0.3rem",
                  },
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && pin.length === expectedPINLength) {
                    handleSubmit();
                  }
                }}
              />
            </Box>
          </Stack>

          <Button
            variant="contained"
            color="primary"
            sx={{ width: "100%" }}
            onClick={handleSubmit}
            disabled={loading || pin.length !== expectedPINLength}
          >
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              t("auth.submit", "Přihlásit se")
            )}
          </Button>

          {error && (
            <Typography color="error" sx={{ marginTop: 2 }}>
              {error}
            </Typography>
          )}

          <Box
            position="absolute"
            bottom={16}
            left={0}
            width="100%"
            display="flex"
            justifyContent="center"
          >
            <Typography
              variant="caption"
              sx={{ color: "#9EA8C3", fontSize: "1rem" }}
            >
              &copy; {new Date().getFullYear()} Svět rovnátek. All Rights
              Reserved.
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        flex={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgcolor="#2F586A"
        color="white"
        flexDirection="column"
        px={4}
      >
        <img
          src="/img/logo.svg"
          alt="Svět rovnátek logo"
          style={{ maxWidth: "200px", marginBottom: "1.5rem" }}
        />
      </Box>

      <Box position="absolute" bottom={16} left={16}>
        <TranslationSwitcher />
      </Box>
    </Box>
  );
}
