import { useState } from "react";
import { useLatest } from "../../../hooks";
import { fetchLocationOptions } from "../utils";

const useLocationOptions = () => {
  const [options, setOptions] = useState();
  useLatest(async (signal) => {
    setOptions(await fetchLocationOptions(signal));
  }, []);

  return { options: options ?? [], loading: options == null };
};

export { useLocationOptions };
