import "./receptionTypes.js";
import { Box, Card, CardContent, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { status } from "shared/src/appointment.mjs";
import { TreatedClientHeader } from "./TreatedClientHeader";
import { TreatedClient } from "./TreatedClient";

/**
 * A component to display a list of treated clients
 *
 * @typedef {object} TreatedClientListProps
 * @property {Array<Appointment} appointments
 * @property {function} setNewReservationDialogOptions Set the options for the reservation dialog
 * @property {function} setIsHovered Setter function for the isHovered state
 *
 * @param {TreatedClientListProps} props
 * @returns {JSX.Element}
 */
export const TreatedClientList = ({
  appointments,
  setNewReservationDialogOptions,
  setIsHovered,
}) => {
  const [treatedClients, setTreatedClients] = useState(appointments);
  const { t } = useTranslation();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    setTreatedClients(
      appointments
        .filter((appointment) => appointment.status_id === status.TREATED)
        .sort(
          (a, b) =>
            new Date(
              a.audit.data.findLast(
                (change) =>
                  change?.changed_fields?.status_id === String(status.TREATED),
              )?.created_at,
            ).getTime() -
            new Date(
              b.audit.data.findLast(
                (change) =>
                  change?.changed_fields?.status_id === String(status.TREATED),
              )?.created_at,
            ).getTime(),
        ),
    );
  }, [appointments]);

  return (
    <Card onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h2" gutterBottom>
            {t("reception.treated_clients")}
          </Typography>
        </Box>

        <div
          style={{
            display: "grid",
            gridAutoFlow: "row",
            gridTemplateColumns: "1fr",
          }}
        >
          <TreatedClientHeader />

          {treatedClients.map((appointment) => (
            <TreatedClient
              key={appointment.id}
              appointment={appointment}
              setNewReservationDialogOptions={setNewReservationDialogOptions}
            />
          ))}
        </div>
      </CardContent>
    </Card>
  );
};
