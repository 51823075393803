/**
 * @typedef GroupCapacity
 * @property {number} capacity_minutes
 * @property {number} used_minutes
 * @property {Record<string, number} usage
 * @property {string[]} groups
 */

import { groupBy, selectKeys, sum } from "./util.mjs";

export const status = {
  BOOKED: 0,
  CONFIRMED: 1,
  WAITING: 2,
  TREATING: 3,
  TREATED: 4,
  FINISHED: 5,
  NOSHOW: 6,
  ASSIGNED: 20,
};

/**
 * @param {GroupCapacity} capacityMut
 * @param {string} groupId
 * @param {number} minutes
 */
function addUsedMinutes(capacityMut, groupId, minutes) {
  capacityMut.used_minutes += minutes;
  capacityMut.usage[groupId] = (capacityMut.usage[groupId] ?? 0) + minutes;
}

/**
 * @param {GroupCapacity[]} singleGroupCapacitiesMut
 * @param {Record<string, number} usageByGroupIdMut
 */
function useSingleGroupCapacities(singleGroupCapacitiesMut, usageByGroupIdMut) {
  for (const c of singleGroupCapacitiesMut) {
    const groupId = c.groups[0];
    const usage = usageByGroupIdMut[groupId] ?? 0;

    const sub = Math.min(c.capacity_minutes, usage);
    addUsedMinutes(c, groupId, sub);
    const minutesLeft = usage - sub;

    if (minutesLeft > 0) {
      usageByGroupIdMut[groupId] = minutesLeft;
    } else {
      delete usageByGroupIdMut[groupId];
    }
  }
}

/**
 * @param {GroupCapacity[]} multiGroupCapacities
 * @param {Record<string, number} usageByGroupIdMut
 */
function useMultiGroupCapacitiesWithinMultiCapacity(
  multiGroupCapacities,
  usageByGroupIdMut,
) {
  let iteration = 0;
  iteration: while (true) {
    const capacitiesCopy = multiGroupCapacities.map((c) => ({
      ...c,
      usage: { ...c.usage },
    }));

    const capacityByIndex = Object.fromEntries(
      capacitiesCopy.map((c, i) => [i, c]),
    );

    const groupToCapacityIndexes = {};
    for (const i in capacitiesCopy) {
      for (const gid of capacitiesCopy[i].groups) {
        const arr = groupToCapacityIndexes[gid] ?? [];
        arr.push(i);
        groupToCapacityIndexes[gid] = arr;
      }
    }

    const interestingUsage = selectKeys(
      usageByGroupIdMut,
      Object.keys(groupToCapacityIndexes),
    );

    const usedMinutes = [];

    const entries = Object.entries(interestingUsage);
    const entriesLen = entries.length;

    for (let i = iteration; i < iteration + entries.length; i++) {
      const index = i % entriesLen;
      const [groupId, usage] = entries[index];
      const capacityIndexes = groupToCapacityIndexes[groupId] ?? [];
      const available = sum(
        capacityIndexes.map((i) => {
          const c = capacityByIndex[i];

          return c.capacity_minutes - c.used_minutes;
        }),
      );

      if (available < usage) {
        if (iteration >= Object.keys(interestingUsage).length) {
          return;
        }

        iteration++;
        continue iteration;
      }

      let m = usage;
      for (const i of capacityIndexes) {
        const c = capacityByIndex[i];
        const sub = Math.min(c.capacity_minutes - c.used_minutes, m);

        if (sub !== 0) {
          usedMinutes.push([i, groupId, sub]);
          addUsedMinutes(c, groupId, sub);
        }

        m = m - sub;

        if (m === 0) {
          delete interestingUsage[groupId];
          break;
        }
      }
    }

    if (Object.keys(interestingUsage).length === 0) {
      for (const gid of Object.keys(groupToCapacityIndexes)) {
        delete usageByGroupIdMut[gid];
      }
    }

    for (const [i, groupId, sub] of usedMinutes) {
      addUsedMinutes(multiGroupCapacities[i], groupId, sub);
    }

    break;
  }
}

/**
 * @param {GroupCapacity[]} multiGroupCapacitiesMut
 * @param {Record<string, number} usageByGroupIdMut
 */
function useMultiGroupCapacities(multiGroupCapacitiesMut, usageByGroupIdMut) {
  const groupInCapacitiesCount = multiGroupCapacitiesMut.reduce((acc, c) => {
    for (const g of c.groups) {
      acc[g] = (acc[g] ?? 0) + 1;
    }

    return acc;
  }, {});

  const gcc = groupBy(
    Object.entries(groupInCapacitiesCount),
    ([_, x]) => x === 1,
  );

  const groupsWithinSingleCapacity = new Set(
    Object.keys(Object.fromEntries(gcc[true] ?? [])),
  );

  for (let i = multiGroupCapacitiesMut.length - 1; i >= 0; i--) {
    const c = multiGroupCapacitiesMut[i];
    const interestingGroupIds = c.groups.filter((gid) =>
      groupsWithinSingleCapacity.has(gid),
    );

    if (interestingGroupIds.length === 0) {
      continue;
    }

    const usageByGroupId = selectKeys(usageByGroupIdMut, interestingGroupIds);
    const usage = sum(Object.values(usageByGroupId));

    const sub = Math.min(c.capacity_minutes, usage);

    const minutesLeft = usage - sub;

    if (minutesLeft > 0) {
      // not enough capacity
      return;
    } else {
      for (const groupId of Object.keys(usageByGroupId)) {
        addUsedMinutes(c, groupId, usageByGroupIdMut[groupId]);
        delete usageByGroupIdMut[groupId];
      }
    }
  }

  useMultiGroupCapacitiesWithinMultiCapacity(
    multiGroupCapacitiesMut.filter((c) => c.capacity_minutes > c.used_minutes),
    usageByGroupIdMut,
  );
}

/**
 * @param {GroupCapacity[]} groupCapacities
 * @param {Record<string, number} usageByGroupId
 *
 * @returns {{capacities: GroupCapacity[], usage: Record<string, number}}
 */
export function useGroupCapacities(groupCapacities, usageByGroupId) {
  const groupCapacitiesCopy = groupCapacities.map((c) => ({ ...c }));
  const gc = groupBy(groupCapacitiesCopy, (c) => c.groups.length === 1);

  const singleGroupCapacities = gc[true] ?? [];
  const multiGroupCapacities = gc[false] ?? [];

  const usageCopy = { ...usageByGroupId };

  useSingleGroupCapacities(singleGroupCapacities, usageCopy);
  useMultiGroupCapacities(multiGroupCapacities, usageCopy);

  return { capacities: groupCapacitiesCopy, usage: usageCopy };
}
