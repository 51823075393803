import { config } from "../config/config";
import * as auth from "../auth";
import { saveIntendedUrl } from "../utils/util";
import { workingDays } from "shared/src/calendar.mjs";

/**
 * Syntax highlighting for gql in vscode
 * @see {@link https://stackoverflow.com/questions/60643685/there-is-a-way-to-syntax-highlight-graphql-typedef-inside-grave-accent-in}
 */
const gql = String.raw;

/**
 * @param {string} path
 * @param {RequestInit} [opts]
 */
async function apiFetch(path, opts) {
  const authToken = auth.getAuthenticationToken();

  const resp = await fetch(`${config.apiUrl}${path}`, {
    ...opts,
    headers: {
      ...opts?.headers,
      ...(authToken ? { Authorization: `Bearer ${authToken}` } : {}),
    },
  });

  if (resp.ok) {
    return resp.json();
  } else if (resp.status === 401) {
    saveIntendedUrl(window.location.pathname + window.location.search);
    auth.reAuthenticate();
    throw new Response("Unauthorized", { status: 401 });
  } else {
    throw new Error(`Failed to load resource at path ${path}.`);
  }
}

function workingHours(selection) {
  return workingDays.map((day) => day + " {" + selection + "}").join(" ");
}

export async function loadAppointments(payload) {
  return loadGraphql({
    query:
      "query appointments($filter: AppointmentConnectionFilterInput!, $order: AppointmentConnectionOrderInput) { appointments(filter: $filter, order: $order) {data {id calendar_id start end status_id description client {id first_name last_name birth_number phone} service {id color name} adhoc_type audit {data {created_at changed_fields}} }} }",
    variables: payload,
  });
}

export async function loadCalendarAppointments(payload) {
  return loadGraphql({
    query: `query appointments($filter: AppointmentConnectionFilterInput!) {
      appointments(filter: $filter) {
        data {
          id calendar_id start end status_id description
          client {id first_name last_name}
          service {id color name}
          audit {data {created_at changed_fields}}
        }
      }
    }`,
    variables: payload,
  });
}

export async function loadFlooreManagerAppointments(payload) {
  return loadGraphql({
    query: `query appointments($filter: AppointmentConnectionFilterInput! $order: AppointmentConnectionOrderInput $auditFilter: AuditConnectionFilterInput) {
        appointments(filter: $filter, order: $order) {
          data {
            id calendar_id location_id actual_calendar_id start end status_id description adhoc_type parent_id
            client {id first_name last_name}
            service {id color name}
            audit(filter: $auditFilter) {data {created_at changed_fields row_data action}}
            employee {id first_name last_name}
          }
        } 
      }`,
    variables: payload,
  });
}

/**
 * @param {import("shared/src/gql-types.mjs").PatchAppointmentInput} payload
 */
export function patchAppointment(payload) {
  return loadGraphql({
    query:
      "mutation patchAppointment($input: PatchAppointmentInput!) {patchAppointment(input: $input) {id} }",
    variables: { input: payload },
  });
}

/**
 * @param {import('shared/src/gql-types.mjs').CreateAppointmentInput} payload
 */
export function createAppointment(payload) {
  return loadGraphql({
    query:
      "mutation createAppointment($input: CreateAppointmentInput!) {createAppointment(input: $input) {id} }",
    variables: { input: payload },
  });
}

/**
 * @param {import('shared/src/gql-types.mjs').CreateCalendarInput} payload
 */
export function createCalendar(payload) {
  return loadGraphql({
    query:
      "mutation createCalendar($input: CreateCalendarInput!) {createCalendar(input: $input) {id}}",
    variables: { input: payload },
  });
}

export function deleteAppointment(payload) {
  return loadGraphql({
    query:
      "mutation deleteAppointment($input: DeleteAppointmentInput!) {deleteAppointment(input: $input) {id} }",
    variables: { input: payload },
  });
}

export async function loadCalendarData(variables) {
  return loadGraphql({
    query: `query data($calendarFilter: CalendarConnectionFilterInput) {
      appointmentStatuses {data {id name}}
      locations {data {id name working_hours {${workingHours("valid_from valid_to from to")}}}}
      calendars(filter: $calendarFilter) {data {id title: name location_id}}
    }`,
    variables: variables,
  });
}

export function loadClients(variables) {
  return loadGraphql({
    query:
      "query clients($page: PageInput!, $filter: ClientConnectionFilterInput) { clients(page: $page, filter: $filter) {page {limit offset} count data {id first_name last_name}}}",
    variables: variables,
  });
}

export function loadServicesWithTemplates(variables) {
  return loadGraphql({
    query: `query services($page: PageInput!, $filter: ServiceConnectionFilterInput) {
      services(page: $page, filter: $filter) { 
        page {
          limit 
          offset
        } 
        count 
        data {
          id 
          name 
          color 
          visible
          templates {
            id 
            version 
            template 
            service_id
          }
        }
      }
    }`,
    variables,
  });
}

export function loadCalendars(variables) {
  return loadGraphql({
    query: `query calendars($page: PageInput!, $filter: CalendarConnectionFilterInput) {
         calendars(page: $page, filter: $filter) {page {limit offset} count data {id name service_type visible location {name}}}
      }`,
    variables,
  });
}

export function loadCalendarEditData(variables) {
  return loadGraphql({
    query:
      "query calendar($id: ID!) { calendar(id: $id) {id name visible location {name}} }",
    variables,
  });
}

/**
 * @param {object} variables
 * @param {import('shared/src/gql-types.mjs').LocationSettingsConnectionFilterInput} variables.locationSettingsFilter
 * @param {import('shared/src/gql-types.mjs').LocationServiceConnectionFilterInput} variables.locationServicesFilter
 */
export function loadReservationSettingsEditData(variables) {
  return loadGraphql({
    query: gql`
      query data(
        $locationSettingsFilter: LocationSettingsConnectionFilterInput!
        $locationServicesFilter: LocationServiceConnectionFilterInput
      ) {
        locationServices(filter: $locationServicesFilter) {
          data {
            duration
            service_id
            service {
              name
            }
          }
        }
        locationSettings(filter: $locationSettingsFilter) {
          data
        }
      }
    `,
    variables,
  });
}

export function loadReservationSettingsData(variables) {
  return loadGraphql({
    query: `query data($serviceUsageReportParams: ServiceUsageReportParams!, $locationSettingsFilter: LocationSettingsConnectionFilterInput!) {
      serviceUsageReport(params: $serviceUsageReportParams) {from to usage capacity available_minutes}
      locationSettings(filter: $locationSettingsFilter) { data }
    }`,
    variables,
  });
}

export function patchLocationSettings(payload) {
  return loadGraphql({
    query:
      "mutation patchLocationSettings($input: PatchLocationSettingsInput!) {patchLocationSettings(input: $input) }",
    variables: { input: payload },
  });
}

export function loadLocationEditData(variables) {
  return loadGraphql({
    query: `query location($id: ID!) { location(id: $id) {id name working_hours {${workingHours("valid_from valid_to from to")}}} }`,
    variables,
  });
}

/**
 * @param {import('shared/src/gql-types.mjs').PatchLocationInput} payload
 */
export function patchLocation(payload) {
  return loadGraphql({
    query:
      "mutation patchLocation($input: PatchLocationInput!) {patchLocation(input: $input) {id} }",
    variables: { input: payload },
  });
}

export function loadLocations(variables) {
  return loadGraphql({
    query:
      "query locations($page: PageInput!) { locations(page: $page) { page {limit offset} count data {id name}}}",
    variables,
  });
}

export function loadCompany(variables) {
  return loadGraphql({
    query: `query data($location_id: ID) {
      company(location_id: $location_id) {
        products {id name}
      }
    }`,
    variables,
  });
}

export function loadProduct(variables) {
  return loadGraphql({
    query: `query data($id: ID!) {
      product(id: $id) {
        id
        name
        services {
          id
          name
          color
          type
        }
      }
    }`,
    variables,
  });
}

/**
 * @param {{query: string, operationName: string, variables: object}} params
 * @param {RequestInit} [opts]
 */
async function loadGraphql(params, opts) {
  return apiFetch(`/api`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(params),
    ...opts,
  });
}

export function loadClientData(variables) {
  return loadGraphql({
    query: `
      query client($id: ID!) {
        client(id: $id) {
          id
          first_name
          last_name
          email
          birth_number
          phone
          street
          city
          post_code
        }
      }
    `,
    variables,
  });
}

/**
 * @param {import('shared/src/gql-types.mjs').PatchServiceInput} payload
 */
export function patchService(payload) {
  return loadGraphql({
    query:
      "mutation patchService($input: PatchServiceInput!) {patchService(input: $input) {id} }",
    variables: { input: payload },
  });
}

/**
 * @param {import('shared/src/gql-types.mjs').PatchCalendarInput} payload
 */
export function patchCalendar(payload) {
  return loadGraphql({
    query:
      "mutation patchCalendar($input: PatchCalendarInput!) {patchCalendar(input: $input) {id} }",
    variables: { input: payload },
  });
}

/**
 * @param {{filter: import('shared/src/gql-types.mjs').ClientConnectionFilterInput}} variables
 * @param {{signal?: AbortSignal}} [opts]
 */
export function clientSearch(variables, opts) {
  return loadGraphql(
    {
      query: `query clients($page: PageInput!, $filter: ClientConnectionFilterInput) { 
      clients(page: $page, filter: $filter) {
        page {limit offset}
        count
        data {id first_name last_name birth_number phone street city post_code location_id}
      }
    }`,
      variables: variables,
    },
    opts,
  );
}

/**
 * @param {{filter: import('shared/src/gql-types.mjs').LocationServiceConnectionFilterInput}} variables
 * @param {{signal?: AbortSignal}} [opts]
 */
export function locationServiceOptions(variables, opts) {
  return loadGraphql(
    {
      query: `query data($filter: LocationServiceConnectionFilterInput) {
        locationServices(filter: $filter) {
          data {
            duration
            service {id name}
          }
        }
      }`,
      variables,
    },
    opts,
  );
}

export function fetchServiceDetail(variables, opts) {
  return loadGraphql(
    {
      query: `query data($id: ID!) {
        service(id: $id) {
          id
          name
          color
          locationServices {
            duration
            location {
              id
              name
            }
          }
        }
      }`,
      variables,
    },
    opts,
  );
}

export function loadserviceEditData(variables, opts) {
  return loadGraphql(
    {
      query: `query data($id: ID! $location_id: ID!) {
        service(id: $id) {
          id
          name
          color
          locationServices {
            duration
            location {
              id
              name
            }
          }
        }
        company(location_id: $location_id) {
          locations {id name}
        }
      }`,
      variables,
    },
    opts,
  );
}

/**
 * @param {{filter: import('shared/src/gql-types.mjs').ProductFilterInput}} variables
 * @param {{signal?: AbortSignal}} [opts]
 */
export function productOptions(variables, opts) {
  return loadGraphql(
    {
      query: `query data($filter: ProductFilterInput) {
        products(filter: $filter) {
          data {
            id
            name
          }
        }
      }`,
      variables,
    },
    opts,
  );
}

/**
 * @param {{signal?: AbortSignal}} [opts]
 */
export function locationOptions(opts) {
  return loadGraphql(
    {
      query: `query locations {
         locations {data {id name company_id}}
      }`,
    },
    opts,
  );
}

/**
 * @param {{filter: import('shared/src/gql-types.mjs').AppointmentConnectionFilterInput}} variables
 * @param {{signal?: AbortSignal}} [opts]
 */
export function futureAppointments(variables, opts) {
  return loadGraphql(
    {
      query: `query($filter: AppointmentConnectionFilterInput!) {
      appointments(filter: $filter, order: {asc: start}) {
        data {
          id
          start
          service {
            name
          }
        }
      }
    }`,
      variables,
    },
    opts,
  );
}

/**
 * @param {object} variables
 * @param {{signal?: AbortSignal}} [opts]
 */
export function appointmentSuggestions(variables, opts) {
  return loadGraphql(
    {
      query: `query appointmentSuggestions($filter: AppointmentSuggestionsFilterInput!) {
         appointmentSuggestions(filter: $filter) {data {start end calendar_id calendar {name}}}
      }`,
      variables,
    },
    opts,
  );
}

export async function loadLegalGuardians(variables) {
  return loadGraphql({
    query: `
      query legalGuardians($client_id: ID!) {
        legalGuardians(client_id: $client_id) {
          id
          first_name
          last_name
          birth_number
          phone
          email
        }
      }
    `,
    variables,
  });
}

export function loadTreatmentTypes(variables) {
  return loadGraphql({
    query: `
      query treatmentTypes($filter: TreatmentTypeFilterInput) {
        treatmentTypes(filter: $filter) {
          id 
          treatment
          note
        }
      }
    `,
    variables: variables,
  });
}

export function loadRiskAllergies(variables) {
  return loadGraphql({
    query: `query riskAllergies($client_id: ID!) {
         riskAllergies(client_id: $client_id) { 
           id
           name
           type
           note
         }
      }`,
    variables,
  });
}

export function loadClientActivityLogs(variables) {
  return loadGraphql({
    query: `
      query clientActivityLogs($client_id: ID!) {
        clientActivityLogs(client_id: $client_id) {
          id
          activity_type
          activity_reference
          description
          content
          attachment
          created_at
        }
      }
    `,
    variables: variables,
  });
}

export function loadClientActivityLog(clientId) {
  return loadGraphql({
    query: `
      query clientActivityLog($id: ID!) {
        clientActivityLog(clientId: $id) {
          id
          activity_type
          activity_reference
          description
          content
          created_at
        }
      }
    `,
    variables: {
      id: clientId,
    },
  });
}

export function createClientActivityLog(payload) {
  return loadGraphql({
    query: `
      mutation createClientActivityLog($input: CreateClientActivityLogInput!) {
        createClientActivityLog(input: $input) {
          id
          client_id
          activity_type
          description
          content
        }
      }
    `,
    variables: { input: payload },
  });
}

/**
 * @param {{signal?: AbortSignal}} [opts]
 */
export function availableNurses(variables, opts) {
  return loadGraphql(
    {
      query: `query availableNurses($filter: EmployeeConnectionFilterInput $order: EmployeeConnectionOrderInput) {
        employees(filter: $filter order: $order) {
          data {id first_name last_name available}}
      }`,
      variables,
    },
    opts,
  );
}

export function loadWorkingHours(variables) {
  return loadGraphql({
    query: `query data($location_id: ID!) {
      location(id: $location_id) {
        id
        working_hours {${workingHours("valid_from valid_to from to")}}
      }
    }`,
    variables: variables,
  });
}

export function loadFlooreManagerEmployees(variables) {
  return loadGraphql({
    query: `query data($location_id: ID!, $employeeFilter: EmployeeConnectionFilterInput, $appointmentFilter: AppointmentConnectionFilterInput!) {
      location(id: $location_id) {
        id
        working_hours {${workingHours("valid_from valid_to from to")}}
      }
      employees(filter: $employeeFilter) {
        data {
          id
          first_name
          last_name
        }
      }
      appointments(filter: $appointmentFilter) {
        data {
          id
          employee_id
          audit {data {created_at changed_fields row_data action}}
        }
      }
    }`,
    variables: variables,
  });
}

/**
 * Fetch the next appointment for a client
 * @param {string} clientId - The ID of the client
 */
export async function getNextAppointmentForClient(clientId) {
  const now = new Date();
  const todayStart = new Date(now.setHours(0, 0, 0, 0)).toISOString();

  return loadGraphql({
    query: `
      query getNextAppointmentForClient($client_id: String!, $todayStart: String!) {
        appointments(
          filter: {
            client_id: { eq: $client_id }
            start: { gte: $todayStart } # Include past and ongoing appointments today
          }
          order: { asc: start }
        ) {
          data {
            id
            start
            calendar_id
            status_id
            service {
              id
              name
            }
            client {
              first_name
              last_name
            }
          }
        }
      }
    `,
    variables: {
      client_id: clientId,
      todayStart, // Fetches all appointments today, even past ones
    },
  });
}

/**
 * Fetch a list of service templates
 * @param {object} filter - Filter for the templates
 * @param {object} page - Pagination info { limit, offset }
 */
export async function loadServiceTemplates({ filter, page }) {
  return loadGraphql({
    query: `
      query AppointmentTemplates($filter: AppointmentTemplateFilterInput, $page: PageInput!) {
        appointmentTemplates(filter: $filter, page: $page) {
          data {
            id
            title
            version
            template
            service_id
          }
          page {
            limit
            offset
          }
          count
        }
      }
    `,
    variables: {
      filter,
      page,
    },
  });
}

/**
 * Fetch a single service template by ID
 * @param {string} id - ID of the service template to fetch
 */
export async function loadServiceTemplateById(id) {
  return loadGraphql({
    query: `
      query ServiceTemplateById($id: ID!) {
        serviceTemplateById(id: $id) {
          id
          version
          template
          service_id
        }
      }
    `,
    variables: {
      id,
    },
  });
}

export function syncEmployeeShifts() {
  return loadGraphql({
    query: `mutation { syncEmployeeShifts }`,
  });
}

export function syncAvailableEmployees() {
  return loadGraphql({
    query: "mutation { syncAvailableEmployees }",
  });
}

/**
 * Create a new service template
 * @param {object} input - The service template input
 */
export async function createServiceTemplate(input) {
  return loadGraphql({
    query: `
      mutation CreateServiceTemplate($input: CreateServiceTemplateInput!) {
        createServiceTemplate(input: $input) {
          id
          version
          template
          service_id
        }
      }
    `,
    variables: {
      input,
    },
  });
}

/**
 * Update a service template
 * @param {string} id - ID of the template to update
 * @param {object} input - The update input
 */
export function patchServiceTemplate(input) {
  return loadGraphql({
    query: `
      mutation patchServiceTemplate($input: PatchServiceTemplateInput!) {
        patchServiceTemplate(input: $input) {
          id
          version
          template
          service_id
        }
      }
    `,
    variables: { input },
  });
}

/**
 * Fetch a list of document templates (for dropdown)
 * @param {object} filter - Filter for the templates
 * @param {object} page - Pagination info { limit, offset }
 */

/**
 * @param {{page: PageInput, filter?: NotificationFilterInput}} variables
 */
export function loadNotifications({ page, filter }) {
  return loadGraphql({
    query: `
      query GetNotifications($page: PageInput!, $filter: NotificationFilterInput) {
        notifications(page: $page, filter: $filter) {
          data {
            id
            category
            type
            title
            description
            date
            linkText
            linkHref
            isRead
            client_id
            employee_id
          }
          page {
            limit
            offset
          }
          count
        }
      }
    `,
    variables: {
      page: {
        limit: parseInt(page.limit) || 10,
        offset: parseInt(page.offset) || 0,
      },
      filter: filter || null,
    },
  });
}

export function loadUnreadNotificationsCount({ clientId, employeeId }, opts) {
  return loadGraphql(
    {
      query: `
      query GetUnreadCount($filter: NotificationFilterInput!) {
        notifications(
          page: { limit: 1, offset: 0 }
          filter: $filter
        ) {
          count
        }
      }
    `,
      variables: {
        filter: {
          isRead: false,
          client_id: clientId,
          employee_id: employeeId,
        },
      },
    },
    opts,
  );
}

export function loadLatestNotifications(
  { limit = 5, clientId, employeeId },
  opts,
) {
  return loadGraphql(
    {
      query: `
      query GetLatestNotifications($page: PageInput!, $filter: NotificationFilterInput) {
        notifications(
          page: $page,
          filter: $filter
        ) {
          data {
            id
            category
            type
            title
            description
            date
            linkText
            linkHref
            isRead
            client_id
            employee_id
          }
        }
      }
    `,
      variables: {
        page: { limit: parseInt(limit), offset: 0 },
        filter: {
          client_id: clientId,
          employee_id: employeeId,
        },
      },
    },
    opts,
  );
}

export function loadNotification(id, opts) {
  return loadGraphql(
    {
      query: `
      query GetNotification($id: ID!) {
        notification(id: $id) {
          id
          category
          type
          title
          description
          date
          linkText
          linkHref
          isRead
          client_id
          employee_id
          client {
            id
            first_name
            last_name
          }
          employee {
            id
            first_name
            last_name
            username
          }
        }
      }
    `,
      variables: { id },
    },
    opts,
  );
}

export function createNotification(input, opts) {
  return loadGraphql(
    {
      query: `
      mutation CreateNotification($input: CreateNotificationInput!) {
        createNotification(input: $input) {
          id
          category
          type
          title
          description
          date
          linkText
          linkHref
          isRead
          client_id
          employee_id
        }
      }
    `,
      variables: { input },
    },
    opts,
  );
}

export function markNotificationsRead(input, opts) {
  return loadGraphql(
    {
      query: `
      mutation MarkNotificationsRead($input: MarkNotificationsReadInput!) {
        markNotificationsRead(input: $input)
      }
    `,
      variables: { input },
    },
    opts,
  );
}

export function markAllNotificationsReadStatus({ isRead }, opts) {
  return loadGraphql(
    {
      query: `
      mutation MarkAllRead($input: MarkNotificationsReadInput!) {
        markNotificationsRead(input: $input)
      }
    `,
      variables: {
        input: {
          isRead,
          ids: [],
        },
      },
    },
    opts,
  );
}

export function createService(input) {
  return loadGraphql({
    query: `
    mutation createService($input: CreateServiceInput!) {
      createService(input: $input) {
        id
        name
        color
      }
    }
  `,
    variables: { input },
  });
}

export function softDeleteService(id) {
  return loadGraphql({
    query: `
      mutation softDeleteService($id: ID!) {
        softDeleteService(id: $id) {
          id
          name
          visible
        }
      }
    `,
    variables: { id },
  });
}

export function loadClientFullData(variables) {
  return loadGraphql({
    query: `
      query clientFullData($id: ID!) {
        client(id: $id) {
          id
          first_name
          last_name
          email
          birth_number
          phone
          street
          city
          post_code
        }
        treatmentTypes {
          id 
          treatment
          note
        }
        riskAllergies(client_id: $id) { 
          id
          name
          type
          note
        }
      }
    `,
    variables,
  });
}

export function fetchAppointmentState(appointmentId) {
  return loadGraphql({
    query: `
      query FetchAppointmentState($appointment_id: ID!) {
        appointmentState(appointment_id: $appointment_id) {
          id
          appointment_id
          activeStep
          elapsedSeconds          
        }
      }
    `,
    variables: { appointment_id: appointmentId },
  });
}

export function fetchAppointmentNotes(appointmentId) {
  return loadGraphql({
    query: `
      query FetchAppointmentNotes($appointment_id: ID!) {
        appointmentNotes(appointment_id: $appointment_id) {
          id
          note_type
          note_content
          doctor_required
          service_id
          reservation_count
          reservation_unit
        }
      }
    `,
    variables: { appointment_id: appointmentId },
  });
}

export function createAppointmentNote(input) {
  return loadGraphql({
    query: `
      mutation CreateAppointmentNote($input: CreateAppointmentNoteInput!) {
        createAppointmentNote(input: $input) {
          id
          note_type
          note_content
          doctor_required
          service_id
          reservation_count
          reservation_unit
        }
      }
    `,
    variables: { input },
  });
}

export async function updateAppointmentNotes(input) {
  return loadGraphql({
    query: `
      mutation UpdateAppointmentNotes($input: UpdateAppointmentNotesInput!) {
        updateAppointmentNotes(input: $input) {
          id
          appointment_id
          note_type
          note_content
          doctor_required
          service_id
          reservation_count
          reservation_unit
        }
      }
    `,
    variables: { input },
  });
}

export function createAppointmentProgressStep(input) {
  return loadGraphql({
    query: `
      mutation CreateAppointmentProgressStep($input: CreateAppointmentProgressStepInput!) {
        createAppointmentProgressStep(input: $input) {
          id
          appointment_id
          step
          time_spent
        }
      }
    `,
    variables: {
      input: {
        appointment_id: input.appointment_id,
        step: input.step,
        time_spent: input.time_spent,
      },
    },
  });
}

export function fetchAppointmentProgressSteps(appointmentId) {
  return loadGraphql({
    query: `
      query FetchAppointmentProgressSteps($appointment_id: ID!) {
        appointmentProgressSteps(appointment_id: $appointment_id) {
          id
          appointment_id
          step
          time_spent
        }
      }
    `,
    variables: { appointment_id: appointmentId },
  });
}

export async function updateAppointmentState(input) {
  return loadGraphql({
    query: `
      mutation UpdateAppointmentState($input: UpdateAppointmentStateInput!) {
        updateAppointmentState(input: $input) {
          appointment_id
          activeStep
          elapsedSeconds
        }
      }
    `,
    variables: { input },
  });
}

/**
 * Fetch a single filled service template by ID
 * @param {string} id - ID of the filled service template
 * @returns {Promise<object>} The fetched filled service template
 */
export async function fetchFilledServiceTemplateById(id) {
  return loadGraphql({
    query: `
      query FilledServiceTemplateById($id: ID!) {
        filledServiceTemplateById(id: $id) {
          id
          appointment_id
          template_id
          filled_data
        }
      }
    `,
    variables: { id },
  });
}

/**
 * Fetch filled service templates by appointment ID
 * @param {string} appointmentId - Appointment ID to filter the templates
 * @param {object} page - Pagination information { limit, offset }
 * @param {object} filter - Additional filters
 * @returns {Promise<object>} The fetched filled service templates
 */
export async function fetchFilledServiceTemplatesByAppointmentId(
  appointmentId,
  page,
  filter = {},
) {
  return loadGraphql({
    query: `
      query FilledServiceTemplatesByAppointmentId(
        $appointment_id: ID!,
        $page: PageInput,
        $filter: FilledServiceTemplateFilterInput
      ) {
        filledServiceTemplatesByAppointmentId(
          appointment_id: $appointment_id,
          page: $page,
          filter: $filter
        ) {
          page {
            limit
            offset
          }
          count
          data {
            id
            appointment_id
            template_id
            filled_data
          }
        }
      }
    `,
    variables: {
      appointment_id: appointmentId,
      page,
      filter,
    },
  });
}

/**
 * Create a new filled service template
 * @param {object} input - The input data for the filled service template
 * @returns {Promise<object>} The created filled service template
 */
export async function createFilledServiceTemplate(input) {
  return loadGraphql({
    query: `
      mutation CreateFilledServiceTemplate($input: CreateFilledServiceTemplateInput!) {
        createFilledServiceTemplate(input: $input) {
          id
          appointment_id
          template_id
          filled_data
        }
      }
    `,
    variables: { input },
  });
}

/**
 * Patch an existing filled service template
 * @param {object} input - The input data for updating the filled service template
 * @returns {Promise<object>} The updated filled service template
 */
export async function patchFilledServiceTemplate(input) {
  return loadGraphql({
    query: `
      mutation PatchFilledServiceTemplate($input: PatchFilledServiceTemplateInput!) {
        patchFilledServiceTemplate(input: $input) {
          id
          appointment_id
          template_id
          filled_data
        }
      }
    `,
    variables: { input },
  });
}

/**
 * Patch an existing appointment progress step
 * @param {object} input - The input data for updating the appointment progress step
 * @returns {Promise<object>} The updated appointment progress step
 */
export async function patchAppointmentProgressStep(input) {
  return loadGraphql({
    query: `
      mutation PatchAppointmentProgressStep($input: PatchAppointmentProgressStepInput!) {
        patchAppointmentProgressStep(input: $input) {
          id
          appointment_id
          step
          time_spent
        }
      }
    `,
    variables: { input },
  });
}

export async function fetchFilledServiceTemplatesByClientId(clientId) {
  const appointmentResponse = await loadGraphql({
    query: `
      query GetClientAppointments($client_id: String!, $start: DateFilterInput!) {
        appointments(
          filter: { client_id: { eq: $client_id }, start: $start }
        ) {
          data {
            id
            start
            service {
              id
              name
            }
          }
        }
      }
    `,
    variables: {
      client_id: String(clientId),
      start: { gte: "2000-01-01T00:00:00Z" },
    },
  });

  if (!appointmentResponse?.data?.appointments?.data?.length) {
    throw new Error("No appointments found for client.");
  }

  const appointmentsMap = {};
  appointmentResponse.data.appointments.data.forEach((app) => {
    appointmentsMap[app.id] = {
      date: app.start,
      serviceName: app.service?.name || "Unknown Service",
    };
  });

  const appointmentIds = Object.keys(appointmentsMap);
  const filledTemplatesResponse = await loadGraphql({
    query: `
      query GetFilledTemplates($appointment_ids: [ID!]!) {
        filledServiceTemplatesByAppointmentIds(
          appointment_ids: $appointment_ids
        ) {
          count
          data {
            id
            appointment_id
            template_id
            filled_data
            template
            employee_name
          }
        }
      }
    `,
    variables: {
      appointment_ids: appointmentIds,
    },
  });

  return (
    filledTemplatesResponse.data?.filledServiceTemplatesByAppointmentIds?.data.map(
      (template) => ({
        ...template,
        appointment_date:
          appointmentsMap[template.appointment_id]?.date || null,
        service_name:
          appointmentsMap[template.appointment_id]?.serviceName || null,
      }),
    ) || []
  );
}

export async function fetchMedicalReportsByClientId(clientId) {
  const response = await loadGraphql({
    query: `
      query GetMedicalReports($client_id: ID!) {
        medicalReportsByClientId(client_id: $client_id) {
          report_id
          report_type
          report_text
          report_created_at
          report_employee_id
          employee_name
        }
      }
    `,
    variables: { client_id: String(clientId) },
  });

  return response.data?.medicalReportsByClientId || [];
}

export function fetchClientNotes(clientId) {
  return loadGraphql({
    query: `
      query FetchClientNotes($client_id: ID!) {
        clientNotes(client_id: $client_id) {
          id
          appointment_id
          note_type
          note_content
          doctor_required
          service_id
          reservation_count
          reservation_unit
        }
      }
    `,
    variables: { client_id: clientId },
  });
}

export async function updateEmployeePin(employeeId, newPin) {
  return loadGraphql({
    query: `
      mutation ChangeEmployeePin($employeeId: ID!, $newPin: String!) {
        changeEmployeePin(employeeId: $employeeId, newPin: $newPin)
      }
    `,
    variables: { employeeId, newPin },
  });
}

export async function getServiceByName(name) {
  return loadGraphql({
    query: `
      query GetServiceByName($name: String!) {
        serviceByName(name: $name) {
          id
          name
          type
          color
          visible
          product_id
        }
      }
    `,
    variables: { name },
  });
}
