import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Paper,
  Stack,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";
import TranslationSwitcher from "../components/TranslationSwitcher";
import { handleLoginWithAzure } from "./../auth";
import { clearIntendedUrl, getIntendedUrl } from "../utils/util";
import { useAuthenticationToken } from "../context/AuthenticationTokenContext";
import { useNavigate } from "../url";
import { useAuth } from "../context/authProvider";

const LoginButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#fff",
  color: theme.palette.primary.dark,
  width: "100%",
  height: "48px",
  border: `1px solid ${theme.palette.primary.dark}`,
  fontWeight: 600,
  fontSize: "1rem",
  "&:hover": {
    backgroundColor: theme.palette.primary.light,
  },
}));

const MicrosoftIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    width="24px"
    height="24px"
    style={{ flexShrink: 0, marginRight: "8px" }}
    {...props}
  >
    <path fill="#f1511b" d="M22 22H3V3h19v19z" />
    <path fill="#80cc28" d="M45 22H26V3h19v19z" />
    <path fill="#00a1f1" d="M22 45H3V26h19v19z" />
    <path fill="#fbbc09" d="M45 45H26V26h19v19z" />
  </svg>
);

const LoginPage = () => {
  const { t } = useTranslation();
  const { instance } = useMsal();
  const navigate = useNavigate();
  const { stayLoggedIn, setStayLoggedIn } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [_, setAuthToken] = useAuthenticationToken();
  const [authToken] = useAuthenticationToken();

  useEffect(() => {
    const intendedUrl = getIntendedUrl();
    if (authToken != null && intendedUrl) {
      navigate(intendedUrl, { replace: true });
      clearIntendedUrl();
    }
  }, [navigate, authToken]);

  const handleLogin = async () => {
    setIsLoading(true);
    try {
      const loginResponse = await instance.loginPopup({
        scopes: [
          "openid",
          "profile",
          "email",
          "Directory.Read.All",
          "GroupMember.Read.All",
          "User.Read",
        ],
        prompt: "select_account",
      });

      instance.setActiveAccount(loginResponse.account);

      let tokenResponse;
      try {
        tokenResponse = await instance.acquireTokenSilent({
          scopes: [
            "openid",
            "profile",
            "email",
            "Directory.Read.All",
            "GroupMember.Read.All",
            "User.Read",
          ],
          account: loginResponse.account,
        });
      } catch (error) {
        console.warn("Silent token failed, using popup:", error);
        tokenResponse = await instance.acquireTokenPopup({
          scopes: [
            "openid",
            "profile",
            "email",
            "Directory.Read.All",
            "GroupMember.Read.All",
            "User.Read",
          ],
          account: loginResponse.account,
        });
      }

      const backendToken = await handleLoginWithAzure(
        tokenResponse,
        stayLoggedIn,
      );
      if (backendToken) setAuthToken(backendToken);

      const intendedUrl = getIntendedUrl();
      navigate(intendedUrl || "/", { replace: true });
    } catch (error) {
      console.error("Login failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        bgcolor="#FFFFFF"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box display="flex" minHeight="100vh" bgcolor="#FFFFFF" position="relative">
      <Box
        flex={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        px={2}
        position="relative"
      >
        <Paper
          sx={{
            p: 4,
            maxWidth: 400,
            width: "100%",
            border: "1px solid #C3E4E2",
            boxShadow: "0px 0px 12px  #C3E4E2",
            borderRadius: "8px",
          }}
        >
          <Typography variant="h1" gutterBottom>
            {t("login.title", "Přihlášení do systému")}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {t("login.description", "Přihlaste se pomocí e-mailu a hesla")}
          </Typography>
          <Stack spacing={2} mt={3}>
            <LoginButton
              variant="contained"
              startIcon={<MicrosoftIcon />}
              onClick={handleLogin}
            >
              {t("login.loginButton", "Přihlásit se Microsoft účtem")}
            </LoginButton>
            <FormControlLabel
              control={
                <Checkbox
                  checked={stayLoggedIn}
                  onChange={(e) => setStayLoggedIn(e.target.checked)}
                />
              }
              label={t("login.stayLoggedIn", "Nechte mě přihlášeného")}
            />
          </Stack>
        </Paper>
        <Box position="absolute" bottom={16} textAlign="center" width="100%">
          <Typography
            variant="caption"
            sx={{ color: "#9EA8C3", fontSize: "1rem" }}
          >
            &copy; {new Date().getFullYear()} Svět rovnátek. All Rights
            Reserved.
          </Typography>
        </Box>
      </Box>

      <Box
        flex={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgcolor="primary.dark"
        color="white"
        flexDirection="column"
        px={4}
      >
        <img
          src="/img/logo.svg"
          alt="Svět rovnátek logo"
          style={{
            maxWidth: "200px",
            marginBottom: "1.5rem",
          }}
        />
      </Box>

      <Box position="absolute" bottom={16} left={16}>
        <TranslationSwitcher />
      </Box>
    </Box>
  );
};

export default LoginPage;
