import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import Link from "../components/Link";
import { currentLocationFromRequest } from "../components/LocationSelector";
import * as api from "../services/api";
import { useLoaderData } from "react-router";
import { round, selectKeys, sum } from "shared/src/util.mjs";
import * as f from "../utils/formatter";
import { useGroupCapacities } from "shared/src/appointment.mjs";

const weeks = 5;

/**
 * @typedef UsageReport
 * @property {Record<string, number>} usage
 * @property {string} from
 * @property {string} to
 * @property {object} capacity
 * @property {number} available_minutes
 *
 * @typedef ServiceGroup
 * @property {string} name
 * @property {string[]} services
 */

export async function loader({ request }) {
  const location_id = currentLocationFromRequest(request);
  if (location_id == null) {
    return {};
  }

  return api.loadReservationSettingsData({
    serviceUsageReportParams: {
      location_id: location_id,
      weeks: weeks,
    },
    locationSettingsFilter: {
      location_id: { eq: location_id },
      key: { eq: "reservation_settings" },
    },
  });
}

function availableMinutes(week, reservationSettings) {
  const bufferCapacity = reservationSettings?.buffer?.capacity ?? 0;
  const available_minutes = week.available_minutes;

  return Math.floor(available_minutes * ((100 - bufferCapacity) / 100));
}

/**
 * @param {object} props
 * @param {UsageReport} props.week
 * @param {Record<string, ServiceGroup>} props.serviceGroups
 */
function UsageReport({ serviceGroups, week, reservationSettings }) {
  const { capacity: originalCapacity, usage, from, to } = week;
  const available_minutes = availableMinutes(week, reservationSettings);

  const capacity = originalCapacity.map((c) => ({
    ...c,
    capacity_minutes: Math.floor((c.capacity / 100) * available_minutes),
    used_minutes: 0,
    usage: {},
  }));

  const usageByGroupId = Object.fromEntries(
    Object.entries(serviceGroups).map(([groupId, group]) => {
      return [groupId, sum(Object.values(selectKeys(usage, group.services)))];
    }),
  );

  const groupServices = new Set(
    Object.values(serviceGroups).flatMap((group) => group.services),
  );

  const allServices = new Set(Object.keys(week.usage));
  const unassignedServices = allServices.difference(groupServices);
  const unassignedMinutes = sum(
    Object.values(selectKeys(usage, Array.from(unassignedServices))),
  );

  const capacities = useGroupCapacities(capacity, usageByGroupId);

  const totalUsage = sum(Object.values(usage));

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell colSpan={4}>
            {f.date(from)} - {f.date(to)}, dostupných minut: {available_minutes}{" "}
            / {week.available_minutes}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Kapacita</TableCell>
          <TableCell>Skupina</TableCell>
          <TableCell>Rezervováno (m)</TableCell>
          <TableCell>Zabírá (%)</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {capacities.capacities.map((c) => {
          return c.groups.map((gid, groupIndex) => {
            return (
              <TableRow key={gid}>
                {groupIndex === 0 && (
                  <TableCell rowSpan={c.groups.length}>
                    {round((c.used_minutes / available_minutes) * 100, 2)}% /{" "}
                    {c.capacity}%
                  </TableCell>
                )}
                <TableCell>{serviceGroups[gid]?.name}</TableCell>
                <TableCell>{c.usage[gid] ?? 0}</TableCell>
                <TableCell>
                  {round(((c.usage[gid] ?? 0) / available_minutes) * 100, 2)}
                </TableCell>
              </TableRow>
            );
          });
        })}

        {Object.entries(capacities.usage).map(([gid, minutes], index) => {
          return (
            <TableRow key={gid}>
              {index === 0 && (
                <TableCell
                  rowSpan={Object.keys(capacities.usage).length}
                ></TableCell>
              )}

              <TableCell>{serviceGroups[gid]?.name}</TableCell>
              <TableCell>{minutes}</TableCell>
              <TableCell>
                {round((minutes / available_minutes) * 100, 2)}
              </TableCell>
            </TableRow>
          );
        })}

        <TableRow>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell>{unassignedMinutes}</TableCell>
          <TableCell>
            {round((unassignedMinutes / available_minutes) * 100, 2)}
          </TableCell>
        </TableRow>
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell>{totalUsage}</TableCell>
          <TableCell>
            {round((totalUsage / available_minutes) * 100, 2)}
          </TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  );
}

export default function ReservationSettings() {
  const loaderData = useLoaderData();
  const reservationSettings =
    loaderData.data?.locationSettings?.data?.reservation_settings;
  const serviceGroups = reservationSettings?.service_groups ?? {};

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mb: 2 }}>
      <Button
        variant="contained"
        startIcon={<FontAwesomeIcon icon={faPencil} />}
        to="/reservation-settings/edit"
        component={Link}
      >
        Upravit parametry
      </Button>
      <Typography variant="h1">Report pro {weeks} týdnů</Typography>
      {loaderData.data?.serviceUsageReport.map((week) => (
        <UsageReport
          key={week.from}
          serviceGroups={serviceGroups}
          reservationSettings={reservationSettings}
          week={week}
        />
      ))}
    </Box>
  );
}
