import React from "react";
import { useTranslation } from "react-i18next";
import { ColumnHeader } from "./ColumnHeader";

/**
 * A component to display the header of the treated client list
 *
 * @typedef {object} BookedClientProps
 *
 * @returns {JSX.Element}
 */
const TreatedClientHeader = () => {
  const { t } = useTranslation();

  return (
    <ColumnHeader>
      <span>{t("reception.client_name")}</span>
      <span>{t("reception.book")}</span>
    </ColumnHeader>
  );
};

export { TreatedClientHeader };
