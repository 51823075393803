import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useWizardContext } from "./AppointmentWizardContext";
import { useWizardSteps, WIZARD_STEPS } from "./hooks/useWizardSteps";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as api from "../../services/api";
import {
  faCalendarCheck,
  faCheckCircle,
  faBellConcierge,
  faCalendarLinesPen,
  faBroom,
} from "@fortawesome/pro-solid-svg-icons";
import { status } from "shared/src/appointment.mjs";

const AppointmentWizard = () => {
  const { t } = useTranslation();
  const {
    activeStep,
    handleFormSubmit,
    currentAppointmentId,
    appointmentStatus,
    receptionistNotes,
  } = useWizardContext();
  const { handleProgressStep, getStepConfig } = useWizardSteps();

  if (appointmentStatus !== status.ASSIGNED) return null;

  const handleButtonClick = async () => {
    if (!currentAppointmentId) return;

    if (activeStep === WIZARD_STEPS.RECEPTION_NOTES) {
      try {
        let appointmentNumber = receptionistNotes.number
          ? parseInt(receptionistNotes.number, 10)
          : null;

        let appointmentType = receptionistNotes.unit || "earliestAvailableDate";

        if (appointmentNumber === 0 || isNaN(appointmentNumber)) {
          appointmentNumber = 0;
          appointmentType = "earliestAvailableDate";
        }

        await api.createAppointmentNote({
          appointment_id: currentAppointmentId,
          note_content: receptionistNotes.quickInfo || "",
          note_type: "receptionist",
          doctor_required: receptionistNotes.doctorRequired || false,
          service_id: receptionistNotes.service || null,
          reservation_count: appointmentNumber,
          reservation_unit: appointmentType,
        });

        handleProgressStep();
      } catch (error) {
        console.error("Error saving receptionist notes:", error);
      }
    } else if (activeStep === WIZARD_STEPS.PROCEDURE_NOTES) {
      const success = await handleFormSubmit();
      if (success) {
        await api.patchAppointment({
          id: currentAppointmentId,
          status_id: status.TREATED,
        });
        handleProgressStep();
      }
    } else {
      handleProgressStep();
    }
  };

  const steps = [
    { label: t("wizard.steps.preparation"), icon: faCalendarCheck },
    { label: t("wizard.steps.completion"), icon: faCheckCircle },
    { label: t("wizard.steps.receptionNotes"), icon: faBellConcierge },
    { label: t("wizard.steps.procedureNotes"), icon: faCalendarLinesPen },
    { label: t("wizard.steps.chairCleaning"), icon: faBroom },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-around",
        position: "fixed",
        bottom: "20px",
        width: "100%",
        maxWidth: "60%",
        padding: "15px 15px",
        backgroundColor: "#f8f8f8",
        borderRadius: "10px",
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: 1000,
      }}
    >
      {steps.map((step, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: activeStep === index ? "primary.main" : "text.secondary",
            transition: "color 0.3s",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 36,
              height: 36,
              borderRadius: "50%",
              backgroundColor:
                activeStep === index ? "primary.light" : "transparent",
              color: activeStep === index ? "primary.main" : "inherit",
              transition: "background-color 0.3s, color 0.3s",
            }}
          >
            <FontAwesomeIcon icon={step.icon} size="xl" />
          </Box>
          <Typography
            variant="caption"
            sx={{
              fontWeight: activeStep === index ? "bold" : "normal",
              marginTop: "4px",
              fontSize: "0.75rem",
            }}
          >
            {step.label}
          </Typography>
        </Box>
      ))}

      <Button
        variant="contained"
        color="primary"
        onClick={handleButtonClick}
        sx={{ marginLeft: "20px" }}
        disabled={
          !getStepConfig(activeStep).canProgress &&
          activeStep !== WIZARD_STEPS.RECEPTION_NOTES
        }
      >
        {activeStep === WIZARD_STEPS.RECEPTION_NOTES
          ? t("appointmentNotes.sendToReceptionist")
          : getStepConfig(activeStep).nextLabel}
      </Button>
    </Box>
  );
};

export default AppointmentWizard;
