import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsRotate,
  faCalendarDays,
  faCalendarLinesPen,
  faRotateRight,
  faXmark,
} from "@fortawesome/pro-solid-svg-icons";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

import * as d from "shared/src/date.mjs";

import * as f from "../../utils/formatter";
import * as api from "../../services/api";
import ButtonLoader from "../ButtonLoader";
import { FormErrorList, useFormAction } from "../../form";
import { useCurrentLocation } from "../LocationSelector";

import AutocompleteSelect from "../AutocompleteSelect";
import { ClientAutocomplete } from "./ClientAutocomplete";
import { useServiceOptions } from "./hooks/useServiceOption";
import {
  loadAppointments,
  getRandomElements,
  generateAppointmentDates,
} from "./utils";
import { useClientOptions } from "./hooks/useClientOptions";
import { useLocationOptions } from "./hooks/useLocationOptions";
import { useSuggestions } from "./hooks/useSuggestions";
import { useProductOptions } from "./hooks/useProductOptions";
import { status } from "shared/src/appointment.mjs";

/**
 * @typedef NewReservationDialogDefaults
 *
 * @typedef NewReservationDialogState
 * @property {object | null} option
 * @property {object | null} serviceOption
 * @property {object | null} locationOption
 * @property {{from: string, to: string}[]} days
 * @property {object[]} suggestions
 * @property {boolean} suggestionsLoading
 * @property {object | null} suggestion
 * @property {object | null} appointment
 *
 * @returns {NewReservationDialogState}
 */
const initialNewReservationDialogState = (defaults) => {
  return {
    option: defaults.option ?? null,
    productOption: {
      id: "59b7da94-8ce7-4bd1-bdce-f981a72c421e",
      name: "Rovnátka",
    }, //TODO temporary solution for demo
    serviceOption: defaults.serviceOption ?? null,
    locationOption: defaults.locationOption ?? null,
    days: null,
    suggestions: [],
    suggestionsLoading: false,
    suggestion: null,
    appointment: defaults.appointment ?? null,
    note: null,
    personel: null,
  };
};

//TODO Refactor this mess!!!!

/**
 * @param {object} props
 * @param {() => void} props.close
 * @param {() => void} props.setManualDateSelectionDialogOptions
 * @param {NewReservationDialogDefaults} [props.defaults]
 */
const NewReservationDialog = ({
  close,
  setManualDateSelectionDialogOptions,
  defaults = {},
}) => {
  const { t } = useTranslation();
  const [state, setState] = useState(() =>
    initialNewReservationDialogState(defaults),
  );
  const location_id = useCurrentLocation();

  const [randomSuggestions, setRandomSuggestions] = React.useState([[]]);
  const [employees, setEmployees] = React.useState([]);
  const {
    option,
    serviceOption,
    locationOption,
    productOption,
    suggestions,
    suggestion,
    appointment,
    note,
    personel,
  } = state;

  useEffect(() => {
    setRandomSuggestions([getRandomElements(suggestions, 5)]);
  }, [suggestions]);

  const [options, clientSearch, clientOptionsLoading] = useClientOptions(
    null,
    option,
  );
  useEffect(() => {
    const getData = async () => {
      const appointments = await loadAppointments(option?.id);
      const notes = await fetchAppointmentNotes(appointments[0].id);
      const receptionNotes = notes.filter(
        (note) => note.note_type === "receptionist",
      );
      const lastReceptionNote = receptionNotes[receptionNotes.length - 1];
      if (lastReceptionNote?.service_id) {
        const service = await fetchServiceData(lastReceptionNote.service_id);
        const serviceDuration = service.locationServices.find(
          (service) => service.location.id === location_id,
        ).duration;
        service.duration = serviceDuration;
        setState((state) => ({
          ...state,
          serviceOption: service,
        }));
      }
      const days = generateAppointmentDates(
        lastReceptionNote?.reservation_count,
        lastReceptionNote?.reservation_unit,
      );
      const nurses = await fetchNurses({ location_id: { eq: location_id } });
      setEmployees(
        nurses.map((nurse) => ({
          ...nurse,
          name: nurse.first_name + " " + nurse.last_name, //TODO temporary solution for demo
        })),
      );
      setState((state) => ({
        ...state,
        days: days,
        note: lastReceptionNote,
      }));
    };

    async function fetchNurses(filter) {
      const res = await api.availableNurses({
        filter,
        order: { asc: "last_name" },
      });
      return res.data.employees.data;
    }

    async function fetchAppointmentNotes(id) {
      const res = await api.fetchAppointmentNotes(id);
      return res.data.appointmentNotes;
    }

    async function fetchServiceData(serviceId) {
      const res = await api.fetchServiceDetail({
        id: serviceId,
        location_id: location_id,
      });
      return res.data.service;
    }
    if (option?.id) {
      getData();
    }
  }, [option]);

  const locationOptions = useLocationOptions();
  const productFilter = useMemo(() => {
    return locationOption == null
      ? null
      : { company_id: { eq: locationOption.company_id } };
  }, [locationOption]);
  const productOptions = useProductOptions(productFilter);

  const serviceFilter = React.useMemo(() => {
    return locationOption == null || productOption == null
      ? null
      : {
          location_id: { eq: locationOption.id },
          product_id: { eq: productOption.id },
        };
  }, [locationOption, productOption]);
  const so = useServiceOptions(serviceFilter);

  const { errors, loading, onSubmit } = useFormAction(
    () => {
      if (appointment) {
        return api.patchAppointment({
          id: appointment.id,
          status_id: status.FINISHED,
        });
      } else {
        return api.createAppointment({
          calendar_id: suggestion.calendar_id,
          client_id: option.id,
          service_id: serviceOption.id,
          start: d.toString(new Date(suggestion.start)),
          end: d.toString(new Date(suggestion.end)),
        });
      }
    },
    () => close(),
  );

  useSuggestions(setState, state);
  const submitDisabled = loading || suggestion == null;

  return (
    <Dialog open={true} onClose={() => close()} maxWidth="lg">
      <DialogTitle>
        {t("calendar.newReservation")}
        <IconButton aria-label="close" onClick={() => close()}>
          <FontAwesomeIcon icon={faXmark} fixedWidth />
        </IconButton>
      </DialogTitle>
      <form onSubmit={onSubmit}>
        <DialogContent>
          <Box display="flex" flexDirection="row" gap="4rem">
            <Box display="flex" flexDirection="column" gap="1rem">
              <ClientAutocomplete
                search={clientSearch}
                loading={clientOptionsLoading || locationOptions.loading}
                options={options}
                option={option}
                disabled={defaults.option != null}
                onChange={(option) =>
                  setState((state) => ({
                    ...state,
                    option: option,
                    locationOption:
                      option == null
                        ? null
                        : (locationOptions.options.find(
                            (o) => o.id === option.location_id,
                          ) ?? null),
                  }))
                }
              />
              <AutocompleteSelect
                required
                loading={locationOptions.loading}
                options={locationOptions.options}
                label={t("calendars.location")}
                value={locationOption}
                readOnly={true}
              />
              <AutocompleteSelect
                required
                loading={productOptions.loading}
                options={productOptions.options}
                label={t("appointment.product")}
                value={productOption}
                onChange={(value) => {
                  setState((state) => ({
                    ...state,
                    productOption: value == null ? null : value,
                  }));
                }}
              />
              <AutocompleteSelect
                required
                name="service_id"
                loading={so.loading}
                options={so.options}
                label={t("appointment.service")}
                value={serviceOption}
                onChange={(value) => {
                  setState((state) => ({
                    ...state,
                    serviceOption: value == null ? null : value,
                  }));
                }}
              />
              <AutocompleteSelect
                required
                name="personel_id"
                options={employees}
                label="Lékař"
                value={personel}
                onChange={(value) => {
                  setState((state) => ({
                    ...state,
                    personel: value == null ? null : value,
                  }));
                }}
              />

              <FormErrorList formErrors={errors} />
            </Box>
            {note && (
              <Box display="flex" flexDirection="column" gap="1rem">
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    borderBottom: "1px solid",
                    paddingBottom: "1rem",
                  }}
                >
                  {t("calendar.orderClientOn")}:
                </Typography>
                <>
                  <Box display="flex" flexDirection="column" gap="1rem">
                    <p>Úkon: {serviceOption?.name}</p>
                    <p>
                      Kontrola: {note.reservation_count} {note.reservation_unit}
                    </p>
                    <p>Délka app.: {serviceOption?.duration} minut</p>
                    {/* TODO temporary "solution" fro demo */}
                    <p>Klinika: Svět rovnátek s.r.o.</p>
                    <p>Poznámka pro recepci: {note.note_content}</p>
                    <p>Poslední app.:</p>
                  </Box>
                </>
              </Box>
            )}
            {option && (
              <Box display="flex" flexDirection="column" gap="1rem">
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    borderBottom: "1px solid",
                    paddingBottom: "1rem",
                  }}
                >
                  {t("calendar.availableDates")}:
                </Typography>
                <>
                  <FormControl required disabled={state.suggestionsLoading}>
                    <FormLabel>{t("appointment.suggestedTimes")}</FormLabel>
                    <RadioGroup
                      onChange={(_, value) => {
                        setState((state) => ({
                          ...state,
                          suggestion: randomSuggestions
                            .at(-1)
                            .find((s) => s.id === value),
                        }));
                      }}
                    >
                      {randomSuggestions?.at(-1)?.map((s) => {
                        const label = <>{f.longDateTime(s.start)}</>;

                        return (
                          <FormControlLabel
                            key={s.id}
                            value={s.id}
                            control={<Radio />}
                            label={label}
                          />
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                  <Box
                    sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}
                  >
                    <Button
                      sx={{ padding: "0.7rem 2.4rem", gap: "0.6rem" }}
                      disabled={randomSuggestions.length === 1}
                      onClick={() =>
                        setRandomSuggestions(
                          randomSuggestions.length > 1
                            ? randomSuggestions.slice(0, -1)
                            : [[]],
                        )
                      }
                      variant="outlined"
                    >
                      <FontAwesomeIcon icon={faRotateRight} />
                      {t("form.back")}
                    </Button>
                    <Button
                      sx={{ padding: "0.7rem 2.4rem", gap: "0.6rem" }}
                      onClick={() =>
                        setRandomSuggestions([
                          ...randomSuggestions,
                          getRandomElements(suggestions, 5),
                        ])
                      }
                      variant="outlined"
                    >
                      <FontAwesomeIcon icon={faArrowsRotate} />
                      {t("form.refresh")}
                    </Button>
                  </Box>
                </>
                <Typography
                  sx={{
                    display: "flex",
                    gap: "0.6rem",
                  }}
                  variant="h3"
                  onClick={() => {
                    setManualDateSelectionDialogOptions({});
                  }}
                >
                  {t("calendar.manualTimeSelection")}
                  <FontAwesomeIcon icon={faCalendarLinesPen} />
                </Typography>
                {!state.suggestionsLoading && suggestions.length === 0 && (
                  <Typography>{t("fcalendar.suggestionsEmpty")}</Typography>
                )}
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => close()} variant="outlined">
            {t("form.cancel")}
          </Button>
          <Button
            type="submit"
            disabled={submitDisabled}
            sx={{ padding: "0.7rem 2.4rem", gap: "0.6rem" }}
            variant="contained"
          >
            {loading && <ButtonLoader />}
            <FontAwesomeIcon icon={faCalendarDays} />
            {t("form.reserve")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export { NewReservationDialog };
