import "@event-calendar/core/index.css";
import React, { useEffect, useState } from "react";
import { useLoaderData, useRevalidator } from "react-router";
import { useTranslation } from "react-i18next";
import { Box, Button, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import * as d from "shared/src/date.mjs";
import * as api from "../services/api";
import * as ws from "../utils/ws";
import { currentLocationFromRequest } from "../components/LocationSelector";
import { BookedClientList } from "../components/reception/BookedClientList.jsx";
import { NewReservationDialog } from "../components/reservationDialog/NewReservationDialog";
import {
  NewAdhocDialog,
  adhocTypesEnum,
} from "../components/reservationDialog/NewAdhocDialog";
import { ManualDateSelectionDialog } from "../components/reservationDialog/ManualDateSelectionDialog";
import { CheckedInClientList } from "../components/reception/CheckedInClientList.jsx";
import { TreatedClientList } from "../components/reception/TreatedClientList";
import { Snackbar } from "../components/Snackbar.jsx";

export async function loader({ request }) {
  const location_id = currentLocationFromRequest(request);

  const now = new Date();
  const from = d.toString(d.startOfDay(now));
  const to = d.toString(d.endOfDay(now));

  return api.loadAppointments({
    filter: {
      start: { gte: from, lt: to },
      location_id: { eq: location_id },
    },
    order: {
      asc: "start",
    },
  });
}

export default function ReceptionDashboard() {
  const [newReservationDialogOptions, setNewReservationDialogOptions] =
    useState(null);
  const [newAdhocDialogOptions, setNewAdhocDialogOptions] = useState(null);
  const [
    manualDateSelectionDialogOptions,
    setManualDateSelectionDialogOptions,
  ] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState();
  const [snackbarTitle, setSnackbarTitle] = useState();
  const loaderData = useLoaderData();
  const appointments = loaderData.data.appointments.data;
  const revalidator = useRevalidator();
  const { t } = useTranslation();

  const [renderedAppointments, setRenderedAppointments] =
    useState(appointments);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (!isHovered) {
      setRenderedAppointments(appointments);
    }
  }, [appointments, isHovered]);

  useEffect(() => {
    async function refresh(msg) {
      if (msg.type === "appointment_changed") {
        revalidator.revalidate();
      }
    }

    ws.subscribe(refresh);

    return () => {
      ws.unsubscribe(refresh);
    };
  }, []);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h1">{t("reception.dashboard")}</Typography>
        <Box
          sx={{
            display: "flex",
            gap: "4rem",
          }}
        >
          <Button
            sx={{ padding: "0.7rem 2.4rem", gap: "0.6rem" }}
            variant="outlined"
            onClick={() =>
              setNewReservationDialogOptions({
                close: () => setNewReservationDialogOptions(null),
              })
            }
          >
            <FontAwesomeIcon icon={faCalendarDays} />
            {t("reception.reservation")}
          </Button>
          <Button
            sx={{ padding: "0.7rem 2.4rem", gap: "0.6rem" }}
            variant="outlined"
            onClick={() => {}}
          >
            <FontAwesomeIcon icon={faCalendarDays} />
            {t("reception.handover")}
          </Button>
          <Button
            sx={{ padding: "0.7rem 2.4rem", gap: "0.6rem" }}
            variant="outlined"
            onClick={() =>
              setNewAdhocDialogOptions({
                close: () => setNewAdhocDialogOptions(null),
                defaults: {
                  types: [
                    adhocTypesEnum.STREET,
                    adhocTypesEnum.INITIALEXAMINATION,
                  ],
                },
              })
            }
          >
            <FontAwesomeIcon icon={faCalendarDays} />
            {t("reception.adhoc")}
          </Button>
        </Box>
      </Box>

      {manualDateSelectionDialogOptions && (
        <ManualDateSelectionDialog
          {...manualDateSelectionDialogOptions}
          close={() => setManualDateSelectionDialogOptions(null)}
        />
      )}

      {newReservationDialogOptions && (
        <NewReservationDialog
          {...newReservationDialogOptions}
          setManualDateSelectionDialogOptions={
            setManualDateSelectionDialogOptions
          }
        />
      )}

      {newAdhocDialogOptions && <NewAdhocDialog {...newAdhocDialogOptions} />}

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 20%",
          gridTemplateRows: "2",
          gridTemplateAreas: `"booked treated" "checkedIn treated"`,
          width: "100%",
          gap: "36px",
        }}
      >
        <Box sx={{ gridArea: "booked" }}>
          <BookedClientList
            appointments={renderedAppointments}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarTitle={setSnackbarTitle}
            setSnackbarOpen={setSnackbarOpen}
            setIsHovered={setIsHovered}
            setNewAdhocDialogOptions={setNewAdhocDialogOptions}
          />
        </Box>
        <Box sx={{ gridArea: "checkedIn" }}>
          <CheckedInClientList
            appointments={renderedAppointments}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarTitle={setSnackbarTitle}
            setSnackbarOpen={setSnackbarOpen}
            setIsHovered={setIsHovered}
          />
        </Box>
        <Box sx={{ gridArea: "treated" }}>
          <TreatedClientList
            appointments={renderedAppointments}
            setNewReservationDialogOptions={setNewReservationDialogOptions}
            setIsHovered={setIsHovered}
          />
        </Box>
      </Box>

      <Snackbar
        open={snackbarOpen}
        handleClose={handleSnackbarClose}
        title={snackbarTitle}
        severity="success"
      >
        {snackbarMessage}
      </Snackbar>
    </>
  );
}
