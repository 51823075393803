import React from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete, Box, TextField } from "@mui/material";
import * as f from "../../utils/formatter";

const ClientAutocomplete = ({
  search,
  options,
  option,
  onChange,
  loading,
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <input type="hidden" name="client_id" value={option?.id ?? ""} />
      <Autocomplete
        sx={{ minWidth: "400px" }}
        filterOptions={(x) => x}
        autoComplete
        disabled={disabled}
        loading={loading}
        options={options}
        value={option}
        getOptionLabel={(opt) => f.fullName(opt)}
        getOptionKey={(opt) => opt.id}
        isOptionEqualToValue={(opt, val) => opt.id === val.id}
        renderInput={(params) => (
          <TextField label={t("appointment.client")} required {...params} />
        )}
        renderOption={(props, option) => {
          const { key, ...otherProps } = props;

          return (
            <li key={key} {...otherProps}>
              <Box>
                <strong>{f.fullName(option)}</strong>
                <br />
                {t("client.birth_number")}: {option.birth_number}{" "}
                {t("client.phone")}: {option.phone}
                <br />
                {option.street} {option.city}
                <br />
                {option.post_code}
              </Box>
            </li>
          );
        }}
        onInputChange={(_, term) => {
          search(term);
        }}
        onChange={(_, value) => {
          onChange(value == null ? null : value);
        }}
      />
    </>
  );
};

export { ClientAutocomplete };
