import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import * as api from "../../services/api";
import NotesForReceptionForm from "./NotesForReceptionForm.jsx";
import { useWizardContext } from "./AppointmentWizardContext";
import AppointmentNoteFields from "./AppointmentNotesFields";
import { WIZARD_STEPS } from "./hooks/useWizardSteps";

const AppointmentNotesTabContent = () => {
  const { t } = useTranslation();
  const [services, setServices] = useState([]);
  const [page] = useState({ limit: 60, offset: 0 });

  const { clientData, setSelectedServiceId, activeStep } = useWizardContext();

  const fetchServices = async () => {
    try {
      const result = await api.loadServicesWithTemplates({
        page,
        filter: { visible: { eq: true } },
      });
      setServices(result.data.services.data);
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  useEffect(() => {
    fetchServices();
    setSelectedServiceId(clientData.nextAppointment.service.id);
  }, [page]);

  const isReceptionStep = activeStep === WIZARD_STEPS.RECEPTION_NOTES;
  const isProcedureNotesStep = activeStep === WIZARD_STEPS.PROCEDURE_NOTES;

  return (
    <Box sx={{ maxWidth: 1100 }}>
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h4" sx={{ marginBottom: 3 }}>
          {t("appointmentNotes.title")}
        </Typography>

        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {isReceptionStep && (
            <NotesForReceptionForm
              services={services}
              appointmentId={clientData.nextAppointment.id}
            />
          )}

          {isProcedureNotesStep && <AppointmentNoteFields />}
        </Box>
      </Box>
    </Box>
  );
};

export default AppointmentNotesTabContent;
