import "./receptionTypes.js";
import React, { memo } from "react";
import { Box, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngry,
  faCalendarPlus,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";
import * as api from "../../services/api";
import * as f from "../../utils/formatter";
import Link from "../Link";
import { useCurrentLocation } from "../LocationSelector";
import { createClickHandler } from "../calendar";
import { createUrlString } from "../../url";
/**
 * A component to display a client that has been treated
 *
 * @typedef {object} BookedClientProps
 * @property {Appointment} appointment
 * @property {function} setNewReservationDialogOptions Set the options for the reservation dialog
 *
 * @param {BookedClientProps} props
 * @returns {JSX.Element}
 */

const TreatedClient = memo(
  ({ appointment, setNewReservationDialogOptions }) => {
    const location_id = useCurrentLocation();

    const clickHandler = createClickHandler({
      onClick: () => {},
      onDoubleClick: () => {
        const clientId = appointment.client?.id;
        if (clientId == null) {
          return;
        }

        window.open(
          createUrlString(
            location.origin,
            location.search,
            `/clients/${clientId}`,
          ),
          "_blank",
        );
      },
    });

    return (
      <Box
        sx={{
          borderBottom: "1px solid lightblue",
          padding: "12px",
          display: "flex",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
        onClick={clickHandler}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            flex: "0 0 60%",
          }}
        >
          <Link
            to={"/clients/" + appointment.client.id}
            style={{
              color: "inherit",
              textDecoration: "none",
              fontWeight: "bold",
              fontSize: "14px",
              display: "flex",
              gap: "0.5rem",
              alignItems: "center",
            }}
          >
            <span
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {f.fullName(appointment.client)}
            </span>
          </Link>
          <FontAwesomeIcon icon={faGlobe} color="green" />
          <FontAwesomeIcon icon={faAngry} color="red" />
        </Box>

        <IconButton
          onClick={() => {
            async function setReservationOptions() {
              async function fetchLocationOptions() {
                const res = await api.locationOptions();
                return res.data.locations.data;
              }
              async function fetchAppointmentNotes() {
                const res = await api.fetchAppointmentNotes(appointment.id);
                return res.data.appointmentNotes;
              }
              async function fetchServiceData(serviceId) {
                const res = await api.fetchServiceDetail({
                  id: serviceId,
                  location_id: location_id,
                });
                return res.data?.service;
              }

              const [location] = await fetchLocationOptions();
              const notes = await fetchAppointmentNotes();
              const receptionNotes = notes.filter(
                (note) => note.note_type === "receptionist",
              );
              const lastReceptionNote =
                receptionNotes[receptionNotes.length - 1];
              const service = await fetchServiceData(
                lastReceptionNote?.service_id,
              );
              const serviceDuration = service?.locationServices.find(
                (service) => service.location.id === location_id,
              ).duration;

              setNewReservationDialogOptions({
                close: () => setNewReservationDialogOptions(null),
                defaults: {
                  option: appointment.client,
                  locationOption: location,
                  serviceOption: {
                    ...service,
                    duration: serviceDuration,
                  },
                  appointment,
                },
              });
            }
            setReservationOptions();
          }}
          sx={{
            borderRadius: 1,
            color: "white",
            backgroundColor: "primary.main",
            width: "2.6rem",
            height: "2.6rem",
            fontSize: "1.6rem",
            "&:hover": {
              color: "primary.main",
            },
          }}
        >
          <FontAwesomeIcon icon={faCalendarPlus} />{" "}
        </IconButton>
      </Box>
    );
  },
);

TreatedClient.displayName = "TreatedClient";
export { TreatedClient };
