import Holidays from "date-holidays";
import { isOverlap } from "./util.mjs";

const hd = new Holidays("CZ");

const days = {
  0: "su",
  1: "mo",
  2: "tu",
  3: "we",
  4: "th",
  5: "fr",
  6: "sa",
};

export const workingDays = ["mo", "tu", "we", "th", "fr"];

/**
 * @param {Date} date
 *
 * @returns {string}
 */
export function dateToDay(date) {
  return days[date.getDay()];
}

/**
 * @param {Date | string} day
 * @param {array | null} whs
 */
export function workingHoursInDayExcludingHolidays(day, whs) {
  if (whs == null) {
    return null;
  }

  const valid = whs.filter(
    (whs) => new Date(whs.valid_from) <= day && day <= new Date(whs.valid_to),
  );

  if (valid.length > 2) {
    console.error(
      `There is ${valid.length} valid working hours. Should be 1 or 0.`,
    );
  }

  return valid[0];
}

function isInterestingHoliday(h) {
  return h.type === "public";
}

/**
 * @param {Date | string} day
 *
 * @returns {boolean}
 */
export function isHoliday(day) {
  const holidays = hd.isHoliday(day);

  if (holidays === false) {
    return false;
  }

  return holidays.some((h) => isInterestingHoliday(h));
}

/**
 * @param {Date | string} day
 * @param {array | null} whs
 */
export function workingHoursInDay(day, whs) {
  if (isHoliday(day)) {
    return null;
  }

  return workingHoursInDayExcludingHolidays(day, whs);
}

/**
 * @param {number[]} years
 *
 * @returns {string[]} Dates
 */
export function getHolidays(years) {
  return years
    .flatMap((y) => hd.getHolidays(y))
    .filter(isInterestingHoliday)
    .map((h) => h.date);
}

/**
 * @param {Date} from
 * @param {Date} to
 *
 * @returns {Date[]}
 */
export function holidays(from, to) {
  const res = [];

  let next = new Date(from.getTime());
  while (next < to) {
    if (isHoliday(next)) {
      res.push(next);
    }

    next = new Date(next.getTime());
    next.setDate(next.getDate() + 1);
  }

  return res;
}

function mergeWith(f, m1, m2) {
  const res = { ...m1 };

  Object.entries(m2).reduce((acc, [k, v]) => {
    if (acc[k] == null) {
      acc[k] = v;
    } else {
      acc[k] = f(acc[k], v);
    }

    return res;
  }, res);

  return res;
}

function dateTimeToString(date) {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .slice(0, -5);
}

function parseWorkingHour(wh, isNew = false) {
  return {
    valid_from: new Date(wh.valid_from),
    valid_to: new Date(wh.valid_to),
    new: isNew,
    __data: wh,
  };
}

function convertWorkingHour(wh) {
  return {
    ...wh.__data,
    valid_from: dateTimeToString(wh.valid_from),
    valid_to: dateTimeToString(wh.valid_to),
  };
}

function workingHourComparator(wh1, wh2) {
  const d1 = wh1.valid_from;
  const d2 = wh2.valid_from;

  return d1.getTime() - d2.getTime();
}

function removeOverlaps(sortedWh) {
  return sortedWh.reduce((acc, item) => {
    const prev = acc.at(-1);
    if (prev == null) {
      acc.push(item);
      return acc;
    }

    const overlaps = isOverlap(
      prev.valid_from,
      prev.valid_to,
      item.valid_from,
      item.valid_to,
    );
    if (!overlaps) {
      acc.push(item);
      return acc;
    }

    if (item.new) {
      const originalPrev = { ...prev };
      prev.valid_to = item.valid_from;
      if (prev.valid_from.getTime() === item.valid_from.getTime()) {
        acc.pop();
      }

      acc.push(item);
      if (item.valid_to < originalPrev.valid_to) {
        acc.push({
          ...originalPrev,
          valid_from: item.valid_to,
        });
      }
    } else {
      const next = { ...item, valid_from: prev.valid_to };
      acc.push(next);
    }

    return acc;
  }, []);
}

function mergeWorkingHours(whs1, whs2) {
  const whs = [...whs1, ...whs2].toSorted(workingHourComparator);

  return removeOverlaps(whs);
}

export function mergeIntervals(existingIntervals, newIntervals) {
  const i1 = existingIntervals.map((interval) => parseWorkingHour(interval));
  const i2 = newIntervals.map((interval) => parseWorkingHour(interval, true));

  return mergeWorkingHours(i1, i2).map((interval) =>
    convertWorkingHour(interval),
  );
}

export function patchWorkingHours(existingWhs, newWhs) {
  return mergeWith(mergeIntervals, existingWhs, newWhs);
}
