import React from "react";
import { withJsonFormsControlProps } from "@jsonforms/react";
import {
  and,
  hasOption,
  isMultiLineControl,
  rankWith,
  schemaTypeIs,
  uiTypeIs,
} from "@jsonforms/core";
import { ControlWithTouched } from "./materialRenderers.jsx";
import {
  MaterialInputControl,
  MuiInputText,
} from "@jsonforms/material-renderers";
import { Box } from "@mui/material";

/**
 * @typedef {Object} Props
 * @property {Object} schema - The JSON Schema for the control
 * @property {Object} uischema - The UI Schema for customizing the control
 * @property {*} data - The current value of the data bound to the control
 * @property {Function} handleChange - Callback to update the form data
 * @property {string} path - The data path for the control
 */

/**
 * TextAreaRenderer
 * A custom renderer for displaying and editing multi-line text.
 *
 * @param {Props} props - The properties passed to the renderer.
 * @returns {React.ReactElement} The rendered text area field.
 */
const TextAreaRenderer = (props) => {
  const height = props.uischema.options.rows
    ? props.uischema.options.rows * 2
    : 8;
  return (
    <Box
      sx={{
        "& .textAreaTester textarea": { height: `${height}rem !important` },
      }}
    >
      <MaterialInputControl
        {...props}
        uischema={{
          ...props.uischema,
          options: { ...props.uischema.options, multi: true },
        }}
        input={MuiInputText}
        className="textAreaTester"
      />
    </Box>
  );
};

export const textAreaTester = rankWith(
  5,
  and(
    uiTypeIs("Control"),
    schemaTypeIs("string"),
    isMultiLineControl,
    hasOption("rows"),
  ),
);

// Wrap the renderer with JSONForms control props
export default withJsonFormsControlProps((props) => (
  <ControlWithTouched {...{ ...props, wrappedControl: TextAreaRenderer }} />
));
