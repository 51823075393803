import React from "react";
import { Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const NextAppointmentCard = ({ nextAppointment }) => {
  const { t } = useTranslation();

  const formatAppointmentDate = (startDate) => {
    const appointmentDate = new Date(startDate);
    const today = new Date();

    const formattedTime = appointmentDate.toLocaleTimeString("cs-CZ", {
      hour: "2-digit",
      minute: "2-digit",
    });

    if (
      appointmentDate.getDate() === today.getDate() &&
      appointmentDate.getMonth() === today.getMonth() &&
      appointmentDate.getFullYear() === today.getFullYear()
    ) {
      return `${t("nextAppointmentCard.todayAt")} ${formattedTime}`;
    } else {
      return `${appointmentDate.toLocaleDateString("cs-CZ")} ${t(
        "nextAppointmentCard.at",
      )} ${formattedTime}`;
    }
  };

  const cardStyle = {
    padding: 2,
    marginBottom: 2,
    maxWidth: 300,
    minHeight: 200,
  };

  return (
    <Paper elevation={3} sx={cardStyle}>
      <Typography variant="h6">{t("nextAppointmentCard.title")}</Typography>
      {nextAppointment ? (
        <div>
          <Typography variant="body1">
            <strong>{t("nextAppointmentCard.service")}:</strong>{" "}
            {nextAppointment.service.name}
          </Typography>
          <Typography variant="body1">
            <strong>{t("nextAppointmentCard.date")}:</strong>{" "}
            {formatAppointmentDate(nextAppointment.start)}
          </Typography>
        </div>
      ) : (
        <Typography
          variant="body1"
          sx={{
            wordWrap: "break-word",
          }}
        >
          {t("nextAppointmentCard.noUpcomingAppointments")}
        </Typography>
      )}
    </Paper>
  );
};

export default NextAppointmentCard;
