import React from "react";
import { TablePagination } from "../components/table";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  TextField,
} from "@mui/material";
import { useLoaderData, useRevalidator, useSearchParams } from "react-router";
import { useTranslation } from "react-i18next";
import * as qs from "../qs";
import * as api from "../services/api";
import * as p from "../parser";
import ButtonLoader from "../components/ButtonLoader";
import { formData, FormErrorList, useFormAction } from "../form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faPen } from "@fortawesome/free-solid-svg-icons";
import Link from "../components/Link";
import AutocompleteSelect from "../components/AutocompleteSelect.jsx";

const defaults = [
  [["page", "limit"], 10],
  [["page", "offset"], 0],
];

const parsers = [
  [["page", "limit"], (val) => p.parseInt(val, 10)],
  [["page", "offset"], (val) => p.parseInt(val, 0)],
];

function graphqlArgs(request) {
  return qs.parse(new URL(request.url).searchParams.toString(), {
    defaults: defaults,
    parsers: parsers,
  });
}

export async function loader({ request }) {
  return { calendars: await api.loadCalendars(graphqlArgs(request)) };
}

function useLocationOptions() {
  const [options, setOptions] = React.useState();
  React.useEffect(() => {
    (async () => {
      const res = await api.locationOptions();
      setOptions(res.data.locations.data);
    })();
  }, []);

  return { options: options ?? [], loading: options == null };
}

export function NewCalendarDialog({ close }) {
  const { t } = useTranslation();
  const revalidator = useRevalidator();
  const locationOptions = useLocationOptions();
  const { loading, errors, onSubmit } = useFormAction(
    (e) => api.createCalendar(formData(e.currentTarget)),
    () => {
      revalidator.revalidate();
      close();
    },
  );

  return (
    <Dialog open={true} onClose={() => close()}>
      <DialogTitle>{t("calendars.newCalendar")}</DialogTitle>
      <form onSubmit={onSubmit}>
        <DialogContent>
          <Box display="flex" flexDirection="column" gap="1rem">
            <TextField name="name" label={t("calendars.name")} required />
            <AutocompleteSelect
              required
              name="location_id"
              loading={locationOptions.loading}
              options={locationOptions.options}
              label={t("calendars.location")}
            />
            <FormErrorList formErrors={errors} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => close()}>{t("form.cancel")}</Button>
          <Button type="submit" disabled={loading}>
            {loading && <ButtonLoader />}
            {t("form.submit")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

function useChangeQuery() {
  const [_, setSearchParams] = useSearchParams();
  const changeQuery = React.useCallback(
    (newValues) => {
      if (typeof newValues === "function") {
        setSearchParams((prev) => newValues(prev));
        return;
      }

      setSearchParams((prev) => ({
        ...Object.fromEntries(prev),
        ...newValues,
      }));
    },
    [setSearchParams],
  );

  return changeQuery;
}

export default function Calendars() {
  const { t } = useTranslation();
  const loaderData = useLoaderData();
  const [state, setState] = React.useState({ createCalendar: false });
  const calendarsConnection = loaderData.calendars.data.calendars;
  const locationOptions = useLocationOptions();
  const changeQuery = useChangeQuery();
  const [searchParams] = useSearchParams();
  const { createCalendar } = state;

  return (
    <>
      <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
        <AutocompleteSelect
          required
          value={
            locationOptions.options.find(
              (opt) => opt.id === searchParams.get("filter[location_id][eq]"),
            ) ?? null
          }
          loading={locationOptions.loading}
          options={locationOptions.options}
          label={t("calendars.location")}
          onChange={(val) => {
            if (val == null) {
              changeQuery((prev) => {
                Object.fromEntries(
                  Object.entries(prev).filter(
                    ([k]) => k !== "filter[location_id][eq]",
                  ),
                );
              });
              return;
            }

            changeQuery({
              "page[offset]": 0,
              "filter[location_id][eq]": val.id,
            });
          }}
        />
        <Button
          onClick={() =>
            setState((state) => ({ ...state, createCalendar: true }))
          }
          sx={{ mt: 2.5 }}
        >
          {t("calendars.newCalendar")}
        </Button>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("calendars.name")}</TableCell>
            <TableCell>{t("calendars.location")}</TableCell>
            <TableCell>{t("calendars.visible")}</TableCell>
            <TableCell>Typ služby</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {calendarsConnection.data.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.location?.name}</TableCell>
              <TableCell>
                <FontAwesomeIcon
                  icon={row.visible ? faEye : faEyeSlash}
                  size="lg"
                />
              </TableCell>
              <TableCell>{row.service_type}</TableCell>
              <TableCell>
                <IconButton
                  component={Link}
                  to={`/calendars/${row.id}/edit`}
                  sx={{ aspectRatio: "1 / 1" }}
                  size="small"
                >
                  <FontAwesomeIcon icon={faPen} fixedWidth />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination connection={calendarsConnection} />
          </TableRow>
        </TableFooter>
      </Table>
      {createCalendar && (
        <NewCalendarDialog
          close={() =>
            setState((state) => ({ ...state, createCalendar: false }))
          }
        />
      )}
    </>
  );
}
