import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from "@mui/lab";
import {
  Typography,
  Box,
  CircularProgress,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Container,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  fetchFilledServiceTemplatesByClientId,
  fetchMedicalReportsByClientId,
} from "../../../services/api";
import parse from "html-react-parser";
import debounce from "lodash.debounce";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const ClientActivityLogTimeline = ({ clientId }) => {
  const { t, i18n } = useTranslation(["translation", "serviceTemplates"]);
  const [timelineEntries, setTimelineEntries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState("");

  const translateKeyValue = useMemo(
    () => (key, value) => {
      const translationKey = `serviceTemplates:${key}.label`;
      const translatedKey = i18n.exists(translationKey)
        ? t(translationKey)
        : key.replace(/_/g, " ");

      const valueKey = `serviceTemplates:${key}.${value}`;
      const translatedValue = i18n.exists(valueKey) ? t(valueKey) : value;

      return { translatedKey, translatedValue };
    },
    [i18n.language, t],
  );

  const fetchTimeline = useCallback(
    debounce(async () => {
      if (!clientId) {
        setError(t("client.noActivityLogs"));
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const [filledTemplates, medicalReports] = await Promise.all([
          fetchFilledServiceTemplatesByClientId(clientId),
          fetchMedicalReportsByClientId(clientId),
        ]);

        const formattedEntries = [
          ...filledTemplates.map((entry) => ({
            type: "APPOINTMENT",
            id: entry.id,
            appointment_id: entry.appointment_id,
            service_name: entry.service_name || t("client.unknownService"),
            appointment_date: entry.appointment_date,
            filled_data: entry.filled_data,
            employee_name: entry.employee_name || t("client.unknownEmployee"),
          })),
          ...medicalReports.map((report) => ({
            type: "MEDICAL_REPORT",
            id: report.report_id,
            report_type: report.report_type,
            report_text: report.report_text,
            report_created_at: report.report_created_at,
            employee_name: report.employee_name || t("client.unknownEmployee"),
          })),
        ];

        formattedEntries.sort((a, b) => {
          const dateA = new Date(a.appointment_date || a.report_created_at);
          const dateB = new Date(b.appointment_date || b.report_created_at);
          return dateB - dateA;
        });

        setTimelineEntries(formattedEntries);
        setError(null);
      } catch (err) {
        console.error("Error fetching timeline:", err);
        setError(t("client.noActivityLogs"));
        setTimelineEntries([]);
      } finally {
        setLoading(false);
      }
    }, 300),
    [clientId, t],
  );

  useEffect(() => {
    fetchTimeline();
    return () => fetchTimeline.cancel();
  }, [fetchTimeline]);

  const formatDate = (dateString) => {
    if (!dateString) return t("client.unknown");
    const date = new Date(dateString);
    return date.toLocaleDateString(i18n.language, {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  if (loading) return <CircularProgress />;
  if (error || !timelineEntries.length)
    return <Typography variant="h6">{t("client.noActivityLogs")}</Typography>;

  return (
    <Container maxWidth="xl" sx={{ position: "relative", padding: 0 }}>
      <Box
        sx={{
          display: "flex",
          position: "absolute",
          top: 0,
          right: 0,
          zIndex: 1,
          alignItems: "bottom",
          gap: 1,
        }}
      >
        <FormControl sx={{ width: 220 }}>
          <InputLabel>{t("client.activityFilter")}</InputLabel>
          <Select
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            displayEmpty
          >
            <MenuItem value="">{t("client.allActivities")}</MenuItem>
            <MenuItem value="appointment">
              {t("client.appointmentEntry")}
            </MenuItem>
            <MenuItem value="sent_messages">
              {t("client.sentMessages")}
            </MenuItem>
            <MenuItem value="cp_instructions">
              {t("client.cpInstructions")}
            </MenuItem>
            <MenuItem value="satisfaction_survey">
              {t("client.satisfactionSurvey")}
            </MenuItem>
            <MenuItem value="new_plan">{t("client.newPlan")}</MenuItem>
          </Select>
        </FormControl>

        <Button
          variant="contained"
          color="primary"
          onClick={() => fetchTimeline()}
          sx={{ mt: 2.4 }}
        >
          {t("client.applyFilter")}
        </Button>
      </Box>

      <Box sx={{ maxWidth: 1200, padding: 3, pt: 6 }}>
        <Timeline>
          {timelineEntries.map((entry) => (
            <TimelineItem key={entry.id}>
              <TimelineOppositeContent
                sx={{
                  textAlign: "left",
                  flex: 0.3,
                  paddingRight: 4,
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: "bold",
                    color: "primary.main",
                    fontSize: "1.6rem",
                  }}
                >
                  {entry.type === "APPOINTMENT"
                    ? entry.service_name
                    : t("client.medicalReport")}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "1.3rem",
                    fontWeight: "bold",
                    color: "secondary.main",
                    marginTop: "4px",
                  }}
                >
                  {entry.type === "APPOINTMENT"
                    ? t("client.appointment")
                    : entry.report_type === "NOTE"
                      ? t("client.medicalNote")
                      : t("client.medicalReportLegacy")}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "1.3rem",
                    fontWeight: "500",
                    color: "text.secondary",
                    marginTop: "6px",
                  }}
                >
                  {formatDate(
                    entry.appointment_date || entry.report_created_at,
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "1.3rem",
                    fontWeight: "500",
                    color: "text.secondary",
                    marginTop: "6px",
                  }}
                >
                  {entry.employee_name}
                </Typography>
              </TimelineOppositeContent>

              <TimelineSeparator sx={{ justifyContent: "center" }}>
                <TimelineDot
                  color="success"
                  sx={{
                    boxShadow: "none",
                    marginBottom: "4px",
                    aspectRatio: "1",
                    alignItems: "center",
                    padding: "2px",
                  }}
                >
                  <FontAwesomeIcon icon={faCheck} fixedWidth />
                </TimelineDot>
                <TimelineConnector
                  sx={{
                    backgroundColor: "grey.300",
                    width: "1px",
                  }}
                />
                <TimelineDot
                  color="grey"
                  sx={{
                    padding: "1px",
                    marginTop: 0,
                    boxShadow: "none",
                    alignSelf: "center",
                  }}
                />
              </TimelineSeparator>

              <TimelineContent
                sx={{ display: "flex", justifyContent: "flex-start", pl: 3 }}
              >
                <Paper
                  elevation={0}
                  sx={{
                    px: 3,
                    py: 1,
                    width: "100%",
                    border: "1px solid",
                    borderColor: "divider",
                  }}
                >
                  {entry.type === "APPOINTMENT" ? (
                    entry.filled_data ? (
                      Object.entries(entry.filled_data).map(([key, value]) => {
                        const { translatedKey, translatedValue } =
                          translateKeyValue(key, value);

                        return (
                          <Typography
                            key={key}
                            variant="body1"
                            sx={{ fontSize: "1.3rem", marginBottom: "8px" }}
                          >
                            <strong>{translatedKey}:</strong> {translatedValue}
                          </Typography>
                        );
                      })
                    ) : (
                      <Typography variant="body1">
                        {t("serviceTemplates:noTemplateAvailable")}
                      </Typography>
                    )
                  ) : (
                    <Typography variant="body1">
                      {parse(entry.report_text)}
                    </Typography>
                  )}
                </Paper>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Box>
    </Container>
  );
};

export default ClientActivityLogTimeline;
