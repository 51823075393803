import { Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileEdit,
  faBroomWide,
  faHourglassStart,
} from "@fortawesome/pro-solid-svg-icons";
import React from "react";
import { t } from "i18next";

const status = {
  MINUTES: "minutes",
  CLEANING: "cleaning",
  PREPARATION: "preparation",
};

const ResourceLabel = ({ resourceStatus }) => {
  let icon;
  switch (resourceStatus) {
    case status.PREPARATION:
      icon = faHourglassStart;
      break;
    case status.CLEANING:
      icon = faBroomWide;
      break;
    case status.MINUTES:
      icon = faFileEdit;
      break;
  }

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        right: "1rem",
        backgroundColor: "elevation.outlined",
        color: "_components.heading.color",
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        marginTop: "3px",
        paddingX: "0.2rem",
        fontSize: "1rem",
        fontWeight: "medium",
      }}
    >
      <FontAwesomeIcon icon={icon} fixedWidth style={{ fontSize: "0.9rem" }} />
      <Box component="span" sx={{ ml: "0.2rem" }}>
        {t(`resourceLabel.${resourceStatus}`)}
      </Box>
    </Box>
  );
};

export default ResourceLabel;
