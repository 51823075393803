import React from "react";
import { Box, Button, Divider, IconButton, TextField } from "@mui/material";
import { Form, useLoaderData, useNavigation } from "react-router";
import * as api from "../services/api";
import { currentLocationFromRequest } from "../components/LocationSelector";
import * as f from "../utils/formatter";
import {
  compose,
  dissocInIf,
  indexBy,
  updateInIfExists,
} from "shared/src/util.mjs";
import { FormErrorList, useRouterFormAction } from "../form";
import ButtonLoader from "../components/ButtonLoader";
import { useTranslation } from "react-i18next";
import AutocompleteSelect from "../components/AutocompleteSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

export async function loader({ request }) {
  const location_id = currentLocationFromRequest(request);

  return api.loadReservationSettingsEditData({
    locationServicesFilter: {
      location_id: { eq: location_id },
      service_type: { eq: "other" },
    },
    locationSettingsFilter: {
      location_id: { eq: location_id },
      key: { eq: "reservation_settings" },
    },
  });
}

export async function action({ request }) {
  const data = {
    location_id: currentLocationFromRequest(request),
    data: { reservation_settings: await request.json() },
  };

  return api.patchLocationSettings(data);
}

function ServiceGroupForm({ serviceById, serviceGroups, setServiceGroups }) {
  const serviceOptions = Object.values(serviceById).map((s) => ({
    id: s.id,
    name: `${s.name} (${f.minutesToDuration(s.duration)})`,
  }));

  return (
    <Box display="flex" flexDirection="column" gap="1rem" marginTop="1rem">
      {Object.entries(serviceGroups).map(([id, group]) => {
        const selectedServices = new Set(group.services);

        return (
          <React.Fragment key={id}>
            <Box display="flex">
              <Box
                display="flex"
                flexDirection="column"
                gap="1rem"
                flexGrow="1"
              >
                <TextField
                  autoFocus
                  required
                  name={`service_groups.${id}.name`}
                  label="Název"
                  defaultValue={group.name}
                  onChange={(e) => {
                    const name = e.currentTarget.value;
                    setServiceGroups((g) => ({
                      ...g,
                      [id]: {
                        ...g[id],
                        name,
                      },
                    }));
                  }}
                />
                <AutocompleteSelect
                  required
                  multiple
                  label="Služby"
                  name={`service_groups.${id}.services`}
                  value={serviceOptions.filter((o) =>
                    selectedServices.has(o.id),
                  )}
                  onChange={(services) => {
                    setServiceGroups((g) => ({
                      ...g,
                      [id]: {
                        ...g[id],
                        services: services.map((s) => s.id),
                      },
                    }));
                  }}
                  options={serviceOptions}
                />
              </Box>
              <IconButton
                onClick={() => {
                  setServiceGroups((g) => dissocInIf(g, [id], () => true));
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </IconButton>
            </Box>
            <Divider />
          </React.Fragment>
        );
      })}
      <Button
        onClick={() => {
          setServiceGroups((settings) => ({
            ...settings,
            [crypto.randomUUID()]: { name: "", services: [] },
          }));
        }}
      >
        Nová skupina
      </Button>
    </Box>
  );
}

const ServiceGroupCapacityItem = React.memo(function ServiceGroupCapacityItem({
  id,
  settings,
  groupOptions,
  setWeeklySettings,
}) {
  const selectedGroups = new Set(settings.groups);

  return (
    <React.Fragment>
      <Box display="flex">
        <Box display="flex" flexDirection="column" gap="1rem" flexGrow="1">
          <Box>
            <TextField
              required
              defaultValue={settings.from}
              name={`service_group_capacity_weekly.${id}.from`}
              label="Od týdne"
              type="number"
            />
            <TextField
              defaultValue={settings.to}
              name={`service_group_capacity_weekly.${id}.to`}
              label="Do týdne"
              type="number"
            />

            <TextField
              autoFocus
              required
              defaultValue={settings.capacity}
              name={`service_group_capacity_weekly.${id}.capacity`}
              label="Kapacita (%)"
              type="number"
              slotProps={{ htmlInput: { step: "0.1" } }}
            />
          </Box>

          <AutocompleteSelect
            required
            multiple
            label="Skupiny"
            name={`service_group_capacity_weekly.${id}.groups`}
            value={groupOptions.filter((o) => selectedGroups.has(o.id))}
            onChange={(groups) => {
              setWeeklySettings((g) => ({
                ...g,
                [id]: {
                  ...g[id],
                  groups: groups.map((s) => s.id),
                },
              }));
            }}
            options={groupOptions}
          />
        </Box>
        <IconButton
          onClick={() => {
            setWeeklySettings((settings) =>
              dissocInIf(settings, [id], () => true),
            );
          }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </IconButton>
      </Box>
      <Divider />
    </React.Fragment>
  );
});

function ServiceGroupCapacity({ groupOptions, weeklySettings: weekly }) {
  const [weeklySettings, setWeeklySettings] = React.useState(() => {
    return Object.fromEntries(weekly.map((s) => [crypto.randomUUID(), s]));
  });

  return (
    <Box display="flex" flexDirection="column" gap="1rem" marginTop="1rem">
      {Object.entries(weeklySettings).map(([id, settings]) => {
        return (
          <ServiceGroupCapacityItem
            key={id}
            id={id}
            settings={settings}
            groupOptions={groupOptions}
            setWeeklySettings={setWeeklySettings}
          />
        );
      })}

      <Button
        onClick={() => {
          setWeeklySettings((settings) => ({
            ...settings,
            [crypto.randomUUID()]: { group: {} },
          }));
        }}
      >
        Nová týdenní kapacita
      </Button>
    </Box>
  );
}

const formConverter = compose(
  (formData) =>
    updateInIfExists(formData, ["service_group_capacity_weekly"], (v) =>
      Object.values(v),
    ),
  (formData) =>
    updateInIfExists(formData, ["buffer", "times"], (v) => Object.values(v)),
  (formData) =>
    updateInIfExists(formData, ["blocations"], (v) => Object.values(v)),
);

function BufferForm({ reservationSettings }) {
  const [times, setTimes] = React.useState(() => {
    return Object.fromEntries(
      (reservationSettings.buffer?.times ?? []).map((v) => [
        crypto.randomUUID(),
        v,
      ]),
    );
  });

  return (
    <React.Fragment>
      <TextField
        required
        defaultValue={reservationSettings.buffer?.capacity}
        name="buffer.capacity"
        label="Kapacita (%)"
        type="number"
      />
      <Box display="flex" flexDirection="column" gap="1rem" marginTop="1rem">
        {Object.entries(times).map(([id, t]) => {
          return (
            <Box key={id} display="flex">
              <TextField
                required
                defaultValue={t.from}
                name={`buffer.times.${id}.from`}
                label="Od"
                type="time"
              />
              <TextField
                required
                defaultValue={t.to}
                name={`buffer.times.${id}.to`}
                label="Do"
                type="time"
              />
              <IconButton
                onClick={() => {
                  setTimes((times) => dissocInIf(times, [id], () => true));
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </IconButton>
            </Box>
          );
        })}
        <Button
          onClick={() => {
            setTimes((times) => {
              const id = crypto.randomUUID();

              return { ...times, [id]: { from: "", to: "" } };
            });
          }}
        >
          Přidat čas
        </Button>
      </Box>
    </React.Fragment>
  );
}

function ReservationBlocationForm({ reservationBlocationSettings }) {
  const [times, setTimes] = React.useState(() => {
    return Object.fromEntries(
      (reservationBlocationSettings ?? []).map((v) => [crypto.randomUUID(), v]),
    );
  });

  return (
    <>
      <Box display="flex" flexDirection="column" gap="1rem" marginTop="1rem">
        {Object.entries(times).map(([id, t]) => {
          return (
            <Box key={id} display="flex">
              <TextField
                required
                defaultValue={t.valid_from}
                name={`blocations.${id}.valid_from`}
                label="Od"
                type="date"
              />
              <TextField
                required
                defaultValue={t.valid_to}
                name={`blocations.${id}.valid_to`}
                label="Do"
                type="date"
              />
              <IconButton
                onClick={() => {
                  setTimes((times) => dissocInIf(times, [id], () => true));
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </IconButton>
            </Box>
          );
        })}
        <Button
          onClick={() => {
            setTimes((times) => {
              const id = crypto.randomUUID();

              return { ...times, [id]: { valid_from: "", valid_to: "" } };
            });
          }}
        >
          Přidat čas
        </Button>
      </Box>
    </>
  );
}

export default function ReservationSettingsEdit() {
  const { t } = useTranslation();
  const loaderData = useLoaderData();
  const services = loaderData.data.locationServices.data.map((ls) => ({
    id: ls.service_id,
    name: ls.service.name,
    duration: ls.duration,
  }));
  const serviceById = indexBy(services, (s) => s.id);
  const navigation = useNavigation();
  const formKey = React.useMemo(() => crypto.randomUUID(), [loaderData]);

  const reservationSettings =
    loaderData.data.locationSettings.data?.reservation_settings ?? {};

  const { errors: formErrors, onSubmit } = useRouterFormAction({
    converter: formConverter,
  });

  const [serviceGroups, setServiceGroups] = React.useState(
    reservationSettings.service_groups ?? {},
  );
  const groupOptions = Object.entries(serviceGroups).map(([k, v]) => ({
    id: k,
    name: v.name,
  }));
  const [selectedGroups, setSelectedGroups] = React.useState(() => {
    const ids = new Set(reservationSettings?.waiting_list?.groups ?? []);
    return groupOptions.filter((go) => ids.has(go.id));
  });

  return (
    <Form key={formKey} onSubmit={onSubmit}>
      <fieldset>
        <legend>Skupiny služeb</legend>
        <ServiceGroupForm
          serviceById={serviceById}
          serviceGroups={serviceGroups}
          setServiceGroups={setServiceGroups}
        />
      </fieldset>
      <fieldset>
        <legend>Kapacity služeb</legend>
        <ServiceGroupCapacity
          groupOptions={groupOptions}
          weeklySettings={
            reservationSettings.service_group_capacity_weekly ?? []
          }
        />
      </fieldset>
      <fieldset>
        <legend>Waiting list</legend>
        <AutocompleteSelect
          multiple
          label="Skupiny"
          name={`waiting_list.groups`}
          value={selectedGroups}
          onChange={(groups) => {
            setSelectedGroups(groups);
          }}
          defaultValue
          options={groupOptions}
        />
        <TextField
          required
          defaultValue={reservationSettings.waiting_list?.weeks}
          name="waiting_list.weeks"
          label="Posledních týdnů"
          type="number"
        />
      </fieldset>
      <fieldset>
        <legend>Uvolnění kapacity pro libovolné služby</legend>
        <Box>
          <TextField
            required
            defaultValue={reservationSettings.release_days}
            name={`release_days`}
            label="Posledních dnů"
            type="number"
          />
        </Box>
      </fieldset>
      <fieldset>
        <legend>Denní buffer</legend>
        <BufferForm reservationSettings={reservationSettings} />
      </fieldset>
      <fieldset>
        <legend>Blokace rezervací</legend>
        <ReservationBlocationForm
          reservationBlocationSettings={reservationSettings.blocations}
        />
      </fieldset>
      <FormErrorList formErrors={formErrors} />
      <Button type="submit" disabled={navigation.state !== "idle"}>
        {navigation.state !== "idle" && <ButtonLoader />}
        {t("form.submit")}
      </Button>
    </Form>
  );
}
