import { Box } from "@mui/material";
import React from "react";

/**
 * A component to display the column header
 *
 * @typedef {object} ColumnHeaderProps
 * @property {React.ReactNode} children The children to render

 *
 * @param {ColumnHeaderProps} props
 * @returns {JSX.Element}
 */

const ColumnHeader = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        px: 1.5,
        py: 1,
        textAlign: "left",
        backgroundColor: "background.default",
        fontSize: "1rem",
      }}
    >
      {children}
    </Box>
  );
};

export { ColumnHeader };
