import * as d from "shared/src/date.mjs";

const appointmentDelayedBy = (appointmentTime) => {
  const now = new Date();
  const appointment = d.parseDateTime(appointmentTime);
  const diff = now - appointment;
  if (diff < 0) {
    return 0;
  }
  const totalMinutes = Math.floor(diff / 1000 / 60);

  return totalMinutes;
};

const searchAppointments = (appointments, searchTerm, statuses = []) => {
  const searchTerms = searchTerm.split(" ").map((term) =>
    term
      .toLowerCase()
      .normalize("NFD")
      .replace(/\p{Diacritic}/gu, ""),
  );

  let filteredAppointments = appointments;
  if (statuses.length) {
    filteredAppointments = filteredAppointments.filter((appointment) =>
      statuses.includes(appointment.status_id),
    );
  }
  searchTerms.forEach((term) => {
    filteredAppointments = filteredAppointments.filter(
      (appointment) =>
        appointment.client.first_name
          .toLowerCase()
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .includes(term) ||
        appointment.client.last_name
          .toLowerCase()
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .includes(term) ||
        appointment.client.birth_number?.includes(term) ||
        appointment.client.phone?.includes(term) ||
        appointment.start.split(" ")[1].includes(term) ||
        appointment.start.split(" ")[1].replace(":", "").includes(term),
    );
  });
  return filteredAppointments;
};

const getCheckedInMinutes = (checkedIn, appointment) => {
  if (checkedIn) {
    const checkedInTime = appointment.calendar_id
      ? d.parseUtcDateTime(checkedIn.created_at)
      : d.parseDateTime(checkedIn.created_at);
    const appointmentTime = d.parseDateTime(appointment.start);
    const diff = checkedInTime - appointmentTime;

    return Math.floor(diff / 1000 / 60);
  }
};

const getWaitingTime = (checkedIn, appointment) => {
  if (checkedIn) {
    const checkedInTime = appointment.calendar_id
      ? d.parseUtcDateTime(checkedIn.created_at)
      : d.parseDateTime(checkedIn.created_at);
    const appointmentTime = d.parseDateTime(appointment.start);
    const diff = checkedInTime - appointmentTime;

    const now = new Date();
    let time;
    if (diff < 0) {
      time = now - appointmentTime;
    } else {
      time = now - checkedInTime;
    }
    return Math.floor(time / 1000 / 60);
  }
};

export {
  appointmentDelayedBy,
  searchAppointments,
  getCheckedInMinutes,
  getWaitingTime,
};
