import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { useLoaderData } from "react-router";
import * as api from "../services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "../components/Link";
import { faPen } from "@fortawesome/free-solid-svg-icons";

export async function loader({ params }) {
  const res = await api.loadProduct({ id: params.id });
  if (res.data.product == null) {
    throw new Response("Not Found", { status: 404, statusText: "Not Found" });
  }

  return res;
}

export default function Product() {
  const loaderData = useLoaderData();
  const product = loaderData.data.product;

  return (
    <>
      <Typography variant="h1" sx={{ mb: 2 }}>
        {product.name}
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Barva</TableCell>
            <TableCell>Název</TableCell>
            <TableCell>Typ</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {product.services.map((row) => (
            <TableRow key={row.id}>
              <TableCell>
                <Box
                  sx={{
                    backgroundColor: row.color,
                    width: "1rem",
                    height: "1rem",
                  }}
                ></Box>
              </TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.type}</TableCell>
              <TableCell>
                <IconButton component={Link} to={`/services/${row.id}/edit`}>
                  <FontAwesomeIcon icon={faPen} fixedWidth />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
