import { JsonForms } from "@jsonforms/react";
import { materialRenderers } from "../jsonForms/materialRenderers.jsx";
import { materialCells } from "@jsonforms/material-renderers";
import { Box, Divider, Typography, Grid } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ServicesSelectInput from "./ServicesSelectInput.jsx";
import { useWizardContext } from "./AppointmentWizardContext.jsx";

const ServiceJsonFormComponent = ({
  serviceId,
  services,
  translation,
  formSubmitted,
  onFormChange,
}) => {
  const service = useMemo(
    () => services.find((s) => s.id === serviceId),
    [serviceId, services],
  );

  const { getFormPrefillData, filledFormsData } = useWizardContext();

  const templateId = service?.templates?.[0]?.id;
  const template = service?.templates?.[0]?.template;

  const initialData = useMemo(() => {
    if (!templateId || !template) return {};
    return filledFormsData?.[templateId] ?? getFormPrefillData(template);
  }, [templateId, template, filledFormsData, getFormPrefillData]);

  const [formState, setFormState] = useState(initialData);

  useEffect(() => {
    if (!templateId || !template) return;

    if (filledFormsData?.[templateId]) {
      setFormState(filledFormsData[templateId]);
    } else {
      setFormState(getFormPrefillData(template));
    }
  }, [templateId, template, filledFormsData, getFormPrefillData]);

  const handleFormChange = (data) => {
    setFormState(data);
    if (templateId) {
      onFormChange(templateId, data);
    }
  };

  return template?.schema ? (
    <JsonForms
      schema={JSON.parse(template.schema)}
      uischema={JSON.parse(template.uischema)}
      data={formState}
      renderers={materialRenderers}
      cells={materialCells}
      onChange={({ data }) => handleFormChange(data)}
      config={{ forceShow: formSubmitted }}
      i18n={{ translate: translation }}
    />
  ) : (
    <div>{translation("service.notFound", "Service not found")}</div>
  );
};

const AppointmentServices = ({ services, formSubmitted, onFormChange }) => {
  const { t, i18n } = useTranslation(["translation", "serviceTemplates"]);
  const { selectedServiceId, setSelectedServiceId } = useWizardContext();

  const handleChange = (e) => setSelectedServiceId(e.target.value);

  const translation = useMemo(
    () => (key, defaultMessage) =>
      i18n.exists(`serviceTemplates:${key}`)
        ? t(`serviceTemplates:${key}`)
        : defaultMessage,
    [i18n.language],
  );

  useEffect(() => {
    if (!selectedServiceId && services.length > 0) {
      setSelectedServiceId(services[0].id);
    }
  }, [services, selectedServiceId, setSelectedServiceId]);

  return (
    <>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        {t("appointmentNotes.serviceNotes")}
      </Typography>

      <Box sx={{ mb: 2 }}>
        <Grid container spacing={1}>
          <Grid
            size={{ xs: 12, md: 4 }}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <ServicesSelectInput
              services={services}
              value={selectedServiceId}
              labelKey="appointmentNotes.service"
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Box sx={{ mt: 2 }}>
          <ServiceJsonFormComponent
            services={services}
            serviceId={selectedServiceId}
            formSubmitted={formSubmitted}
            translation={translation}
            onFormChange={onFormChange}
          />
        </Box>

        <Divider sx={{ marginTop: 4, marginBottom: 4 }} />
      </Box>
    </>
  );
};

export default AppointmentServices;
