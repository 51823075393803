import React from "react";
import { rankWith, uiTypeIs } from "@jsonforms/core";
import { withJsonFormsLayoutProps } from "@jsonforms/react";
import { CustomMaterialLayoutRenderer } from "./CustomMaterialLayoutRenderer.jsx";

/**
 * Default tester for a horizontal layout.
 */
export const customMaterialHorizontalLayoutTester = rankWith(
  3,
  uiTypeIs("HorizontalLayout"),
);

export const CustomMaterialHorizontalLayout = ({
  uischema,
  renderers,
  cells,
  schema,
  path,
  enabled,
  visible,
}) => {
  const childProps = {
    elements: uischema.elements,
    schema,
    path,
    enabled,
    visible,
  };

  return (
    <CustomMaterialLayoutRenderer
      {...childProps}
      renderers={renderers}
      cells={cells}
    />
  );
};

export default withJsonFormsLayoutProps(CustomMaterialHorizontalLayout);
