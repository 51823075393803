import { useCallback, useEffect } from "react";
import { useWizardContext } from "../AppointmentWizardContext";
import {
  createAppointmentProgressStep,
  fetchAppointmentState,
} from "../../../services/api";
import { logout } from "../../../auth";

export const WIZARD_STEPS = {
  PREPARATION: 0,
  COMPLETION: 1,
  RECEPTION_NOTES: 2,
  PROCEDURE_NOTES: 3,
  CHAIR_CLEANING: 4,
};

const STEPS_CONFIG = {
  [WIZARD_STEPS.PREPARATION]: {
    label: "Preparation",
    nextLabel: "Příprava hotová",
    canProgress: true,
  },
  [WIZARD_STEPS.COMPLETION]: {
    label: "Completion",
    nextLabel: "Ukončit Ošetření",
    canProgress: true,
  },
  [WIZARD_STEPS.RECEPTION_NOTES]: {
    label: "Reception Notes",
    nextLabel: "Odeslat na Recepci",
    canProgress: true,
  },
  [WIZARD_STEPS.PROCEDURE_NOTES]: {
    label: "Procedure Notes",
    nextLabel: "Zápis dokončen ",
    canProgress: true,
  },
  [WIZARD_STEPS.CHAIR_CLEANING]: {
    label: "Úklid křesla",
    nextLabel: "Logout",
    canProgress: false,
  },
};

export const useWizardSteps = () => {
  const { activeStep, setActiveStep, currentAppointmentId } =
    useWizardContext();

  useEffect(() => {
    const syncAppointmentState = async () => {
      if (!currentAppointmentId) return;

      try {
        const response = await fetchAppointmentState(currentAppointmentId);
        const dbStep = response?.data?.appointmentState?.activeStep;

        if (dbStep !== undefined && dbStep !== activeStep) {
          setActiveStep(dbStep);
        }
      } catch (error) {
        console.error("Failed to fetch appointment state:", error);
      }
    };

    syncAppointmentState();
  }, [currentAppointmentId, activeStep, setActiveStep]);

  const getStepConfig = useCallback(
    (step) => STEPS_CONFIG[step] || STEPS_CONFIG[WIZARD_STEPS.PREPARATION],
    [],
  );

  const handleProgressStep = useCallback(async () => {
    const currentConfig = getStepConfig(activeStep);

    if (currentConfig.canProgress) {
      const nextStep = activeStep + 1;

      const stepEnum = Object.keys(WIZARD_STEPS).find(
        (key) => WIZARD_STEPS[key] === nextStep,
      );

      if (!stepEnum) {
        console.error("Invalid step value:", nextStep);
        return;
      }

      setActiveStep(nextStep);

      try {
        await createAppointmentProgressStep({
          appointment_id: currentAppointmentId,
          step: stepEnum,
          time_spent: 0,
        });

        if (nextStep === WIZARD_STEPS.CHAIR_CLEANING) {
          await logout("/rfid-auth");
        }
      } catch (error) {
        console.error("Failed to update appointment state:", error);
        alert("Unable to proceed to the next step. Please try again.");
      }
    }
  }, [activeStep, currentAppointmentId, getStepConfig, setActiveStep]);

  const setSpecificStep = useCallback(
    async (step) => {
      setActiveStep(step);

      try {
        await createAppointmentProgressStep({
          appointment_id: currentAppointmentId,
          step,
          time_spent: 0,
        });
      } catch (error) {
        console.error("Failed to update appointment state:", error);
      }
    },
    [setActiveStep, currentAppointmentId],
  );

  const isStepCompleted = useCallback(
    (step) => step < activeStep,
    [activeStep],
  );

  const isStepActive = useCallback((step) => step === activeStep, [activeStep]);

  const getStepButtonLabel = useCallback(
    () => getStepConfig(activeStep).nextLabel,
    [activeStep, getStepConfig],
  );

  return {
    activeStep,
    handleProgressStep,
    setSpecificStep,
    isStepCompleted,
    isStepActive,
    getStepButtonLabel,
    getStepConfig,
  };
};
