import "@event-calendar/core/index.css";
import React from "react";
import { renderToStaticMarkup } from "react-dom/server.browser";
import { useLoaderData } from "react-router";
import ECal from "@event-calendar/core";
import ResourceTimeGridPlugin from "@event-calendar/resource-time-grid";
import InteractionPlugin from "@event-calendar/interaction";
import * as api from "../services/api";
import {
  Select,
  MenuItem,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Box,
  DialogActions,
  RadioGroup,
  Radio,
  FormControlLabel,
  CircularProgress,
  Typography,
  useTheme,
  IconButton,
  LinearProgress,
  Chip,
} from "@mui/material";
import * as f from "../utils/formatter";
import * as ws from "../utils/ws";
import { useTranslation } from "react-i18next";
import ButtonLoader from "../components/ButtonLoader";
import * as d from "shared/src/date.mjs";
import { groupBy, indexBy, throttle } from "shared/src/util.mjs";
import { status } from "shared/src/appointment.mjs";
import { THROTTLE_REFRESH } from "../constants";
import { errorsFromApi, FormErrorList } from "../form";
import {
  appointmentBackground,
  createClickHandler,
  appointmentColor,
  appointmentInfoColor,
} from "../components/calendar";
import { animationInterval } from "../utils/util";
import { useLatest } from "../hooks";
import { createUrlString } from "../url";
import Link from "../components/Link";
import {
  currentLocationFromRequest,
  useCurrentLocation,
} from "../components/LocationSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAlarmExclamation,
  faHourglass,
  faLockKeyhole,
  faSeatAirline,
  faTimer,
  faXmark,
} from "@fortawesome/pro-solid-svg-icons";
import {
  faCircleExclamation,
  faClock,
  faCommentDots,
  faFaceAngry,
  faGlobe,
  faUserDoctor,
  faUserNurse,
} from "@fortawesome/free-solid-svg-icons";
import ServiceTag from "../components/ServiceTag.jsx";
import { time24 } from "../utils/formatter";
import ResourceLabel from "../components/calendar/ResourceLabel.jsx";
import { adhocTypes } from "../components/reservationDialog/NewAdhocDialog";

/**
 * @typedef EventCalendar
 * @property {() => Event[]} getEvents
 * @property {(e: Event) => EventCalendar} updateEvent
 *
 * @typedef Service
 * @property {string} name
 *
 * @typedef Client
 * @property {string} first_name
 * @property {string} last_name
 *
 * @typedef Audit
 * @property {created_at} string
 * @property {object} changed_fields
 *
 * @typedef AuditConnection
 * @property {Audit[]} data
 *
 * @typedef Employee
 * @property {string} id
 * @property {string} first_name
 * @property {string} last_name
 *
 * @typedef Appointment
 * @property {string} start
 * @property {string} end
 * @property {number} status_id
 * @property {string} calendar_id
 * @property {string} [actual_calendar_id]
 * @property {Service} service
 * @property {Client} client
 * @property {AuditConnection} audit
 * @property {Employee} [employee]
 *
 * @typedef Calendar
 * @property {string} id
 * @property {string} title
 * @property {string} location_id
 *
 * @typedef Resource
 * @property {Calendar} extendedProps
 *
 * @typedef Event
 * @property {Date} start
 * @property {Date} end
 * @property {'auto' | 'background'} display
 * @property {Appointment} extendedProps
 *
 * @typedef ResourceStatusEmpty
 * @property {'empty'} type
 *
 * @typedef ResourceStatusLocked
 * @property {'locked'} type
 *
 * @typedef ResourceStatusWaiting
 * @property {'waiting'} type
 * @property {Appointment} appointment
 *
 * @typedef ResourceStatusTreating
 * @property {'treating'} type
 * @property {Appointment} appointment
 * @property {number} remaining
 * @property {number} progress
 * @property {number} time
 *
 * @typedef {ResourceStatusEmpty | ResourceStatusWaiting | ResourceStatusTreating | ResourceStatusLocked} ResourceStatus
 *
 * @typedef AssignCalendar
 * @property {string} appointment_id
 * @property {Calendar} actual_calendar
 */

const slotStatus = {
  WAITING: "waiting",
  EMPTY: "empty",
  TREATING: "treating",
  LOCKED: "locked",
};

export async function loader({ request }) {
  const location_id = currentLocationFromRequest(request);

  return api.loadCalendarData({
    calendarFilter: { visible: { eq: true }, location_id: { eq: location_id } },
  });
}

/**
 * @param {Appointment} event
 *
 * @returns {Date | null}
 */
function getStatusChangedAt(event) {
  const data = event.audit.data;

  const last = data.findLast((a) => a.changed_fields?.status_id != null);
  if (last != null) {
    return d.parseUtcDateTime(last.created_at);
  }

  const first = data[0];
  if (first?.row_data?.status_id != null) {
    return d.parseUtcDateTime(first.created_at);
  }
}

/**
 * @param {Audit[]} data
 *
 * @returns {Audit|null}
 */
function getPresentSinceAudit(data) {
  let last = null;
  for (let i = data.length - 1; i >= 0; i--) {
    const current = data[i];
    const status_id = data[i].changed_fields?.status_id;
    if (status_id == null) {
      continue;
    }

    if (status_id === String(status.CONFIRMED)) {
      return current;
    }

    if (status_id === String(status.WAITING)) {
      last = current;
    }
  }

  if (last != null) {
    return last;
  }

  const first = data[0];
  const created_status_id = first?.row_data?.status_id;
  if (
    created_status_id === String(status.CONFIRMED) ||
    created_status_id === String(status.WAITING)
  ) {
    return first;
  }
}

const waitingInfoStatuses = new Set([status.WAITING, status.CONFIRMED]);

/**
 * @param {Appointment} appointment
 *
 * @returns {Date | null}
 */
function getPresentStatusChangedAt(appointment) {
  if (!waitingInfoStatuses.has(appointment.status_id)) {
    return;
  }

  const data = appointment.audit.data;
  const audit = getPresentSinceAudit(data);
  if (audit != null) {
    return d.parseUtcDateTime(audit.created_at);
  }
}

function compareNumbers(n1, n2) {
  return n2 - n1;
}

const interestingStatusArr = [status.WAITING, status.ASSIGNED, status.TREATING];

const statusPriority = Object.fromEntries(
  interestingStatusArr.entries().map(([k, v]) => [v, k]),
);

const interestingResourceStatus = new Set(interestingStatusArr);

/**
 * @param {Appointment} appointment
 * @param {Date} now
 *
 * @returns {ResourceStatus}
 */
function appointmentStatus(appointment, now) {
  switch (appointment.status_id) {
    case status.ASSIGNED:
    case status.WAITING: {
      return {
        type: slotStatus.WAITING,
        appointment,
      };
    }
    case status.TREATING: {
      const changedAt = getStatusChangedAt(appointment);
      const dur =
        duration(
          d.parseDateTime(appointment.start),
          d.parseDateTime(appointment.end),
        ) *
        60 *
        1000;

      const startedSince = changedAt?.getTime() ?? 0;
      const expectedFinish = startedSince + dur;
      const remaining = expectedFinish - now;
      const actualTime = now - startedSince;
      const progress = (actualTime / dur) * 100;

      return {
        type: slotStatus.TREATING,
        remaining,
        progress: progress,
        time: actualTime,
        appointment,
      };
    }
  }
}

/**
 * @param {Appointment[]} appointments
 * @param {Date} now
 *
 * @returns {ResourceStatus[]} Array of size 2
 */
export function resourceStatus(appointments, now) {
  const statuses = appointments.map((appointment) =>
    appointmentStatus(appointment, now),
  );

  for (let i = statuses.length; i < 2; i++) {
    const prevStatus = statuses[i - 1];
    if (prevStatus == null || prevStatus.type === slotStatus.TREATING) {
      statuses.push({ type: slotStatus.EMPTY });

      continue;
    }

    statuses.push({ type: slotStatus.LOCKED });
  }

  statuses.splice(2);

  return statuses;
}

function msToM(ms) {
  return Math.trunc(ms / 1000 / 60);
}

/**
 * @param {Date} start
 * @param {Date} end
 */
function duration(start, end) {
  return msToM(end - start);
}

/**
 * @param {Event} event
 * @param {Date} now
 *
 * @returns Waiting times in minutes
 */
function rawWaitingTimes(event, now) {
  const appointment = event.extendedProps;

  const changedAt = getPresentStatusChangedAt(appointment);
  if (changedAt != null) {
    return {
      waiting_time: msToM(now - changedAt),
      waiting_time_since_start: msToM(now - event.start),
    };
  }
}

/**
 * @param {Event} event
 * @param {Date} now
 */
function waitingTimes(event, now) {
  const times = rawWaitingTimes(event, now);
  if (times == null) {
    return;
  }

  const late = times.waiting_time < times.waiting_time_since_start;
  const color =
    late || times.waiting_time_since_start <= 0
      ? "var(--mui-palette-_components-alert-info-color)"
      : "var(--mui-palette-_components-alert-error-color)";

  return {
    waiting_time: (
      <span style={{ color }}>
        <FontAwesomeIcon icon={faHourglass} />{" "}
        {f.minutesToDuration(times.waiting_time)}
      </span>
    ),
    waiting_time_since_start: (
      <span style={{ color }}>
        <FontAwesomeIcon icon={faAlarmExclamation} />{" "}
        {f.minutesToDuration(times.waiting_time_since_start)}
      </span>
    ),
  };
}

/**
 * @param {Event} event
 * @param {Date} now
 */
function adhocWaitingTimes(event, now) {
  const times = rawWaitingTimes(event, now);
  if (times == null) {
    return;
  }

  return {
    waiting_time: (
      <span style={{ fontWeight: 700 }}>
        <FontAwesomeIcon icon={faTimer} />{" "}
        {f.minutesToDuration(times.waiting_time)}
      </span>
    ),
  };
}

function ChildrenEventContent({ event, resources, appointmentsByCalendarId }) {
  const children = event.extendedProps.children;
  if (children == null) {
    return null;
  }

  return children.map((c) => {
    return (
      <div
        key={c.id}
        className="appointment-draggable"
        data-appointment-id={c.id}
        draggable={isAppointmentDraggable(c)}
      >
        <div
          style={{
            padding: "var(--app-calendar-event-padding)",
            position: "relative",
          }}
        >
          <AssignedEvent
            resources={resources}
            appointment={c}
            appointmentsByCalendarId={appointmentsByCalendarId}
          />
          <ServiceTag service={c.service} />
        </div>
        <hr style={{ width: "100%", margin: 0, borderColor: "#B2C6DE" }} />
      </div>
    );
  });
}

function EventContent({ event, now }) {
  const appointment = event.extendedProps;
  const times = waitingTimes(event, now);
  const service = appointment.service;

  return (
    <>
      <div
        style={{
          color: "var(--mui-palette-_components-heading-color)",
          fontWeight: 700,
        }}
      >
        {f.fullName(appointment.client)}
      </div>
      <ServiceTag service={service} />
      <div
        style={{
          color: appointmentInfoColor(appointment),
          fontWeight: 700,
        }}
      >
        {f.time24(appointment.start)}-{f.time24(appointment.end)} (
        {f.minutesToDuration(duration(event.start, event.end))})
      </div>
      <div style={{ color: "var(--mui-palette-_components-heading-color)" }}>
        {appointment.description}
      </div>
      <div style={{ fontWeight: 700 }}>
        {times?.waiting_time}&nbsp;
        {times?.waiting_time_since_start}
      </div>
    </>
  );
}

function AssignedEventPosition({ position }) {
  if (position == null || position > 1) {
    return null;
  }

  const active = {
    backgroundColor: "#fff",
    borderColor: "#fff",
    borderStyle: "solid",
    borderWidth: "1.8px",
  };
  const inactive = {
    borderColor: "#fff",
    borderStyle: "solid",
    borderWidth: "1.8px",
  };

  return (
    <div
      style={{
        height: "1em",
        width: "0.75em",
        display: "flex",
        flexDirection: "column",
        gap: "10%",
      }}
    >
      <div
        style={{ flexGrow: 1, ...(position === 0 ? active : inactive) }}
      ></div>
      <div
        style={{ flexGrow: 1, ...(position === 1 ? active : inactive) }}
      ></div>
    </div>
  );
}

/**
 * @param {Record<string, Calendar>} resources
 * @param {Appointment} appointment
 * @param {Record<string, Appointment[]} appointmentsByCalendarId
 */
function AssignedEvent({ resources, appointment, appointmentsByCalendarId }) {
  if (appointment.actual_calendar_id == null) {
    return null;
  }

  const assignedResource = resources[appointment.actual_calendar_id];

  const idx = appointmentsByCalendarId[
    appointment.actual_calendar_id
  ]?.findIndex((a) => a.id === appointment.id);

  return (
    <div className="calendar-event--assigned single-line">
      <AssignedEventPosition position={idx} />
      &nbsp;
      {assignedResource?.title}
    </div>
  );
}

function isAppointmentDraggable(appointment) {
  return (
    status.WAITING === appointment.status_id &&
    appointment.actual_calendar_id == null
  );
}

/**
 * @param {Calendar[]} resources
 * @param {Record<string, Appointment[]} appointmentsByCalendarId,
 * @param {Date} now
 */
function createEventContentFn(resources, appointmentsByCalendarId, now) {
  const resourcesById = indexBy(resources, (r) => r.id);

  /**
   * @param {object} info
   * @param {Event} info.event
   */
  return function (info) {
    const appointment = info.event.extendedProps;

    return {
      html: renderToStaticMarkup(
        <div style={{ display: "flex", height: "100%" }}>
          <TagList />
          <div
            className="calendar-event"
            style={{
              background: appointmentBackground(appointment),
              borderColor: appointmentColor(appointment),
            }}
          >
            <ChildrenEventContent
              event={info.event}
              resources={resourcesById}
              appointmentsByCalendarId={appointmentsByCalendarId}
            />
            <div
              className="calendar-event-parent appointment-draggable"
              data-appointment-id={appointment.id}
              style={{
                padding: "var(--app-calendar-event-padding)",
                position: "relative",
              }}
              draggable={isAppointmentDraggable(appointment)}
            >
              <AssignedEvent
                resources={resourcesById}
                appointment={appointment}
                appointmentsByCalendarId={appointmentsByCalendarId}
              />
              <EventContent event={info.event} now={now} />
            </div>
          </div>
        </div>,
      ),
    };
  };
}

/**
 * @param {Date} start
 * @param {Date} end
 * @param {string} location_id
 */
function fetchAppointments(start, end, location_id) {
  return api.loadFlooreManagerAppointments({
    filter: {
      start: {
        gte: d.toString(start),
        lt: d.toString(end),
      },
      location_id: { eq: location_id },
      status_id: {
        in: [
          status.BOOKED,
          status.CONFIRMED,
          status.WAITING,
          status.TREATING,
          status.ASSIGNED,
        ],
      },
    },
    order: { asc: "start" },
    auditFilter: {
      or: [
        {
          action: { eq: "I" },
        },
        {
          changed_fields: {
            name: "status_id",
            expr: { null: false },
          },
        },
      ],
    },
  });
}

function getAppointmentsByCalendarId(appointments) {
  const appointmentsByCalendarId = groupBy(
    appointments.filter((a) => interestingResourceStatus.has(a.status_id)),
    (a) => a.actual_calendar_id,
  );
  Object.values(appointmentsByCalendarId).forEach((appointments) => {
    appointments.sort((a1, a2) =>
      compareNumbers(
        statusPriority[a1.status_id],
        statusPriority[a2.status_id],
      ),
    );
  });

  return appointmentsByCalendarId;
}

function appointmentToEvent(appointment) {
  return {
    ...appointment,
    title: "title",
    resourceId: appointment.calendar_id,
    start: new Date(appointment.start),
    end: new Date(appointment.end),
    extendedProps: appointment,
  };
}

/**
 * @param {HTMLElement} el
 * @param {string} appointmentId
 *
 * @returns {HTMLElement}
 */
function ghostFrom(el, appointmentId) {
  const bcr = el.getBoundingClientRect();

  /** @type {HTMLElement} */
  const ghost = el.cloneNode(true);
  ghost.style.width = `${bcr.width}px`;
  ghost.style.height = `${bcr.height}px`;
  ghost.style.zIndex = 10000;
  ghost.style.position = "absolute";
  ghost.style.pointerEvents = "none";
  ghost.style.top = `${-bcr.height}px`;
  ghost.style.left = `${-bcr.width}px`;
  ghost.style.right = "unset";

  ghost
    .querySelectorAll(
      `.appointment-draggable:not([data-appointment-id="${appointmentId}"])`,
    )
    .forEach((el) => (el.style.display = "none"));

  return ghost;
}

/**
 * @param {object} opts
 * @param {HTMLElement} opts.el
 * @param {React.MutableRefObject<string | undefined>} opts.location_idRef
 * @param {React.MutableRefObject<number>} opts.selectedTime_ref
 * @param {(event: object | null) => void} opts.setEditEvent
 * @param {(Appointment[]) => void} opts.setAdhoc
 */
function createEcal({
  el,
  setEditEvent,
  location_idRef,
  selectedTime_ref,
  setAdhoc,
  setAppointmentsByCalendarId,
  eventContentFn,
}) {
  const clickHandler = createClickHandler({
    onClick: (info) => {
      setEditEvent(info.event.extendedProps);
    },
    onDoubleClick: (info) => {
      const clientId = info.event.extendedProps?.client?.id;
      if (clientId == null) {
        return;
      }

      window.open(
        createUrlString(
          location.origin,
          location.search,
          `/clients/${clientId}`,
        ),
        "_blank",
      );
    },
  });

  let ghost = null;

  function ondragstart(e) {
    if (!e.target.classList.contains("appointment-draggable")) {
      return;
    }
    const eventId = e.target.dataset.appointmentId;

    const el = e.target.closest("article.ec-event");
    ghost = ghostFrom(el, eventId);
    document.body.append(ghost);
    e.dataTransfer.setDragImage(ghost, 0, 0);
    el.classList.add("dragging");

    e.dataTransfer.dropEffect = "move";
    e.dataTransfer.setData(
      "app/json",
      JSON.stringify({ appointment_id: eventId }),
    );
  }

  function ondragend(e) {
    const el = e.target.closest("article.ec-event");
    el.classList.remove("dragging");
    ghost?.remove();
    ghost = null;
  }

  el.addEventListener("dragstart", ondragstart);
  el.addEventListener("dragend", ondragend);

  /** @type {EventCalendar} */
  const cal = new ECal({
    target: el,
    props: {
      plugins: [ResourceTimeGridPlugin, InteractionPlugin],
      options: {
        view: "resourceTimeGridDay",
        nowIndicator: true,
        headerToolbar: { start: "", center: "", end: "" },
        slotLabelFormat: { timeStyle: "short", hour12: false },
        allDaySlot: false,
        slotMinTime: "24:00",
        slotMaxTime: "00:00",
        flexibleSlotTimeLimits: true,
        scrollTime: "09:00",
        eventTextColor: "#000",
        eventDurationEditable: false,
        eventStartEditable: false,
        slotHeight: 96,
        eventDrop: async (info) => {
          const event = info.event;
          const newData = {
            id: event.id,
            start: d.toString(event.start),
            end: d.toString(event.end),
            ...(info.newResource == null
              ? {}
              : { calendar_id: info.newResource.id }),
          };

          const result = await api.patchAppointment(newData);
          if (result.errors != null) {
            info.revert();
          }
        },
        eventClassNames: (info) => {
          const appointment = info.event.extendedProps;

          return `event-id-${info.event.id} status-${appointment.status_id}`;
        },
        eventContent: eventContentFn,
        eventClick: (info) => {
          clickHandler(info);
        },
        eventAllUpdated: () => {
          const ecExtra = document.querySelector(".ec-now-indicator");
          ecExtra?.setAttribute("data-now-time", time24(new Date()));
        },
        eventSources: [
          {
            events: async function (fetchInfo) {
              const location_id = location_idRef.current;
              const selectedTime = selectedTime_ref.current ?? 0;
              if (location_id == null) {
                return [];
              }

              const start = fetchInfo.start;
              const end =
                selectedTime === 0
                  ? fetchInfo.end
                  : (() => {
                      const end = new Date();
                      end.setHours(end.getHours() + selectedTime);

                      return new Date(
                        Math.min(fetchInfo.end.getTime(), end.getTime()),
                      );
                    })();

              const resp = await fetchAppointments(start, end, location_id);

              const appointments = resp.data.appointments.data;
              const appointmentById = indexBy(appointments, (a) => a.id);
              const childAppointments = groupBy(
                appointments,
                (a) => a.parent_id,
              );
              delete childAppointments[null];

              for (const id in childAppointments) {
                const parent = appointmentById[id];
                if (parent == null) {
                  continue;
                }
                parent.children = childAppointments[id];
              }

              setAppointmentsByCalendarId(
                getAppointmentsByCalendarId(appointments),
              );

              const adhoc = appointments.filter((a) => a.calendar_id == null);
              setAdhoc(adhoc);

              return appointments.map((a) => appointmentToEvent(a));
            },
          },
        ],
      },
    },
  });

  return cal;
}

/**
 * @param {ResourceStatus[]} statuses
 */
function isCalendarAvailable(statuses) {
  return statuses.some((s) => s.type === slotStatus.EMPTY);
}

function isValidDraggable(e) {
  return e.dataTransfer.types.includes("app/json");
}

/**
 * @param {ResourceStatusTreating} [status]
 * @param {import('@mui/material').Theme} theme
 */
function remaining(status, theme) {
  const remaining = status?.remaining ?? 0;
  const progress = status?.progress ?? 0;
  const time = status?.time ?? 0;

  return {
    status: {
      props: {
        style: {
          color:
            0 <= remaining
              ? theme.palette._components.heading.color
              : theme.palette.error.main,
        },
      },
    },
    label: `${f.minutesToDuration(msToM(time))}`,
    hoveredProgress: {
      remaining: `${f.minutesToDuration(msToM(remaining))}`,
      time: `${f.minutesToDuration(msToM(time))}`,
      iconStyle: {
        color: theme.palette.primary.main,
      },
    },
    progress: {
      props: {
        value: Math.min(100, progress),
        color: 0 <= remaining ? "primary" : "error",
      },
    },
  };
}

/**
 * @param {ResourceStatus} status
 * @param {import('@mui/material').Theme} theme
 */
function resourceStatusInfo(status, theme, setEditEvent) {
  const resourceDefaultGrey = "#B2C6DE";
  const defaultBgnd = "#F4F7FE";
  switch (status.type) {
    case slotStatus.EMPTY:
      return {
        slot: {
          props: {
            style: {
              borderColor: resourceDefaultGrey,
              backgroundColor: theme.palette.background.default,
              color: theme.palette.primary.main,
              cursor: "default",
            },
            onClick: null,
          },
        },
        remaining: remaining(null, theme),
      };
    case slotStatus.LOCKED:
      return {
        slot: {
          props: {
            style: {
              borderColor: "transparent",
              backgroundColor: theme.palette.background.darker,
              color: resourceDefaultGrey,
              cursor: "default",
            },
            onClick: null,
          },
        },
        remaining: remaining(null, theme),
      };
    case slotStatus.WAITING: {
      const appointment = status.appointment;

      return {
        slot: {
          props: {
            style: {
              borderColor: resourceDefaultGrey,
              backgroundColor: defaultBgnd,
              color: theme.palette.primary.contrastText,
              cursor: "pointer",
            },
            onClick: () => setEditEvent(appointment),
          },
        },
        remaining: remaining(null, theme),
      };
    }
    case slotStatus.TREATING: {
      const appointment = status.appointment;

      return {
        slot: {
          props: {
            style: {
              borderColor: resourceDefaultGrey,
              backgroundColor: defaultBgnd,
              color: theme.palette.primary.contrastText,
              cursor: "pointer",
            },
            onClick: () => setEditEvent(appointment),
          },
        },
        remaining: remaining(status, theme),
      };
    }
  }
}

function ResourceExtraInfo({ appointment }) {
  const client = appointment?.client;
  const employee = appointment?.employee;
  const theme = useTheme();

  return (
    <Box
      sx={{
        marginTop: "1.2rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        fontSize: "1rem",
        lineHeight: "1.1rem",
        gap: "5px",
      }}
    >
      <Box
        className="single-line"
        sx={{ color: theme.palette.background.dark, fontWeight: 600 }}
      >
        {f.fullName(client) ?? <>&nbsp;</>}
      </Box>
      <ServiceTag service={appointment?.service} />
      <Box
        className="single-line"
        sx={{
          color: theme.palette._components.heading.color,
          fontWeight: 400,
        }}
      >
        <FontAwesomeIcon
          icon={faUserNurse}
          size="xl"
          color={theme.palette.primary.main}
        />
        &nbsp;
        <Typography variant="caption">
          {f.fullName(employee) ?? <>&nbsp;</>}
        </Typography>
      </Box>
    </Box>
  );
}

function Resource({
  resource,
  setAssignCalendar,
  now,
  setEditEvent,
  appointments,
}) {
  const theme = useTheme();

  const statuses = resourceStatus(appointments, now);
  const infos = statuses.map((status) =>
    resourceStatusInfo(status, theme, setEditEvent),
  );

  const appointment1 = statuses[0]?.appointment;
  const info1 = infos[0];
  const appointment2 = statuses[1]?.appointment;
  const info2 = infos[1];

  return (
    <Box
      className="floor-manager-resource"
      sx={{ display: "flex", flexShrink: 1, flexGrow: 1 }}
      onDragEnter={function (e) {
        const el = e.currentTarget;
        if (isValidDraggable(e) && isCalendarAvailable(statuses)) {
          el.classList.add("dropping");
        }
      }}
      onDragLeave={function (e) {
        if (e.target !== e.currentTarget) {
          return;
        }
        const el = e.currentTarget;
        el.classList.remove("dropping");
      }}
      onDragOver={function (e) {
        if (isValidDraggable(e) && isCalendarAvailable(statuses)) {
          e.preventDefault();
        }
      }}
      onDrop={function (e) {
        const el = e.currentTarget;
        el.classList.remove("dropping");

        const { appointment_id } = JSON.parse(
          e.dataTransfer.getData("app/json"),
        );

        setAssignCalendar({
          appointment_id: appointment_id,
          actual_calendar: resource,
        });
      }}
    >
      <Box sx={{ flexGrow: 1, flexShrink: 1, width: 0, position: "relative" }}>
        <Typography variant="h3" sx={{ ml: "1.6rem" }}>
          {resource.title}
        </Typography>
        {appointment1 != null && (
          <ResourceLabel resourceStatus={"preparation"} />
        )}
        <Box display="flex">
          <TagList />
          <Box
            className="calendar-slot calendar-slot-1"
            {...info1.slot.props}
            sx={{
              flexGrow: 1,
              flexShrink: 1,
              width: 0,
              ...info1.slot.props?.style,
            }}
          >
            {appointment1 != null && (
              <IconButton
                size="small"
                sx={{
                  position: "absolute",
                  top: "0",
                  right: "0",
                  padding: "0.2rem",
                }}
                onClick={async (e) => {
                  e.stopPropagation();

                  await api.patchAppointment({
                    id: appointment1.id,
                    status_id: status.WAITING,
                    actual_calendar_id: null,
                  });
                }}
              >
                <FontAwesomeIcon icon={faXmark} fontSize="1rem" fixedWidth />
              </IconButton>
            )}
            {appointment1 == null ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <FontAwesomeIcon
                  icon={faSeatAirline}
                  style={{ fontSize: "3.5rem" }}
                />
              </Box>
            ) : (
              <>
                <Box
                  className="calendar-status"
                  {...info1.remaining.status.props}
                  sx={{
                    textAlign: "center",
                    "&:hover": {
                      ".calendar-status--label": {
                        display: "none",
                      },
                      ".calendar-status--progress": {
                        display: "none",
                      },
                      ".calendar-status--label--hovered": {
                        marginTop: "4px",
                        display: "flex",
                        justifyContent: "space-around",
                      },
                    },
                    ...info1.remaining.status.props.style,
                  }}
                >
                  <Box
                    className="calendar-status--label--hovered"
                    sx={{
                      display: "none",
                      fontWeight: 500,
                    }}
                  >
                    <div>
                      <FontAwesomeIcon
                        icon={faSeatAirline}
                        style={info1.remaining.hoveredProgress.iconStyle}
                      />
                      &nbsp;
                      {info1.remaining.hoveredProgress.time}
                    </div>
                    <div>
                      <FontAwesomeIcon
                        icon={faClock}
                        style={info1.remaining.hoveredProgress.iconStyle}
                      />
                      &nbsp;
                      {info1.remaining.hoveredProgress.remaining}
                    </div>
                  </Box>
                  <Box
                    className="calendar-status--label"
                    sx={{ fontWeight: 500 }}
                  >
                    {info1.remaining.label}
                  </Box>
                  <LinearProgress
                    className="calendar-status--progress"
                    variant="determinate"
                    sx={{ borderRadius: 2 }}
                    {...info1.remaining.progress.props}
                  />
                </Box>
                <ResourceExtraInfo appointment={appointment1} />
              </>
            )}
          </Box>
        </Box>
        <Box display="flex">
          <TagList />
          <Box
            className="calendar-slot calendar-slot-2"
            {...info2.slot.props}
            sx={{
              flexGrow: 1,
              flexShrink: 1,
              width: 0,
              ...info2.slot.props.style,
            }}
          >
            {appointment2 == null ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <FontAwesomeIcon
                  icon={
                    statuses[1]?.type === slotStatus.EMPTY
                      ? faSeatAirline
                      : faLockKeyhole
                  }
                  style={{ fontSize: slotStatus.EMPTY ? "2rem" : "3.5rem" }}
                />
              </Box>
            ) : (
              <ResourceExtraInfo appointment={appointment2} />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function LeftMargin() {
  return (
    <div className="ec-time-grid" style={{ display: "flex" }}>
      <div className="ec-sidebar">
        <div className="ec-sidebar-title">all-day</div>
      </div>
      <div className="ec-lines"></div>
    </div>
  );
}

/**
 * @param {React.MutableRefObject<EventCalendar | null>} calRef
 */
function ResourceList({
  resources,
  setAssignCalendar,
  setEditEvent,
  appointmentsByCalendarId,
}) {
  const [now, setNow] = React.useState(new Date());
  React.useEffect(() => {
    const controller = new AbortController();
    animationInterval(1000, controller.signal, () => {
      setNow(new Date());
    });

    return function () {
      controller.abort();
    };
  }, []);

  return (
    <Box className="floor-manager-calendar" display="flex">
      <LeftMargin />
      <Box display="flex" flexGrow="1" flexShrink="1">
        {resources.length === 0 ? (
          <Resource
            resource={{ title: <>&nbsp;</> }}
            setAssignCalendar={() => {}}
            setEditEvent={() => {}}
            appointments={[]}
            now={now}
          />
        ) : (
          resources.map((r) => {
            return (
              <Resource
                key={r.id}
                resource={r}
                setAssignCalendar={setAssignCalendar}
                setEditEvent={setEditEvent}
                appointments={appointmentsByCalendarId[r.id] ?? []}
                now={now}
              />
            );
          })
        )}
      </Box>
    </Box>
  );
}

const Cal = React.memo(function Cal({
  date,
  setEditEvent,
  location_id,
  selectedTime,
  setAdhoc,
  appointmentsByCalendarId,
  setAppointmentsByCalendarId,
  resources,
  cal,
}) {
  const ecalEl = React.useRef();
  const location_idRef = React.useRef();
  const selectedTime_ref = React.useRef(selectedTime);

  React.useEffect(() => {
    const refresh = throttle(() => {
      cal.current?.refetchEvents();
    }, THROTTLE_REFRESH);

    function maybeRefresh(msg) {
      if (["appointment_changed", "__connect"].includes(msg.type)) {
        refresh();
      }
    }

    ws.subscribe(maybeRefresh);

    return () => {
      ws.unsubscribe(maybeRefresh);
    };
  }, []);

  React.useEffect(() => {
    location_idRef.current = location_id;
    selectedTime_ref.current = selectedTime;
    cal.current?.refetchEvents();
  }, [location_id, selectedTime]);

  React.useEffect(() => {
    cal.current = createEcal({
      el: ecalEl.current,
      setEditEvent,
      location_idRef,
      selectedTime_ref,
      setAdhoc,
      setAppointmentsByCalendarId,
      eventContentFn: createEventContentFn(
        resources,
        appointmentsByCalendarId,
        new Date(),
      ),
    });

    return function () {
      cal.current.destroy();
      cal.current = null;
    };
  }, []);

  React.useEffect(() => {
    const c = cal.current;
    if (c == null) {
      return;
    }

    const update = () => {
      c.setOption(
        "eventContent",
        createEventContentFn(resources, appointmentsByCalendarId, new Date()),
      );
    };

    const controller = new AbortController();
    update();
    animationInterval(1000, controller.signal, () => {
      update();
    });

    return function () {
      controller.abort();
    };
  }, [appointmentsByCalendarId]);

  React.useEffect(() => {
    if (cal.current == null) {
      return;
    }

    cal.current.setOption("resources", resources);
  }, [resources]);

  React.useEffect(() => {
    const c = cal.current;
    if (c == null) {
      return;
    }

    c.setOption("date", date);
  }, [date]);

  return (
    <>
      <div id="ecal" className="floor-manager-calendar" ref={ecalEl} />
    </>
  );
});

/**
 * @param {object} props
 * @param {Appointment} props.editEvent
 * @param {(event?: Appointment) => void} props.setEditEvent
 */
function EventDialog({ setEditEvent, editEvent, appointmentStatuses }) {
  const { t } = useTranslation();
  const client = editEvent.client;
  const [loading, setLoading] = React.useState(false);

  return (
    <Dialog open={true} onClose={() => setEditEvent(null)}>
      <DialogTitle>{t("calendar.clientReservation")}</DialogTitle>
      <DialogContent>
        {client && (
          <>
            <Link to={`/clients/${client.id}`} target="_blank">
              {f.fullName(client)}
            </Link>
            <br />
          </>
        )}
        <Box display="flex" flexDirection="column" gap="1rem">
          <Select
            value={editEvent.status_id}
            onChange={async (e) => {
              setEditEvent(null);
              await api.patchAppointment({
                id: editEvent.id,
                status_id: e.target.value,
              });
            }}
            sx={{ width: "100%" }}
            disabled={loading}
          >
            {appointmentStatuses.map((s) => (
              <MenuItem key={s.id} value={s.id}>
                {s.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </DialogContent>
      <DialogActions>
        {editEvent.actual_calendar_id !== null && (
          <Button
            disabled={loading}
            onClick={async () => {
              setEditEvent(null);
              await api.patchAppointment({
                id: editEvent.id,
                status_id: status.WAITING,
                actual_calendar_id: null,
              });
            }}
          >
            {t("fcalendar.backToWaiting")}
          </Button>
        )}
        <Button
          color="error"
          disabled={loading}
          onClick={async () => {
            setLoading(true);
            await api.deleteAppointment({ id: editEvent.id });
            setEditEvent(null);
            setLoading(false);
          }}
        >
          {loading && <ButtonLoader />}
          {t("buttons.delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

async function fetchNurses(filter, signal) {
  const res = await api.availableNurses(
    { filter, order: { asc: "last_name" } },
    { signal },
  );

  // todo delete after demo
  const clinisUser = {
    id: "0f9d9e69-8654-42d3-b9e7-d6ef30d8cb28",
    first_name: "CLINIS",
    last_name: "Complete",
    available: true,
  };

  // return res.data.employees.data;

  return [...res.data.employees.data, clinisUser];
}

/**
 * @param {Record<string, Appointment[]} appointmentsByCalendarId
 */
function useNurseOptions(appointmentsByCalendarId) {
  const location_id = useCurrentLocation();
  const [allOptions, setAllOptions] = React.useState();
  const [availableOptions, setAvailableOptions] = React.useState([]);

  React.useEffect(() => {
    const assignedEmployeeIds = new Set(
      Object.values(appointmentsByCalendarId)
        .flatMap((appointments) => appointments.map((a) => a.employee?.id))
        .filter((id) => id != null),
    );
    setAvailableOptions(
      groupBy(allOptions ?? [], (employee) => {
        if (assignedEmployeeIds.has(employee.id)) {
          return "assigned";
        }

        return employee.available ? "available" : "unavailable";
      }),
    );
  }, [allOptions, appointmentsByCalendarId]);

  useLatest(
    async (signal) => {
      setAllOptions(null);
      if (location_id == null) {
        setAllOptions([]);
        return;
      }

      setAllOptions(
        await fetchNurses({ location_id: { eq: location_id } }, signal),
      );
    },
    [location_id],
  );

  return { options: availableOptions, loading: allOptions == null };
}

function initialAssignCalendarDialogState() {
  return {
    loading: false,
    errors: [],
  };
}

function NurseGroup({ label, nurses }) {
  if ((nurses?.length ?? 0) === 0) {
    return null;
  }

  return (
    <>
      <Typography fontWeight={500}>{label}:</Typography>
      {nurses.map((o) => {
        return (
          <FormControlLabel
            key={o.id}
            value={o.id}
            control={<Radio />}
            label={f.fullName(o)}
          />
        );
      })}
    </>
  );
}

/**
 * @param {object} props
 * @param {(data?: AssignCalendar) => void} props.setAssignCalendar
 * @param {AssignCalendar} props.assignCalendar
 * @param {Record<string, Appointment[]} props.appointmentsByCalendarId
 */
function AssignCalendarDialog({
  setAssignCalendar,
  assignCalendar,
  appointmentsByCalendarId,
}) {
  const [state, setState] = React.useState(initialAssignCalendarDialogState());
  const { t } = useTranslation();
  const { options, loading } = useNurseOptions(appointmentsByCalendarId);

  return (
    <Dialog open={true} onClose={() => setAssignCalendar(null)}>
      <DialogTitle>
        {state.loading && (
          <CircularProgress
            size={24}
            sx={{ position: "absolute", left: "calc(50% - 24px)" }}
          />
        )}
        Přesunutí na {assignCalendar.actual_calendar.title}
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <FormControl required disabled={state.loading}>
            <RadioGroup
              onChange={async (_, value) => {
                setState((state) => ({ ...state, loading: true }));
                const result = await api.patchAppointment({
                  id: assignCalendar.appointment_id,
                  actual_calendar_id: assignCalendar.actual_calendar.id,
                  employee_id: value,
                  status_id: status.ASSIGNED,
                });
                const errors = errorsFromApi({ result, t });
                setState((state) => ({ ...state, errors: errors }));
                if (errors.length === 0) {
                  setAssignCalendar(null);
                }
                setState((state) => ({ ...state, loading: false }));
              }}
            >
              <NurseGroup label="Volné" nurses={options.available} />
              <NurseGroup label="Přiřazeny" nurses={options.assigned} />
              <NurseGroup label="Nepřítomné" nurses={options.unavailable} />
            </RadioGroup>
          </FormControl>
        )}
        <FormErrorList formErrors={state.errors} />
      </DialogContent>
    </Dialog>
  );
}

const hourRange = [2, 3, 4];

const Toolbar = React.memo(function Toolbar({ setState, selectedTime }) {
  const { t } = useTranslation();
  const setSelectedTime = (selectedTime) =>
    setState((state) => ({ ...state, selectedTime }));

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "flex-end",
        mb: 0.5,
      }}
    >
      <Typography variant="h3" gutterBottom>
        {t("fcalendar.planned")}
      </Typography>

      <FormControl sx={{ minWidth: "155px" }}>
        {/*<InputLabel>{t("fcalendar.selectTime")}</InputLabel>*/}
        <Select
          label={t("fcalendar.selectTime")}
          value={selectedTime}
          onChange={(e) => {
            const selectedTime = e.target.value;
            setSelectedTime(selectedTime);
          }}
        >
          {hourRange.map((h) => (
            <MenuItem key={h} value={h}>
              {t("fcalendar.hour_range", { count: h })}
            </MenuItem>
          ))}
          <MenuItem value={0}>{t("fcalendar.hour_rangeAll")}</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
});

function TagList() {
  return (
    <div className="tag-list">
      <FontAwesomeIcon className="tag tag-face" icon={faFaceAngry} />
      <FontAwesomeIcon
        className="tag tag-exclamation"
        icon={faCircleExclamation}
      />
      <FontAwesomeIcon className="tag tag-globe" icon={faGlobe} />
      <FontAwesomeIcon className="tag tag-bubble" icon={faCommentDots} />
      <FontAwesomeIcon className="tag tag-doctor" icon={faUserDoctor} />
    </div>
  );
}

/**
 * @param {object} props
 * @param {Appointment[]} props.adhoc
 * @param {string} props.location_id
 * @param {Calendar[]} resources
 * @param {Record<string, Appointment[]} appointmentsByCalendarId
 */
function AdhocAppointments({
  adhoc,
  location_id,
  setEditEvent,
  resources,
  appointmentsByCalendarId,
}) {
  const resourceById = indexBy(resources, (r) => r.id);
  const appointments = adhoc.filter((a) => a.location_id === location_id);
  const [now, setNow] = React.useState(new Date());
  React.useEffect(() => {
    const controller = new AbortController();
    animationInterval(1000, controller.signal, () => {
      setNow(new Date());
    });

    return function () {
      controller.abort();
    };
  });

  return (
    <Box
      display="grid"
      gridTemplateColumns={`repeat(${resources.length}, minmax(0, 1fr))`}
      rowGap={1}
      width="100%"
    >
      {appointments.map((appointment) => {
        const draggable =
          appointment.status_id === status.WAITING &&
          appointment.actual_calendar_id == null;
        const client = appointment.client;
        const service = appointment.service;
        const clickHandler = createClickHandler({
          onClick: () => {
            setEditEvent(appointment);
          },
          onDoubleClick: () => {
            const clientId = appointment.client?.id;
            if (clientId == null) {
              return;
            }

            window.open(
              createUrlString(
                location.origin,
                location.search,
                `/clients/${clientId}`,
              ),
              "_blank",
            );
          },
        });

        const times = adhocWaitingTimes(
          {
            extendedProps: appointment,
            start: d.parseDateTime(appointment.start),
            end: d.parseDateTime(appointment.end),
          },
          now,
        );

        return (
          <Box
            key={appointment.id}
            className="floor-manager-resource"
            onClick={clickHandler}
            sx={{
              cursor: "pointer",
              backgroundColor: "var(--ec-event-bg-color)",
              display: "flex",
              flexGrow: "1",
              flexShrink: "1",
              position: "relative",
            }}
            draggable={draggable}
            onDragStart={(e) => {
              const el = e.currentTarget;
              e.dataTransfer.setDragImage(el, 0, 0);
              el.classList.add("dragging");
              e.dataTransfer.dropEffect = "move";
              e.dataTransfer.setData(
                "app/json",
                JSON.stringify({ appointment_id: appointment.id }),
              );
            }}
            onDragEnd={(e) => {
              const el = e.currentTarget;
              el.classList.remove("dragging");
            }}
          >
            <TagList />
            <Box
              data-id={appointment.id}
              className={`calendar-adhoc-appointment status-${appointment.status_id}`}
              sx={{
                cursor: "pointer",
                border: `1px solid ${appointmentColor(appointment)}`,
                background: appointmentBackground(appointment),
                padding: 0.5,
              }}
            >
              <AssignedEvent
                resources={resourceById}
                appointment={appointment}
                appointmentsByCalendarId={appointmentsByCalendarId}
              />
              {client && (
                <Box
                  sx={{ color: "_components.heading.color", fontWeight: 600 }}
                >
                  {f.fullName(client)}
                </Box>
              )}
              <ServiceTag service={service} />
              <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
                <Typography
                  sx={{
                    fontSize: "1.1rem",
                    color: "_components.alert.info.color",
                  }}
                >
                  {times?.waiting_time}
                </Typography>
                <Chip
                  label={adhocTypes[appointment.adhoc_type]?.name}
                  color="primary"
                  size="small"
                />
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}

function initialCalendarState() {
  return {
    date: f.dateInput(new Date()),
    editEvent: null,
    assignCalendar: null,
    selectedTime: 0,
    adhoc: [],
    appointmentsByCalendarId: {},
  };
}

export default function Calendar() {
  const { t } = useTranslation();
  const loaderData = useLoaderData();
  const appointmentStatuses = loaderData.data.appointmentStatuses.data;
  const resources = loaderData.data.calendars.data;
  const [state, setState] = React.useState(initialCalendarState);
  const cal = React.useRef();
  const location_id = useCurrentLocation();

  const { date, editEvent, assignCalendar } = state;
  const setEditEvent = React.useCallback(
    (editEvent) => setState((state) => ({ ...state, editEvent })),
    [setState],
  );
  const setAssignCalendar = React.useCallback(
    (assignCalendar) => setState((state) => ({ ...state, assignCalendar })),
    [setState],
  );
  const setAdhoc = React.useCallback(
    (adhoc) => setState((state) => ({ ...state, adhoc })),
    [setState],
  );
  const setAppointmentsByCalendarId = React.useCallback(
    (appointmentsByCalendarId) =>
      setState((state) => ({ ...state, appointmentsByCalendarId })),
    [setState],
  );

  return (
    <>
      <Box
        sx={{
          position: "sticky",
          top: "var(--app-sticky-top)",
          zIndex: "1010",
          backgroundColor: "background.default",
        }}
      >
        <ResourceList
          resources={resources}
          setAssignCalendar={setAssignCalendar}
          setEditEvent={setEditEvent}
          appointmentsByCalendarId={state.appointmentsByCalendarId}
        />
        <Box className="floor-manager-calendar" sx={{ mt: 2, display: "flex" }}>
          <LeftMargin />
          <Box
            display="flex"
            flexGrow="1"
            flexShrink="1"
            flexDirection="column"
          >
            <Typography variant="h3" sx={{ mb: 1.5 }}>
              {t("fcalendar.adhoc")}
            </Typography>
            <Box display="flex" flexGrow="1" flexShrink="1">
              <AdhocAppointments
                adhoc={state.adhoc}
                location_id={location_id}
                setEditEvent={setEditEvent}
                resources={resources}
                appointmentsByCalendarId={state.appointmentsByCalendarId}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={{ mt: 1, display: "flex" }}>
          <LeftMargin />
          <Toolbar setState={setState} selectedTime={state.selectedTime} />
        </Box>
      </Box>
      <Cal
        location_id={location_id}
        selectedTime={state.selectedTime}
        date={date}
        setEditEvent={setEditEvent}
        setAssignCalendar={setAssignCalendar}
        setAdhoc={setAdhoc}
        appointmentsByCalendarId={state.appointmentsByCalendarId}
        setAppointmentsByCalendarId={setAppointmentsByCalendarId}
        resources={resources}
        cal={cal}
      />
      {editEvent && (
        <EventDialog
          setEditEvent={setEditEvent}
          editEvent={editEvent}
          appointmentStatuses={appointmentStatuses}
        />
      )}
      {assignCalendar && (
        <AssignCalendarDialog
          setAssignCalendar={setAssignCalendar}
          assignCalendar={assignCalendar}
          appointmentsByCalendarId={state.appointmentsByCalendarId}
        />
      )}
    </>
  );
}
