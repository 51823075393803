import "./receptionTypes.js";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Card, CardContent, Typography } from "@mui/material";

import { status } from "shared/src/appointment.mjs";

import { CheckedInClientHeader } from "./CheckedInClientHeader";
import { CheckedInClient } from "./CheckedInClient";

/**
 * A component to display the checked-in client list
 *
 * @typedef {object} CheckedInClientListProps
 * @property {Array<Appointment>} appointments
 * @property {function} setSnackbarMessage Setter function for the snackbar message
 * @property {function} setSnackbarTitle Setter function for the snackbar title
 * @property {function} setSnackbarOpen Setter function for the snackbar open state
 * @property {function} setIsHovered Setter function for the isHovered state
 *
 * @param {CheckedInClientListProps} props
 * @returns {JSX.Element}
 */

export const CheckedInClientList = ({
  appointments,
  setSnackbarMessage,
  setSnackbarTitle,
  setSnackbarOpen,
  setIsHovered,
}) => {
  const [checkedInClients, setCheckedInClients] = useState(appointments);
  const { t } = useTranslation();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    setCheckedInClients(
      appointments
        .filter(
          (appointment) =>
            appointment.status_id === status.CONFIRMED ||
            appointment.status_id === status.WAITING,
        )
        .sort((a, b) => a.status_id - b.status_id),
    );
  }, [appointments]);

  return (
    <Card onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h2" gutterBottom>
            {t("reception.checked_in_clients")}
          </Typography>
        </Box>

        <div>
          <div
            style={{
              display: "grid",
              gap: "0 1rem",
              gridAutoFlow: "row",
              gridTemplateColumns: "1fr 1fr 1fr",
            }}
          >
            <CheckedInClientHeader />
            <CheckedInClientHeader />
            <CheckedInClientHeader />
          </div>
        </div>

        <div>
          <div
            style={{
              display: "grid",
              gap: "0 1rem",
              gridAutoFlow: "row",
              gridTemplateColumns: "1fr 1fr 1fr",
              gridAutoRows: "1fr",
            }}
          >
            {checkedInClients.map((appointment) => (
              <CheckedInClient
                key={appointment.id}
                appointment={appointment}
                setSnackbarMessage={setSnackbarMessage}
                setSnackbarTitle={setSnackbarTitle}
                setSnackbarOpen={setSnackbarOpen}
              />
            ))}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
