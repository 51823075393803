import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useLoaderData } from "react-router";
import {
  Tab,
  Tabs,
  Box,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import { useTranslation } from "react-i18next";
import DocumentsTable from "../components/DocumentsTable";
import AppointmentNotesTabContent from "../components/client/AppointmentNotesTabContent";
import {
  ClientInfoCard,
  TreatmentTypeCard,
  RiskAllergyCard,
  AppointmentHistoryCard,
  LegalGuardianCard,
  NextAppointmentCard,
} from "../components/client/cards";
import {
  WizardProvider,
  useWizardContext,
} from "../components/client/AppointmentWizardContext";
import AppointmentWizard from "../components/client/AppointmentWizard";
import ClientAvatar from "../components/client/ClientAvatar";
import {
  useWizardSteps,
  WIZARD_STEPS,
} from "../components/client/hooks/useWizardSteps";
import * as api from "../services/api";
import { status } from "shared/src/appointment.mjs";
import {
  adhocTypes,
  adhocTypesEnum,
  NewAdhocDialog,
} from "../components/reservationDialog/NewAdhocDialog";
import AppointmentNoteFields from "../components/client/AppointmentNotesFields";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { faClipboardUser } from "@fortawesome/pro-solid-svg-icons";
import { NewReservationDialog } from "../components/reservationDialog/NewReservationDialog";

export async function loader({ params }) {
  const clientDataRes = await api.loadClientFullData({ id: params.id });
  if (!clientDataRes.data?.client) {
    throw new Response("Not Found", { status: 404 });
  }

  const nextAppointmentRes = await api.getNextAppointmentForClient(
    clientDataRes.data.client.id,
  );
  const nextAppointment =
    nextAppointmentRes.data?.appointments?.data?.[0] || null;

  return {
    client: clientDataRes.data.client,
    legalGuardians: clientDataRes.data.legalGuardians || [],
    treatmentTypes: clientDataRes.data.treatmentTypes || [],
    riskAllergies: clientDataRes.data.riskAllergies || [],
    nextAppointment,
  };
}

function ClientContent() {
  const { t } = useTranslation();
  const loaderData = useLoaderData();
  const {
    client,
    legalGuardians,
    treatmentTypes,
    riskAllergies,
    nextAppointment,
  } = loaderData;

  const [tabValue, setTabValue] = useState("1");
  const { setClientData } = useWizardContext();
  const { activeStep } = useWizardSteps();
  const [rtgDialogOpen, setRtgDialogOpen] = useState(false);
  const [notesDialogOpen, setNotesDialogOpen] = useState(false);
  const [adhocDialogOpen, setAdhocDialogOpen] = useState(false);
  const [reservationDialogOpen, setReservationDialogOpen] = useState(false);
  const isAppointmentFinished = nextAppointment?.status_id === 4;

  const cardStyle = useMemo(
    () => ({
      padding: 2,
      marginBottom: 2,
      width: 300,
      height: 300,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    }),
    [],
  );

  useEffect(() => {
    if (client?.id) {
      setClientData(loaderData);
    }
    return () => {
      setClientData(null);
    };
  }, [client?.id, loaderData, setClientData]);

  useEffect(() => {
    if (activeStep >= WIZARD_STEPS.RECEPTION_NOTES && !isAppointmentFinished) {
      setTabValue("4");
    }
  }, [activeStep]);

  const handleTabChange = useCallback((event, newValue) => {
    setTabValue(newValue);
  }, []);

  const handleOpenRtgDialog = () => {
    setRtgDialogOpen(true);
  };

  const handleCloseRtgDialog = () => {
    setRtgDialogOpen(false);
  };

  const handleOpenNotesDialog = () => {
    setNotesDialogOpen(true);
  };

  const handleCloseNotesDialog = () => {
    setNotesDialogOpen(false);
  };

  const handleOpenAdhocDialog = () => {
    setAdhocDialogOpen({
      close: handleCloseAdhocDialog,
      defaults: {
        appointment: nextAppointment,
        option: client,
        serviceOption: nextAppointment?.service || null,
        types: [adhocTypesEnum.CHAIR],
        selectedType: adhocTypes.CHAIR,
      },
    });
  };

  const handleCloseAdhocDialog = () => {
    setAdhocDialogOpen(false);
  };

  const handleOpenReservationDialog = () => {
    setReservationDialogOpen(true);
  };

  const handleCloseReservationDialog = () => {
    setReservationDialogOpen(false);
  };

  const handleSendToRTG = async () => {
    try {
      if (!client?.id || !nextAppointment?.calendar_id) {
        alert(
          "Client or calendar information is missing. Cannot create RTG appointment.",
        );
        return;
      }

      const {
        data: { serviceByName },
      } = await api.getServiceByName("Rentgen");

      if (!serviceByName?.id) {
        alert("Rentgen service not found.");
        return;
      }

      const rentgenServiceId = serviceByName.id;
      const params = new URLSearchParams(window.location.search);
      const serviceDuration = 20;
      const location_id = params.get("g_location");
      const now = new Date();
      const start = now.toISOString();
      const end = new Date(
        now.getTime() + serviceDuration * 60000,
      ).toISOString();

      const rtgAppointment = {
        client_id: client.id,
        service_id: rentgenServiceId,
        location_id,
        status_id: status.CONFIRMED,
        start,
        end,
      };

      const result = await api.createAppointment(rtgAppointment);

      if (result.errors) {
        console.error("Error creating RTG appointment:", result.errors);
        alert("Failed to create RTG appointment.");
        return;
      }

      alert("RTG appointment created successfully!");
      handleCloseRtgDialog();
    } catch (error) {
      console.error("Failed to create RTG appointment:", error);
      alert("Error occurred while creating RTG appointment.");
    }
  };

  const isAppointmentStarted = activeStep >= 0;

  const isBirthdayToday = () => {
    if (!client?.birthdate) return false;
    const today = new Date();
    const birthDate = new Date(client.birthdate);
    return (
      today.getDate() === birthDate.getDate() &&
      today.getMonth() === birthDate.getMonth()
    );
  };

  if (!client) {
    return <div>{t("client.notFound")}</div>;
  }

  return (
    <>
      <ClientAvatar client={client} t={t} isBirthdayToday={isBirthdayToday()} />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="client-tabs"
        >
          <Tab label={t("client.tabs.overview")} value="1" />
          <Tab label={t("client.tabs.appointmentHistory")} value="2" />
          <Tab label={t("client.tabs.documents")} value="3" />
          {!!nextAppointment && !isAppointmentFinished && (
            <Tab label={t("client.tabs.appointmentNotes")} value="4" />
          )}
        </Tabs>

        {activeStep >= WIZARD_STEPS.COMPLETION && !isAppointmentFinished && (
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Button
              variant="outlined"
              onClick={handleOpenNotesDialog}
              sx={{ padding: "0.7rem 2.4rem", gap: "0.6rem" }}
            >
              <FontAwesomeIcon icon={faClipboardUser} />
              {t("client.writeNotes")}
            </Button>
            <Button
              sx={{ padding: "0.7rem 2.4rem", gap: "0.6rem" }}
              variant="outlined"
              onClick={handleOpenRtgDialog}
            >
              <FontAwesomeIcon icon={faCalendarDays} />
              {t("client.sendToRTG")}
            </Button>
            <Button
              sx={{ padding: "0.7rem 2.4rem", gap: "0.6rem" }}
              variant="outlined"
              onClick={handleOpenAdhocDialog}
            >
              <FontAwesomeIcon icon={faCalendarDays} />
              {t("reception.adhoc")}
            </Button>
            <Button
              sx={{ padding: "0.7rem 2.4rem", gap: "0.6rem" }}
              variant="outlined"
              onClick={handleOpenReservationDialog}
            >
              <FontAwesomeIcon icon={faCalendarDays} />
              {t("reception.reservation")}
            </Button>
          </Box>
        )}
      </Box>

      <TabContext value={tabValue}>
        <TabPanel value="1">
          <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
            <Box>
              <Grid container spacing={2}>
                <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                  <ClientInfoCard client={client} sx={cardStyle} />
                </Grid>

                {treatmentTypes.length > 0 && (
                  <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                    <TreatmentTypeCard
                      treatmentTypes={treatmentTypes}
                      sx={cardStyle}
                    />
                  </Grid>
                )}

                {riskAllergies.length > 0 && (
                  <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                    <RiskAllergyCard
                      riskAllergies={riskAllergies}
                      sx={cardStyle}
                    />
                  </Grid>
                )}

                {legalGuardians.length > 0 && (
                  <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                    <LegalGuardianCard
                      legalGuardians={legalGuardians}
                      sx={cardStyle}
                    />
                  </Grid>
                )}

                <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                  <NextAppointmentCard nextAppointment={nextAppointment} />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </TabPanel>

        <TabPanel value="2">
          <AppointmentHistoryCard clientId={client?.id} />
        </TabPanel>

        <TabPanel value="3">
          <p>{t("client.documentsDescription")}</p>
          <DocumentsTable documents={[]} />
        </TabPanel>

        {!!nextAppointment && !isAppointmentFinished && (
          <TabPanel value="4">
            <AppointmentNotesTabContent />
          </TabPanel>
        )}
      </TabContext>

      {!!nextAppointment && isAppointmentStarted && (
        <AppointmentWizard appointment={nextAppointment} />
      )}

      <Dialog open={rtgDialogOpen} onClose={handleOpenRtgDialog}>
        <DialogTitle>{t("Poslat na RTG")}</DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseRtgDialog}>{t("Zrušit")}</Button>
          <Button onClick={handleSendToRTG} color="primary">
            {t("Potvrdit")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={notesDialogOpen}
        onClose={handleCloseNotesDialog}
        fullWidth
        maxWidth="lg"
      >
        <Box sx={{ p: 3, pb: 4 }}>
          <AppointmentNoteFields />
        </Box>
      </Dialog>

      {adhocDialogOpen && (
        <NewAdhocDialog
          close={handleCloseAdhocDialog}
          defaults={adhocDialogOpen.defaults}
        />
      )}
      {reservationDialogOpen && (
        <NewReservationDialog close={handleCloseReservationDialog} />
      )}
    </>
  );
}

export default function Client() {
  return (
    <WizardProvider>
      <ClientContent />
    </WizardProvider>
  );
}
