import { useState, useCallback, useMemo } from "react";
import { debounce } from "@mui/material";
import { useClients } from "./useClients";
import { DEBOUNCE_INPUT } from "../../../constants";

const useClientOptions = (location_id = null, option = null) => {
  const [term, setTerm] = useState("");
  const { result, loading } = useClients(term, location_id);

  const options = useMemo(() => {
    if (option == null) {
      return result;
    }

    if (result.find((opt) => opt.id === option.id)) {
      return result;
    }

    return [option, ...result];
  }, [option, result]);

  return [options, useCallback(debounce(setTerm, DEBOUNCE_INPUT), []), loading];
};

export { useClientOptions };
