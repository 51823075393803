import "./receptionTypes.js";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { faXmark } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { status } from "shared/src/appointment.mjs";
import * as f from "../../utils/formatter";
import * as api from "../../services/api";

/**
 * A component to display a confirmation modal for moving an appointment to a different status.
 *
 * @typedef {object} ConfirmationModalProps
 * @property {Appointment} appointment
 * @property {function} handleCloseConfirmationModal Function to close confirmation modal
 * @property {boolean} openConfirmationModal Flag to open confirmation modal
 * @property {function} setSnackbarMessage Setter function for the snackbar message
 * @property {function} setSnackbarTitle Setter function for the snackbar title
 * @property {function} setSnackbarOpen Setter function for the snackbar open state
 * @property {number} newStatus New status Id for the appointment
 *
 * @param {ConfirmationModalProps} props
 * @returns {JSX.Element}
 */

const ConfirmationModal = ({
  appointment,
  handleCloseConfirmationModal,
  openConfirmationModal,
  setSnackbarMessage,
  setSnackbarTitle,
  setSnackbarOpen,
  newStatus,
}) => {
  const { t } = useTranslation();
  const handleMoveAppointment = () => {
    handleCloseConfirmationModal();
    api
      .patchAppointment({
        id: appointment.id,
        status_id: newStatus,
      })
      .then(() => {
        setSnackbarMessage(
          <span>
            {t("reception.client_moved_checked_in", {
              name: f.fullName(appointment.client),
            })}
          </span>,
        );
        setSnackbarTitle(t("reception.info"));
        setSnackbarOpen(true);
      });
  };

  return (
    <Dialog
      open={openConfirmationModal}
      onClose={handleCloseConfirmationModal}
      maxWidth="xs"
    >
      <DialogTitle
        sx={{
          padding: "2.4rem 2rem",
          margin: 0,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {newStatus === status.CONFIRMED && t("reception.move_to_checked_in")}
        {newStatus === status.BOOKED && t("reception.move_to_booked")}
        <IconButton aria-label="close" onClick={handleCloseConfirmationModal}>
          <FontAwesomeIcon icon={faXmark} fixedWidth />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ padding: "0 4.4rem" }}>
        <strong>{f.fullName(appointment.client)}</strong>{" "}
        {newStatus === status.CONFIRMED && t("reception.will_be_checked_in")}
        {newStatus === status.BOOKED && t("reception.will_be_booked")}
      </DialogContent>
      <DialogActions sx={{ padding: "3.5rem 2rem 3rem 2rem" }}>
        <Button variant="outlined" onClick={handleCloseConfirmationModal}>
          {t("reception.cancel")}
        </Button>
        <Button variant="contained" onClick={handleMoveAppointment}>
          {t("reception.yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { ConfirmationModal };
