import React from "react";
import * as api from "../services/api";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useLoaderData, useRevalidator } from "react-router";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { minutesToDuration } from "../utils/formatter";
import { formData, FormErrorList, useFormAction } from "../form";
import ButtonLoader from "../components/ButtonLoader";
import { compose, indexBy, updateInIfExists } from "shared/src/util.mjs";
import { currentLocationFromRequest } from "../components/LocationSelector";
import AutocompleteSelect from "../components/AutocompleteSelect";

export async function loader({ params, request }) {
  const res = await api.loadserviceEditData({
    id: params.id,
    location_id: currentLocationFromRequest(request),
  });
  if (res.data.service == null) {
    throw new Response("Not Found", { status: 404, statusText: "Not Found" });
  }

  return res;
}

function Info({ label, value }) {
  return (
    <Box display="flex" alignItems="center" gap="0.5rem">
      <strong>{label}:</strong> {value}
    </Box>
  );
}

function BasicInfoDialog({ setEditService, editService }) {
  const { t } = useTranslation();
  const revalidator = useRevalidator();
  const { loading, errors, onSubmit } = useFormAction(
    (e) => api.patchService(formData(e.currentTarget)),
    () => {
      revalidator.revalidate();
      setEditService(null);
    },
  );

  return (
    <Dialog open={true} onClose={() => setEditService(null)}>
      <DialogTitle>{t("service.editTitle")}</DialogTitle>
      <form onSubmit={onSubmit}>
        <input type="hidden" name="id" value={editService.id} />
        <DialogContent>
          <Box display="flex" flexDirection="column" gap="1rem">
            <TextField
              name="name"
              label={t("services.name")}
              defaultValue={editService.name}
              required
            />
            <TextField
              name="color"
              label={t("services.color")}
              defaultValue={editService.color}
              type="color"
              required
            />
            <FormErrorList formErrors={errors} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditService(null)}>
            {t("form.cancel")}
          </Button>
          <Button type="submit" disabled={loading}>
            {loading && <ButtonLoader />}
            {t("form.submit")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

function BasicInfo({ service }) {
  const { t } = useTranslation();
  const [editService, setEditService] = React.useState();

  return (
    <>
      <Box display="flex" gap="1rem">
        <Box>
          <Info label={t("services.name")} value={service.name} />
          <Info
            label={t("services.color")}
            value={
              <Box
                sx={{
                  backgroundColor: service.color,
                  width: "1rem",
                  height: "1rem",
                }}
              ></Box>
            }
          />
        </Box>
        <IconButton onClick={() => setEditService(service)}>
          <FontAwesomeIcon icon={faPenToSquare} />
        </IconButton>
      </Box>
      {editService && (
        <BasicInfoDialog
          editService={editService}
          setEditService={setEditService}
        />
      )}
    </>
  );
}

const locationInfoFormConverter = compose((formData) =>
  updateInIfExists(formData, ["locationServices"], (v) => Object.values(v)),
);

function LocationInfoDialog({ editService, setEditService, locations }) {
  const { t } = useTranslation();
  const revalidator = useRevalidator();
  const { loading, errors, onSubmit } = useFormAction(
    (e) =>
      api.patchService(locationInfoFormConverter(formData(e.currentTarget))),
    () => {
      revalidator.revalidate();
      setEditService(null);
    },
  );

  const locationServiceByLocationId = indexBy(
    editService.locationServices,
    (sl) => sl.location.id,
  );
  const [locationIds, setLocationIds] = React.useState(
    new Set(Object.keys(locationServiceByLocationId)),
  );

  return (
    <Dialog open={true} onClose={() => setEditService(null)}>
      <DialogTitle>{t("service.editTitle")}</DialogTitle>
      <form onSubmit={onSubmit}>
        <input type="hidden" name="id" value={editService.id} />
        <DialogContent>
          <Box display="flex" flexDirection="column" gap="1rem">
            {Array.from(locationIds).map((id) => {
              const currentLs = locationServiceByLocationId[id];
              const currentLocation = currentLs?.location;
              const locationOption = currentLocation
                ? { id: currentLocation.id, name: currentLocation.name }
                : null;

              return (
                <React.Fragment key={id}>
                  <Box key={id} display="flex">
                    <Box>
                      <AutocompleteSelect
                        name={`locationServices.${id}.location_id`}
                        required
                        options={locations}
                        label="Pobočka"
                        defaultValue={locationOption}
                      />
                      <TextField
                        name={`locationServices.${id}.duration`}
                        label={t("services.duration")}
                        defaultValue={currentLs?.duration ?? ""}
                        type="number"
                        required
                      />
                    </Box>
                    <IconButton
                      onClick={() => {
                        setLocationIds((l) => {
                          const s = new Set(l);
                          s.delete(id);

                          return s;
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </IconButton>
                  </Box>
                  <Divider />
                </React.Fragment>
              );
            })}
            <Button
              onClick={() => {
                setLocationIds((l) => new Set(l).add(crypto.randomUUID()));
              }}
            >
              Přidat pobočku
            </Button>
            <FormErrorList formErrors={errors} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditService(null)}>
            {t("form.cancel")}
          </Button>
          <Button type="submit" disabled={loading}>
            {loading && <ButtonLoader />}
            {t("form.submit")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

function LocationServiceInfo({ locationService }) {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h3">{locationService.location.name}</Typography>
      <Info
        label={t("services.duration")}
        value={minutesToDuration(locationService.duration)}
      />
    </>
  );
}

function LocationInfo({ service, locations }) {
  const [editService, setEditService] = React.useState();

  return (
    <>
      <Box display="flex" gap="1rem">
        <Box>
          <Typography variant="h2">Pobočky</Typography>
          {service.locationServices.map((ls) => (
            <LocationServiceInfo key={ls.location.id} locationService={ls} />
          ))}
        </Box>
        <IconButton onClick={() => setEditService(service)}>
          <FontAwesomeIcon icon={faPenToSquare} />
        </IconButton>
      </Box>
      {editService && (
        <LocationInfoDialog
          locations={locations}
          editService={editService}
          setEditService={setEditService}
        />
      )}
    </>
  );
}

export default function ServiceEditPage() {
  const loaderData = useLoaderData();
  const service = loaderData.data.service;
  const locations = loaderData.data.company?.locations ?? [];

  return (
    <>
      <Typography variant="h1">{service.name}</Typography>
      <BasicInfo service={service} />
      <LocationInfo service={service} locations={locations} />
    </>
  );
}
