import "./receptionTypes.js";
import React, { memo } from "react";
import { Box, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { status } from "shared/src/appointment.mjs";

import * as f from "../../utils/formatter";
import { appointmentDelayedBy } from "./utils";
import { ClientName } from "./ClientName";
import { ConfirmationModal } from "./ConfirmationModal";
import { adhocTypesEnum } from "../reservationDialog/NewAdhocDialog";
import { createClickHandler } from "../calendar";
import { createUrlString } from "../../url";

/**
 * A component to display a booked client on the reception dashboard
 *
 * @typedef {object} BookedClientProps
 * @property {Appointment} appointment
 * @property {function} setSnackbarMessage Setter function for the snackbar message
 * @property {function} setSnackbarTitle Setter function for the snackbar title
 * @property {function} setSnackbarOpen Setter function for the snackbar open state
 * @property {function} setNewAdhocDialogOptions Setter function for the Adhoc dialog options
 *
 * @param {BookedClientProps} props
 * @returns {JSX.Element}
 */

const BookedClient = memo(
  ({
    appointment,
    setSnackbarMessage,
    setSnackbarTitle,
    setSnackbarOpen,
    setNewAdhocDialogOptions,
  }) => {
    const delayedBy = appointmentDelayedBy(appointment.start);
    const [openConfirmationModal, setOpenConfirmationModal] =
      React.useState(false);

    const handleOpenConfirmationModal = () => {
      setOpenConfirmationModal(true);
    };

    const handleCloseConfirmationModal = () => {
      setOpenConfirmationModal(false);
    };

    const clickHandler = createClickHandler({
      onClick: () => {},
      onDoubleClick: () => {
        const clientId = appointment.client?.id;
        if (clientId == null) {
          return;
        }

        window.open(
          createUrlString(
            location.origin,
            location.search,
            `/clients/${clientId}`,
          ),
          "_blank",
        );
      },
    });

    return (
      <>
        <Box
          sx={{
            borderBottomStyle: "solid",
            borderBottomColor: "divider",
            borderWidth: 1,
            padding: 1.5,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={clickHandler}
        >
          <Box sx={{ flex: "0 0 70%", minWidth: "0", paddingRight: "10px" }}>
            <ClientName appointment={appointment} />
          </Box>
          <Box sx={{ flex: "0 0 20%", fontWeight: "bold", color: "grey" }}>
            {f.time24(appointment.start)}{" "}
            {!!delayedBy && (
              <Box
                component="span"
                sx={{ color: "_components.alert.error.color" }}
              >
                ({f.minutesToDuration(delayedBy)})
              </Box>
            )}
          </Box>
          <Box style={{ flex: "0 0 10%", textAlign: "right" }}>
            {appointmentDelayedBy(appointment.start) <= 20 && (
              <IconButton
                sx={{
                  borderRadius: 1,
                  color: "white",
                  backgroundColor: "secondary.main",
                  width: "2.6rem",
                  height: "2.6rem",
                  fontSize: "1.8rem",
                  "&:hover": {
                    color: "secondary.main",
                  },
                }}
                onClick={handleOpenConfirmationModal}
              >
                <FontAwesomeIcon icon={faPlus} />
              </IconButton>
            )}
            {appointmentDelayedBy(appointment.start) > 20 && (
              <IconButton
                sx={{
                  borderRadius: 1,
                  color: "white",
                  backgroundColor: "error.indicator",
                  height: "2.6rem",
                  fontSize: "1.8rem",
                  "&:hover": {
                    color: "error.indicator",
                  },
                }}
                onClick={() => {
                  return setNewAdhocDialogOptions({
                    close: () => setNewAdhocDialogOptions(null),
                    defaults: {
                      appointment,
                      option: appointment.client,
                      serviceOption: { ...appointment.service, duration: 50 }, // TODO change from the done service to upcoming service and fix duration
                      types: [adhocTypesEnum.NOSHOW],
                    },
                  });
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
              </IconButton>
            )}
          </Box>
        </Box>
        <ConfirmationModal
          appointment={appointment}
          handleCloseConfirmationModal={handleCloseConfirmationModal}
          openConfirmationModal={openConfirmationModal}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbarTitle={setSnackbarTitle}
          setSnackbarOpen={setSnackbarOpen}
          newStatus={status.CONFIRMED}
        />
      </>
    );
  },
);

BookedClient.displayName = "BookedClient";
export { BookedClient };
