import "./receptionTypes.js";
import React, { memo } from "react";
import { Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHourglassClock,
  faAlarmClock,
} from "@fortawesome/pro-solid-svg-icons";

import { status } from "shared/src/appointment.mjs";

import { AppointmentDetailModal } from "./AppointmentDetailModal";
import { ClientName } from "./ClientName";
import { ConfirmationModal } from "./ConfirmationModal";
import * as f from "../../utils/formatter";
import { getCheckedInMinutes, getWaitingTime } from "./utils";
import { createClickHandler } from "../calendar";
import { createUrlString } from "../../url";

/**
 * A component to display a checked in client on the reception dashboard
 *
 * @typedef {object} CheckedInClientProps
 * @property {Appointment} appointment
 * @property {function} setSnackbarMessage Setter function for the snackbar message
 * @property {function} setSnackbarTitle Setter function for the snackbar title
 * @property {function} setSnackbarOpen Setter function for the snackbar open state
 *
 * @param {CheckedInClientProps} props
 * @returns {JSX.Element}
 */

const CheckedInClient = memo(
  ({ appointment, setSnackbarMessage, setSnackbarTitle, setSnackbarOpen }) => {
    const [openAppointmentDetailModal, setOpenAppointmentDetailModal] =
      React.useState(false);
    const handleCloseAppointmentDetailModal = () =>
      setOpenAppointmentDetailModal(false);

    const clickHandler = createClickHandler({
      onClick: () => {
        setOpenAppointmentDetailModal(true);
      },
      onDoubleClick: () => {
        const clientId = appointment.client?.id;
        if (clientId == null) {
          return;
        }

        window.open(
          createUrlString(
            location.origin,
            location.search,
            `/clients/${clientId}`,
          ),
          "_blank",
        );
      },
    });

    const [openConfirmationModal, setOpenConfirmationModal] =
      React.useState(false);
    const handleOpenConfirmationModal = () => setOpenConfirmationModal(true);
    const handleCloseConfirmationModal = () => setOpenConfirmationModal(false);

    let checkedInList = appointment.audit.data.filter(
      (appointment) =>
        appointment.changed_fields?.status_id === String(status.CONFIRMED),
    );

    if (checkedInList.length === 0) {
      checkedInList = appointment.audit.data.filter(
        (appointment) =>
          appointment.changed_fields?.status_id === String(status.WAITING),
      );
    }

    const checkedInMinutes = getCheckedInMinutes(
      appointment.calendar_id
        ? checkedInList[checkedInList.length - 1]
        : { created_at: appointment.start },
      appointment,
    );

    const waitingMinutes = getWaitingTime(
      appointment.calendar_id
        ? checkedInList[checkedInList.length - 1]
        : { created_at: appointment.start },
      appointment,
    );

    return (
      <>
        <Box
          sx={{
            display: "flex",
            borderBottomStyle: "solid",
            borderBottomColor: "divider",
            borderWidth: 1,
            alignItems: "center",
            padding: "12px",
            cursor: "pointer",

            backgroundColor:
              appointment.status_id === status.CONFIRMED
                ? "#FDE0CE"
                : "transparent",
          }}
          onClick={clickHandler}
        >
          <Box sx={{ flex: "0 0 60%" }}>
            <ClientName appointment={appointment} />
          </Box>
          <Box
            sx={{
              display: "flex",
              flex: "0 0 40%",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-between",
              fontWeight: "bold",
            }}
          >
            <Box sx={{ flex: "0 0 30%", color: "grey" }}>
              {f.time24(appointment.start)}
            </Box>
            <Box sx={{ flex: "0 0 fit-content", textAlign: "left" }}>
              <Box
                sx={{
                  color:
                    checkedInMinutes > 0
                      ? "_components.alert.error.color"
                      : "_components.alert.info.color",
                }}
              >
                <FontAwesomeIcon
                  icon={faHourglassClock}
                  style={{ marginRight: "5px" }}
                />
                {f.minutesToDuration(checkedInMinutes)}
              </Box>
              <Box
                sx={{
                  color:
                    waitingMinutes > 0
                      ? "_components.alert.error.color"
                      : "_components.alert.info.color",
                }}
              >
                <FontAwesomeIcon
                  icon={faAlarmClock}
                  style={{ marginRight: "5px" }}
                />
                {f.minutesToDuration(waitingMinutes)}
              </Box>
            </Box>
          </Box>
        </Box>
        <AppointmentDetailModal
          appointment={appointment}
          handleCloseAppointmentDetailModal={handleCloseAppointmentDetailModal}
          openAppointmentDetailModal={openAppointmentDetailModal}
          checkedInMinutes={checkedInMinutes}
          waitingMinutes={waitingMinutes}
          handleOpenConfirmationModal={handleOpenConfirmationModal}
        />
        <ConfirmationModal
          appointment={appointment}
          handleCloseConfirmationModal={handleCloseConfirmationModal}
          openConfirmationModal={openConfirmationModal}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbarTitle={setSnackbarTitle}
          setSnackbarOpen={setSnackbarOpen}
          newStatus={status.BOOKED}
        />
      </>
    );
  },
);

CheckedInClient.displayName = "CheckedInClient";
export { CheckedInClient };
