import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays, faXmark } from "@fortawesome/pro-solid-svg-icons";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormGroup,
  IconButton,
  Typography,
  Select,
  MenuItem,
  Slider,
} from "@mui/material";
import { DateRangeCalendar } from "@mui/x-date-pickers-pro";

import * as d from "shared/src/date.mjs";
import { status } from "shared/src/appointment.mjs";

import * as api from "../../services/api";
import ButtonLoader from "../ButtonLoader";
import { useFormAction } from "../../form";
import { useCurrentLocation } from "../LocationSelector";
import MonthSelector from "./MonthSelector";

/**
 * @typedef ManualDateSelectionDialogDefaults
 *
 * @typedef ManualDateSelectionDialogState
 * @property {object | null} option
 * @property {object | null} serviceOption
 * @property {string} type

 *
 * @returns {ManualDateSelectionDialogState}
 */
const initialManualDateSelectionDialogState = (defaults) => {
  return {
    option: defaults.option ?? null,
    serviceOption: defaults.serviceOption ?? null,
    type: defaults.type ?? null,
  };
};

function generateTimeOptions(from, to) {
  const options = [];

  function timeToMinutes(time) {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  }

  function minutesToTime(minutes) {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${String(hours).padStart(2, "0")}:${String(mins).padStart(2, "0")}`;
  }

  const start = timeToMinutes(from);
  const end = timeToMinutes(to);

  // Add the exact "from" time first
  options.push(minutesToTime(start));

  // Round start up to the next 15-minute mark
  let nextStep = Math.ceil(start / 15) * 15;

  // Add 15-minute increments until we reach or surpass "to"
  while (nextStep < end) {
    options.push(minutesToTime(nextStep));
    nextStep += 15;
  }

  // Ensure the exact "to" time is the last value
  if (options[options.length - 1] !== minutesToTime(end)) {
    options.push(minutesToTime(end));
  }

  return options;
}

/**
 * @param {object} props
 * @param {() => void} props.close
 * @param {ManualDateSelectionDialogDefaults} [props.defaults]
 */
const ManualDateSelectionDialog = ({ close, defaults = {} }) => {
  const { t } = useTranslation();
  const [state, _] = useState(() =>
    initialManualDateSelectionDialogState(defaults),
  );
  const [monthRange, setMonthRange] = useState([null, null]);
  const [calendarRange, setCalendarRange] = useState(null);
  const [timeOptions, setTimeOptions] = useState([]);
  const [sliderValue, setSliderValue] = useState([0, 50]);

  useEffect(() => {
    const fetchHours = async () => {
      const { data } = await api.loadWorkingHours({
        location_id: locationId,
      });
      const dateRanges = data.location.working_hours[days[new Date().getDay()]];
      const [validRange] = dateRanges.filter((range) => {
        if (
          new Date(range.valid_from) < new Date() &&
          new Date(range.valid_to) > new Date()
        ) {
          return range;
        }
      });
      setTimeOptions(generateTimeOptions(validRange.from, validRange.to));
    };
    fetchHours();
  }, []);

  const { option, serviceOption } = state;

  const days = {
    0: "su",
    1: "mo",
    2: "tu",
    3: "we",
    4: "th",
    5: "fr",
    6: "sa",
  };

  const locationId = useCurrentLocation();
  const { loading, onSubmit } = useFormAction(
    () => {
      const start = new Date();
      const end = new Date(start.getTime());
      end.setMinutes(end.getMinutes() + serviceOption.duration);

      return api.createAppointment({
        location_id: locationId,
        client_id: option.id,
        service_id: serviceOption.id,
        start: d.toString(start),
        end: d.toString(end),
        status_id: status.CONFIRMED,
      });
    },
    () => close(),
  );

  const submitDisabled = loading;

  return (
    <Dialog open={true} onClose={() => close()} maxWidth="lg">
      <DialogTitle>
        {t("calendar.newReservation")}
        <IconButton aria-label="close" onClick={() => close()}>
          <FontAwesomeIcon icon={faXmark} fixedWidth />
        </IconButton>
      </DialogTitle>
      <form onSubmit={onSubmit}>
        <DialogContent>
          <Box display="flex" flexDirection="row" gap="1rem">
            <Box display="flex" flexDirection="column" gap="1rem">
              <Typography variant="h3">Rychlý výběr</Typography>
              <Box display="flex" flexDirection="column" gap="1rem">
                <FormGroup>
                  <FormControlLabel control={<Checkbox />} label="Pondělí" />
                  <FormControlLabel control={<Checkbox />} label="Úterý" />
                  <FormControlLabel control={<Checkbox />} label="Středa" />
                  <FormControlLabel control={<Checkbox />} label="Čtvrtek" />
                  <FormControlLabel control={<Checkbox />} label="Pátek" />
                </FormGroup>
              </Box>
              <Box display="flex" flexDirection="column" gap="1rem">
                <FormControl>
                  <RadioGroup>
                    <FormControlLabel
                      value="closest"
                      control={<Radio />}
                      label="Nejbližší termín"
                    />
                    <FormControlLabel
                      value="week"
                      control={<Radio />}
                      label="Za týden"
                    />
                    <FormControlLabel
                      value="twoWeeks"
                      control={<Radio />}
                      label="Za 14 dní"
                    />
                    <FormControlLabel
                      value="oneMonth"
                      control={<Radio />}
                      label="Za 1 měsíc"
                    />
                    <FormControlLabel
                      value="twoMonth"
                      control={<Radio />}
                      label="Za 2 měsíce"
                    />
                    <FormControlLabel
                      value="threeMonth"
                      control={<Radio />}
                      label="Za 3 měsíce"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box display="flex" flexDirection="column" gap="1rem">
                <FormGroup>
                  <FormControlLabel control={<Checkbox />} label="Ráno" />
                  <FormControlLabel control={<Checkbox />} label="Dopoledne" />
                  <FormControlLabel control={<Checkbox />} label="V poledne" />
                  <FormControlLabel control={<Checkbox />} label="Odpoledne" />
                  <FormControlLabel control={<Checkbox />} label="Večer" />
                </FormGroup>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" gap="1rem">
              <Typography variant="h3">Objedant klienta na:</Typography>
              <Box display="flex" flexDirection="column" gap="1rem">
                Úkon: ...
              </Box>

              <Box display="flex" flexDirection="row" gap="1rem">
                <Box display="flex" flexDirection="column" gap="1rem">
                  <Box display="flex" flexDirection="row" gap="1rem">
                    <Select
                      value={sliderValue[0]}
                      onChange={(event) =>
                        setSliderValue([
                          event.target.value,
                          event.target.value > sliderValue[1]
                            ? event.target.value
                            : sliderValue[1],
                        ])
                      }
                    >
                      {timeOptions?.map((time, index) => (
                        <MenuItem value={index} key={time}>
                          {time}
                        </MenuItem>
                      ))}
                    </Select>
                    <Select
                      value={sliderValue[1]}
                      onChange={(event) =>
                        setSliderValue([
                          event.target.value < sliderValue[0]
                            ? event.target.value
                            : sliderValue[0],
                          event.target.value,
                        ])
                      }
                    >
                      {timeOptions?.map((time, index) => (
                        <MenuItem value={index} key={time}>
                          {time}
                        </MenuItem>
                      ))}
                    </Select>{" "}
                    <Slider
                      value={sliderValue}
                      onChange={(event, newValue) => setSliderValue(newValue)}
                      valueLabelDisplay="auto"
                      valueLabelFormat={(value) => timeOptions[value]}
                      min={0}
                      max={timeOptions.length - 1}
                    />
                  </Box>
                  <MonthSelector range={monthRange} setRange={setMonthRange} />
                  <DateRangeCalendar
                    disablePast
                    value={calendarRange}
                    onChange={(newValue) => {
                      return setCalendarRange(newValue);
                    }}
                  />
                </Box>
                <Box display="flex" flexDirection="row" gap="1rem">
                  <Typography variant="h3">Dnešní volné časy:</Typography>
                </Box>
                <Box display="flex" flexDirection="row" gap="1rem">
                  <Typography variant="h3">Volné termíny:</Typography>
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" gap="1rem">
                <Typography variant="h3">Vybraný čas:</Typography>
                <Box display="flex" flexDirection="column" gap="1rem">
                  Středa 12. 5. 2021 ... TODO
                </Box>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => close()} variant="outlined">
            {t("form.cancel")}
          </Button>
          <Button
            type="submit"
            disabled={submitDisabled}
            sx={{ padding: "0.7rem 2.4rem", gap: "0.6rem" }}
            variant="contained"
          >
            {loading && <ButtonLoader />}
            <FontAwesomeIcon icon={faCalendarDays} />
            {t("form.reserve")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export { ManualDateSelectionDialog };
