import { DOUBLE_CLICK_DELAY } from "../constants";
import { status } from "shared/src/appointment.mjs";

/**
 * @typedef Appointment
 * @property {string} start
 * @property {string} end
 * @property {number} status_id
 * @property {string} calendar_id
 *
 * @typedef Event
 * @property {Date} start
 * @property {Date} end
 * @property {string} backgroundColor
 * @property {Appointment} extendedProps
 */

/**
 * @param {{status_id: number, service: {color: string}}} appointment
 *
 * @returns {string}
 */
export function appointmentColor(appointment) {
  return appointment.service?.color;
}

/**
 * @param {{status_id: number, service: {color: string}}} appointment
 *
 * @returns {string}
 */
export function appointmentBackground(appointment) {
  if (status.BOOKED === appointment.status_id) return "#ffffff";
  else if (status.CONFIRMED === appointment.status_id) return "#E8EFEF";

  return `color-mix(in srgb, ${appointmentColor(appointment)} 15%, #ffffff)`;
}

/**
 * @param {{status_id: number, service: {color: string}}} appointment
 *
 * @returns {string}
 */
export function appointmentInfoColor(appointment) {
  if (status.BOOKED === appointment.status_id) return "#D9D9D9";

  return `color-mix(in srgb, ${appointmentColor(appointment)} 80%, #000000)`;
}

/**
 * @param {Event} event
 *
 * @returns {string}
 */
export function eventStyle(event) {
  return `border-color: ${appointmentColor(event.extendedProps)}; color: var(--mui-palette-_components-heading-color); padding: var(--app-calendar-event-padding)`;
}

export function createClickHandler({ onClick, onDoubleClick }) {
  let timeoutId = null;
  const clear = () => {
    clearTimeout(timeoutId);
    timeoutId = null;
  };

  return function (...args) {
    if (timeoutId == null) {
      timeoutId = setTimeout(() => {
        clear();
        onClick(...args);
      }, DOUBLE_CLICK_DELAY);
      return;
    }

    clear();
    onDoubleClick(...args);
  };
}
