import {
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  InputLabel,
  Grid,
} from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useWizardContext } from "./AppointmentWizardContext";
import ServicesSelectInput from "./ServicesSelectInput.jsx";
import { fetchAppointmentNotes } from "../../services/api";

const NotesForReceptionForm = ({ services, appointmentId }) => {
  const { t } = useTranslation();
  const { receptionistNotes, setReceptionistNotes } = useWizardContext();

  useEffect(() => {
    async function loadNotes() {
      if (!appointmentId) return;

      const response = await fetchAppointmentNotes(appointmentId);
      if (response?.appointmentNotes?.length > 0) {
        const note = response.appointmentNotes[0];
        const loadedNumber = note.reservation_count || 0;
        const loadedUnit =
          loadedNumber === 0 ? "earliestAvailableDate" : note.reservation_unit;

        setReceptionistNotes({
          service: note.service_id || "",
          number: loadedNumber,
          unit: loadedUnit,
          quickInfo: note.note_content || "",
          doctorRequired: note.doctor_required || false,
          appointmentNeeded: note.appointment_needed === false ? "no" : "yes",
        });
      }
    }

    loadNotes();
  }, [appointmentId, setReceptionistNotes]);

  const handleNotesChange = (field) => (e) => {
    let value = e.target.value;

    if (field === "number") {
      value = Math.max(0, Number(value));
      setReceptionistNotes((prev) => ({
        ...prev,
        number: value,
        unit: value === 0 ? "earliestAvailableDate" : prev.unit,
      }));
    } else if (field === "unit") {
      let newNumber = receptionistNotes.number;
      if (value === "earliestAvailableDate") newNumber = 0;
      else if (newNumber === 0) newNumber = 1;

      setReceptionistNotes((prev) => ({
        ...prev,
        unit: value,
        number: newNumber,
      }));
    } else if (field === "doctorRequired") {
      setReceptionistNotes((prev) => ({
        ...prev,
        doctorRequired: e.target.checked,
      }));
    } else {
      setReceptionistNotes((prev) => ({
        ...prev,
        [field]: value,
      }));
    }
  };

  return (
    <>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        {t("appointmentNotes.notesForReception")}
      </Typography>

      <Grid
        container
        spacing={2}
        alignItems="center"
        sx={{ flexWrap: "wrap", marginBottom: 2 }}
      >
        <Grid>
          <FormControl sx={{ minWidth: 200 }}>
            <ServicesSelectInput
              services={services}
              value={receptionistNotes?.service || ""}
              onChange={handleNotesChange("service")}
              labelKey="appointmentNotes.service"
            />
          </FormControl>
        </Grid>

        <Grid>
          <FormControlLabel
            control={
              <Checkbox
                name="doctorRequired"
                onChange={handleNotesChange("doctorRequired")}
                checked={receptionistNotes?.doctorRequired || false}
              />
            }
            label={t("appointmentNotes.doctorRequired")}
          />
        </Grid>

        <Grid sx={{ flexGrow: 1 }}>
          <TextField
            name="quickInfo"
            id="quickInfo"
            label={t("appointmentNotes.quickInfo")}
            onChange={handleNotesChange("quickInfo")}
            value={receptionistNotes?.quickInfo || ""}
            multiline
            fullWidth
            rows={2}
          />
        </Grid>
      </Grid>

      <FormControl component="fieldset" sx={{ marginBottom: 2 }}>
        <FormLabel component="legend">
          {t("appointmentNotes.appointmentNeeded")}
        </FormLabel>
        <RadioGroup
          row
          value={receptionistNotes.appointmentNeeded ?? "yes"}
          onChange={handleNotesChange("appointmentNeeded")}
        >
          <FormControlLabel
            value="yes"
            control={<Radio />}
            label={t("appointmentNotes.needNewAppointment")}
          />
          <FormControlLabel
            value="no"
            control={<Radio />}
            label={t("appointmentNotes.noNewAppointment")}
          />
        </RadioGroup>
      </FormControl>

      {(receptionistNotes.appointmentNeeded ?? "yes") === "yes" && (
        <Grid
          container
          spacing={2}
          alignItems="center"
          sx={{ flexWrap: "wrap" }}
        >
          <Grid>
            <TextField
              sx={{ width: 100 }}
              name="number"
              id="number"
              label={t("appointmentNotes.number")}
              type="number"
              onChange={handleNotesChange("number")}
              value={receptionistNotes?.number || 0}
            />
          </Grid>

          <Grid>
            <FormControl sx={{ minWidth: 180 }}>
              <InputLabel>{t("appointmentNotes.unit")}</InputLabel>
              <Select
                name="unit"
                value={
                  receptionistNotes.number === 0
                    ? "earliestAvailableDate"
                    : receptionistNotes.unit || "days"
                }
                onChange={handleNotesChange("unit")}
                displayEmpty
              >
                <MenuItem value="earliestAvailableDate">
                  {t("appointmentNotes.earliestAvailableDate")}
                </MenuItem>
                <MenuItem value="days">{t("appointmentNotes.days")}</MenuItem>
                <MenuItem value="weeks">{t("appointmentNotes.weeks")}</MenuItem>
                <MenuItem value="months">
                  {t("appointmentNotes.months")}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default NotesForReceptionForm;
